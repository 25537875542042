/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "./store";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter, push } from "connected-react-router";
import { History } from "history";
import { HeaderFetcher } from "./containers/Header";
import Footer from "./containers/Footer";
import Top from "./containers/top/Top";
import SideMenu from "./containers/SideMenu";
import { CartDialog } from "./containers/cart/Cart";
import { BrandEventList } from "./containers/event/BrandEventList";
import Event from "./containers/event/Event";
import { EventTicket } from "./containers/event/EventTicket";
import { ArchiveEventList } from "./containers/event/ArchiveEventList";
import { VodEventListFetcher } from "./containers/event/VodEventList";
import { Account } from "./containers/account/Account";
import { AccountEdit } from "./containers/account/AccountEdit";
import { AccountEditPassword } from "./containers/account/AccountEditPassword";
import { AccountDelete } from "./containers/account/AccountDelete";
import { LiveEvent } from "./containers/live/LiveEvent";
import Warning from "./containers/warning/Warning";
import Question from "./containers/question/Question";
import QrcodeReader from "./containers/qrcode/QrcodeReader";
import Company from "./containers/company/Company";
import { Rule } from "./containers/rule/Rule";
import { BasicTermsOfService } from "./features/terms/BasicTermsOfService";
import { ViewerAppTerms } from "./containers/terms/ViewerAppTerms";
import Contact from "./containers/contact/Contact";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { FIREBASE_CONFIG } from "./constants/firebase";
import ModalLoginMethod from "./containers/auth/ModalLoginMethod";
import ModalLogin from "./containers/auth/ModalLogin";
import ARAppLogin from "./containers/auth/ARAppLogin";
import ARAppLoginRequired from "./containers/auth/ARAppLoginRequired";
import ViewerAppLogin from "./containers/auth/ViewerAppLogin";
import Loading from "./containers/Loading";
import Error from "./containers/Error";
import Notice from "./containers/Notice";
import ActionModal from "./containers/ActionModal";
import { authActions } from "./modules/auth";
import { AccountTicket } from "./containers/account/AccountTicket";
import { AccountVodTicket } from "./containers/account/AccountVodTicket";
import { AccountTicketDigital } from "./containers/account/AccountTicketDigital";
import { AccountLottery } from "./features/accounts/AccountLottery";
import { AccountSettlementHistory } from "./containers/account/AccountSettlementHistory";
import { AccountPawUseHistory } from "./containers/account/AccountPawUseHistory";
import { AccountPawChargeHistory } from "./containers/account/AccountPawChargeHistory";
import { AccountCredit } from "./containers/account/AccountCredit";
import { AccountNotificationList } from "./containers/account/notification/AccountNotificationList";
import { AccountNotification } from "./containers/account/notification/AccountNotification";
import Settlement from "./containers/settlement/Settlement";
import { RegisterCard } from "./containers/modal/RegisterCard";
import { TicketReceive } from "./containers/account/TicketReceive";
import Voucher from "./containers/voucher";
import ScrollToTop from "./containers/ScrollToTop";
import ViewerAppPrivacyPolicy from "./containers/policy/ViewerAppPrivacyPolicy";
import { PawTerms } from "./containers/terms/PawTerms";
import PaymentServicesAct from "./containers/payment/PaymentServicesAct";
import PlicaPrivacyPolicy from "./containers/policy/PlicaPrivacyPolicy";
import Admin from "./containers/admin/Admin";
import { ticketActions } from "./modules/ticket";
import Email from "./containers/Email";
import { adminActions } from "./modules/admin";
import { getQueryObject } from "./utility";
import { Timestamp } from "@google-cloud/firestore";
import Questionary from "./containers/modal/Questionary";
import { cartActions } from "./modules/cart";
import StreamingTicketAuthenticator from "./containers/streaming/StreamingTicketAuthenticator";
import EventFlowerStandList from "./containers/event/EventFlowerStandList";
import { EventFlowerStandItem } from "./containers/event/EventFlowerStandItem";
import EventFlowerStandBase from "./containers/event/flowerStand/EventFlowerStandBase";
import StreamingViewTest from "./containers/streaming/StreamingViewTest";
import AccountTicketBase from "./containers/account/ticket/AccountTicketBase";
import ResetPassword from "./containers/auth/ResetPassword";
import PawCharge from "./containers/modal/pawCharge/PawCharge";
import Manual from "./containers/manual/Manual";
import { notificationActions } from "modules/notification";
import { AccountAddressBase } from "./containers/account/AccountAddressBase";
import { changeStyleWithHosting, isPortalDomain } from "./utility/hosting";
import { isBrandDomain } from "./features/isBrandDomain";
import { setMeta } from "./utility/meta";
import { modalActions } from "modules/modal";

import { getBelongedHosting } from "utility/storage";
import DebugLogin from "./containers/debug/DebugLogin";
import appConfig from "constants/appConfig";
import EventBase from "containers/event/EventBase";
import OpenId from "./containers/openId/OpenId";
import { EventGoodsHome } from "containers/event/goods/EventGoodsHome";
import { EventGoodsItem } from "containers/event/goods/EventGoodsItem";
import EventGoodsBase from "containers/event/goods/EventGoodsBase";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import * as Sentry from "@sentry/react";
import { AlertBar } from "components/atoms/AlertBar";
// @ts-expect-error TS7016
import queryString from "query-string";
import { Global } from "@emotion/react";
import { globalStyle } from "./styles";
import { VideoBase } from "containers/video/VideoBase";
import type { MaintenanceInfo } from "@spwn/types/firebase/firestore";
import { VoucherTOFES } from "./containers/voucherTOFES/VoucherTOFES";
import { TenantRouter } from "./features/TenantRouter";
import { ShopContainer } from "./features/ShopContainer";
import { TenantGoodsContainer } from "./features/TenantGoods";
import { ShopPreviewContainer } from "./features/ShopPreviewContainer";
import { TenantGoodsPreview } from "./features/TenantGoodsPreview";
import { PurchaseContainer } from "./features/PurchaseContainer";
import { PurchaseComplete } from "./features/PurchaseComplete";
import { PreviewHeader } from "features/PreviewHeader";
import { withRequireLogin } from "./features/withRequireLogin";
import { AccountsApp, isAccountsApp } from "features/accounts/AccountsApp";
import { GoExternalSite } from "components/common/Link";
import { pushDataLayer } from "utility/ga";
import CrewStreamingFetcher from "features/crew/CrewStreaming";
import { SwitchIfNotLoggedIn } from "features/SwitchIfNotLoggedIn";
import { CapsuleEventListFetcher } from "containers/event/CapsuleEventList";
import { GoodsEventListFetcher } from "containers/event/GoodsEventList";
import { FanclubTermsOfService } from "features/terms/FanclubTermsOfService";
import { CmsTermsOfService } from "features/terms/CmsTermsOfService";
import { MusicCopyrightGuidelines } from "features/terms/MusicCopyrightGuidelines";
import { CreatorPrivacyPolicy } from "features/terms/CreatorPrivacyPolicy";
import { TermsOfService } from "features/terms/TermsOfService";
import { PrivacyPolicy } from "./features/terms/PrivacyPolicy";
import { PortalEventList } from "./containers/event/PortalEventList";
import { RestrictedStreaming } from "features/restrictedStreaming/RestrictedStreaming";
import { ProvisionOfPersonalInfo } from "features/accounts/ProvisionOfPersonalInfo";
import { TodayEventFloatingButtonLogic } from "features/TodayEventFloatingButton";
import { PreFetchRecommendedEvents } from "./features/events/RecommendedEvents/PreFetchRecommendedEvents";
import Box from "@material-ui/core/Box";

interface AppProps {
  history: History;
  user: firebase.User;
  isLogin: boolean;
  // @ts-expect-error TS7008
  location;
  isAdmin: boolean;
  listenLoginState: () => void;
  addLoginAction: () => void;
  fetchMyTickets: () => void;
  // @ts-expect-error TS7006
  checkMaintenanceUser: (payload) => void;
  getMyCart: () => void;
  watchUserNotifications: () => void;
  fetchPickUpNews: () => void;
  fetchIsAddressRegisterd: () => void;
  checkBelongedHosting: () => void;
  toggleLoginMethod: () => void;
  setLocalTimeDiff: () => void;
  // @ts-expect-error TS7051
  toggleError: (string) => void;
}
interface States {
  isRedirect: boolean;
  isFlowerStandMaintenance: boolean;
  isPawChargeMaintenance: boolean;
  isTicketMaintenance: boolean;
  isGoodsMaintenance: boolean;
  isMaintenance: boolean;
  isLiveEventMaintenance: boolean;
  startAt: Timestamp;
  endAt: Timestamp;
  msg: string;
  liveEventId: string;
}

class App extends Component<AppProps, States> {
  managementWatcher = null;
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    firebase.initializeApp(FIREBASE_CONFIG);
    // Use firebase functions emulator
    if (process.env.REACT_APP_USE_EMULATOR) {
      firebase.auth().useEmulator("http://localhost:9099");
      firebase.firestore().useEmulator("localhost", 8080);
      firebase.functions().useEmulator("localhost", 5001);
      firebase.database().useEmulator("localhost", 9000);
    }
    this.state = {
      isRedirect: false,
      isFlowerStandMaintenance: false,
      isPawChargeMaintenance: false,
      isGoodsMaintenance: false,
      isTicketMaintenance: false,
      isLiveEventMaintenance: false,
      // @ts-expect-error TS2322
      isMaintenance: null,
      // @ts-expect-error TS2322
      startAt: null,
      // @ts-expect-error TS2322
      endAt: null,
      msg: "",
      liveEventId: "",
    };
    this.props.checkBelongedHosting();
    this.trackUser();
  }

  componentDidMount() {
    this.isHostnameRedirectRequired();
    this.startManagementWatching();
    this.props.listenLoginState();
    this.props.setLocalTimeDiff();
    this.props.fetchPickUpNews();
    setMeta();
  }
  componentDidUpdate(prevProps: AppProps) {
    this.trackUser();
    // execute once logined
    if (
      !prevProps.isLogin &&
      this.props.user.uid &&
      this.props.isLogin === true
    ) {
      // user由来のwatchを開始 (myCart, notification
      this.props.getMyCart();
      this.props.watchUserNotifications();
      // fetch user info (address, tickets)
      this.props.fetchIsAddressRegisterd();
      this.props.fetchMyTickets();
      // admin checker
      const queryObject = getQueryObject(this.props.location);
      this.props.checkMaintenanceUser(queryObject);
      // set uid to cookies for contact
      window.document.cookie = `uid=${this.props.user.uid};domain=spwn.jp;secure`;
      window.document.cookie = `last-domain=${window.location.hostname};domain=spwn.jp;secure`;
    }
    const queryStrings = queryString.parse(window.location.search);
    if ("errorCode" in queryStrings) {
      this.props.toggleError(queryStrings.msg);
    }
  }

  /**
   * リダイレクトフラグをセットする
   */
  isHostnameRedirectRequired() {
    this.setState({
      isRedirect: this.isUnAvailableFirebaseSubDomain(),
    });
  }

  /**
   * firebase hosting登録時に自動作成されるサブドメイン判定
   * ※ このサブドメインは cors の関係で利用できないAPIが多い
   */
  isUnAvailableFirebaseSubDomain() {
    const domain = window.location.hostname;
    // spwn-balus.web.app, spwn-balus.firebaseapp.com, etc
    if (domain.endsWith("web.app") || domain.endsWith("firebaseapp.com")) {
      return true;
    }
    return false;
  }

  startManagementWatching() {
    const watcher = firebase
      .firestore()
      .collection("/management")
      .onSnapshot((snapshot) => {
        // @ts-expect-error TS2322
        let maintenanceInfo: MaintenanceInfo = null;
        let flags = null;
        for (let i = 0; i < snapshot.docs.length; ++i) {
          // @ts-expect-error TS2532
          switch (snapshot.docs[i].id) {
            case "flags": {
              // @ts-expect-error TS2532
              flags = snapshot.docs[i].data();
              break;
            }
            case "maintenanceInfo": {
              // @ts-expect-error TS2532
              const data: any = snapshot.docs[i].data(); // eslint-disable-line @typescript-eslint/no-explicit-any
              maintenanceInfo = data;
              break;
            }
          }
        }
        if (
          flags === undefined ||
          maintenanceInfo === undefined ||
          flags === null ||
          maintenanceInfo == null
        ) {
          console.log("ASDFGHJK");
          return;
        }
        this.setState({
          isFlowerStandMaintenance: maintenanceInfo.isFlowerStandMaintenance,
          isPawChargeMaintenance: maintenanceInfo.isPawChargeMaintenance,
          isTicketMaintenance: maintenanceInfo.isTicketMaintenance,
          isGoodsMaintenance: maintenanceInfo.isGoodsMaintenance,
          isMaintenance: maintenanceInfo.isMaintenance,
          isLiveEventMaintenance: maintenanceInfo.isLiveEventMaintenance,
          // @ts-expect-error TS2322
          startAt: maintenanceInfo.startAt,
          // @ts-expect-error TS2322
          endAt: maintenanceInfo.endAt,
          msg: maintenanceInfo.msg,
          liveEventId: maintenanceInfo.liveEventId,
        });
      });
    // @ts-expect-error TS2322
    this.managementWatcher = watcher;
  }

  componentWillUnmount() {
    if (this.managementWatcher !== null) {
      // @ts-expect-error TS2349
      this.managementWatcher();
    }
  }

  isInMaintenanceTimePeriod = () => {
    return (
      this.state.startAt !== null &&
      this.state.endAt !== null &&
      this.state.startAt.seconds * 1000 <= Date.now() &&
      this.state.endAt.seconds * 1000 >= Date.now()
    );
  };

  trackUser = () => {
    // Google Analytics に uid を送信する
    pushDataLayer({
      uid: this.props.user.uid,
      domain: getBelongedHosting(),
    });

    // Sentry にユーザー情報を送信する
    Sentry.setUser({
      id: this.props.user.uid,
      // @ts-expect-error TS2322
      username: this.props.user.displayName,
      // @ts-expect-error TS2322
      email: this.props.user.email,
      ip_address: "{{auto}}",
    });
  };

  render() {
    const { history, isLogin } = this.props;

    /**
     * firebase hostingで自動作成された、利用できないホストにアクセスしてきたユーザを正式なホストにリダイレクトする
     */
    if (this.state.isRedirect) {
      // firebase config の 認証ドメインにリダイレクトする
      const { authDomain } = FIREBASE_CONFIG;
      return (
        <GoExternalSite
          externalLink={`https://${authDomain}`}
          caution={
            "このページのドメインが移行されたため、自動的に新しいドメインに遷移します。 / The domain of this page has been migrated and will automatically redirect to the new domain."
          }
        />
      );
    }

    const classesApp = AppDesign();

    if (isAccountsApp()) {
      /**
       * アカウントアプリケーションはportalのログイン機能を流用する
       */
      return <AccountsApp history={history} />;
    }

    if (this.state.isMaintenance === null) {
      return (
        <div id="container" css={classesApp.container}>
          <ConnectedRouter history={history}>
            <HeaderFetcher />
            <LoaderLayout />
            <Footer />
          </ConnectedRouter>
        </div>
      );
    }

    if (
      this.state.isMaintenance &&
      this.isInMaintenanceTimePeriod() &&
      !this.props.isAdmin
    ) {
      return <Admin />;
    }

    const isFlowerStandMaintenance =
      this.state.isFlowerStandMaintenance &&
      this.isInMaintenanceTimePeriod() &&
      !this.props.isAdmin;
    // const isPawChargeMaintenance =
    //   this.state.isPawChargeMaintenance &&
    //   this.isInMaintenanceTimePeriod() &&
    //   !this.props.isAdmin;
    const isGoodsMaintenance =
      this.state.isGoodsMaintenance &&
      this.isInMaintenanceTimePeriod() &&
      !this.props.isAdmin;
    const isTicketMaintenance =
      this.state.isTicketMaintenance &&
      this.isInMaintenanceTimePeriod() &&
      !this.props.isAdmin;
    const { isLiveEventMaintenance } = this.state;

    console.log(process.env.REACT_APP_FB_PROJECT_ID);

    return (
      <React.Fragment>
        <ConnectedRouter history={history}>
          <Global styles={globalStyle} />

          <Box
            component={"section"}
            display={"flex"}
            flexDirection={"column"}
            minHeight={"100vh"}
          >
            <HeaderFetcher />

            <div
              id="container"
              css={classesApp.container}
              style={{
                flex: "1 1 auto",
              }}
            >
              {this.props.location.pathname.includes("/shop-preview") && (
                <PreviewHeader />
              )}

              {this.props.location.pathname.indexOf("/streaming") === -1 && (
                <AlertBar />
              )}

              {!this.props.location.pathname.includes("/streaming") &&
                !this.props.location.pathname.includes("/account") && (
                  <TodayEventFloatingButtonLogic />
                )}

              <SideMenu />

              <ScrollToTop>
                <CartDialog />
                <Switch>
                  <Route
                    exact
                    path="/"
                    /**
                     * @see https://v5.reactrouter.com/web/api/Route/render-func
                     */
                    render={() => {
                      /**
                       * テナントブランドのドメインの場合、ショップページ以外が当初ないのでショップページへリダイレクトさせている
                       * @see https://www.notion.so/balusco/ae82a03fae9a4fb1bfc2a66f03014542 Design Doc
                       */
                      if (isBrandDomain()) {
                        return <Redirect to={"/shop"} />;
                      }
                      return (
                        <Top
                          isAdmin={this.props.isAdmin}
                          isLiveEventMaintenance={isLiveEventMaintenance}
                          maintenanceLiveEventId={
                            this.props.isAdmin && isLiveEventMaintenance
                              ? this.state.liveEventId
                              : ""
                          }
                        />
                      );
                    }}
                  />
                  <TenantRouter exact path="/shop" component={ShopContainer} />
                  <TenantRouter
                    exact
                    path="/shop-preview"
                    // @ts-expect-error TS2345
                    component={withRequireLogin(ShopPreviewContainer)}
                  />
                  <TenantRouter
                    exact
                    path="/shop/goods/:goodsId"
                    component={TenantGoodsContainer}
                  />
                  <TenantRouter
                    exact
                    path="/shop-preview/goods/:goodsId"
                    // @ts-expect-error TS2345
                    component={withRequireLogin(TenantGoodsPreview)}
                  />
                  <Route
                    exact
                    path="/events"
                    component={
                      isPortalDomain() ? PortalEventList : BrandEventList
                    }
                  />
                  <Route path="/events/:eventId">
                    {/* please include component with `/events/:eventId/~` path name basically */}
                    <EventBase>
                      <Switch>
                        <Route
                          exact
                          path="/events/:eventId"
                          component={Event}
                        />
                        <PrivateRoute
                          exact
                          path="/events/:eventId/streaming"
                          component={StreamingTicketAuthenticator}
                          authed={isLogin}
                        />
                        <PrivateRoute
                          exact
                          path="/events/:eventId/ticket"
                          component={isTicketMaintenance ? Admin : EventTicket}
                          authed={isLogin}
                        />
                        <Route path="/events/:eventId/flower-stand">
                          <EventFlowerStandBase>
                            <Switch>
                              <PrivateRoute
                                exact
                                path="/events/:eventId/flower-stand"
                                component={
                                  isFlowerStandMaintenance
                                    ? Admin
                                    : EventFlowerStandList
                                }
                                authed={isLogin}
                              />
                              <PrivateRoute
                                exact
                                path="/events/:eventId/flower-stand/:flowerStandName"
                                component={
                                  isFlowerStandMaintenance
                                    ? Admin
                                    : EventFlowerStandItem
                                }
                                authed={isLogin}
                              />
                            </Switch>
                          </EventFlowerStandBase>
                        </Route>
                        <Route path="/events/:eventId/goods">
                          <EventGoodsBase>
                            <Switch>
                              <Route
                                exact
                                path="/events/:eventId/goods"
                                component={
                                  isGoodsMaintenance ? Admin : EventGoodsHome
                                }
                              />
                              <Route
                                exact
                                path="/events/:eventId/goods/:goodsName"
                                component={
                                  isGoodsMaintenance ? Admin : EventGoodsItem
                                }
                              />
                            </Switch>
                          </EventGoodsBase>
                        </Route>
                      </Switch>
                    </EventBase>
                  </Route>
                  <Redirect
                    from="/_events/:eventId"
                    to={{
                      pathname: "/events/:eventId",
                      search: document.location.search,
                    }}
                  />
                  <Route
                    exact
                    path="/archive-events"
                    component={ArchiveEventList}
                  />
                  <Route
                    exact
                    path="/vod-events"
                    component={VodEventListFetcher}
                  />
                  <Route
                    exact
                    path="/capsule"
                    component={CapsuleEventListFetcher}
                  />
                  <Route
                    exact
                    path="/goods"
                    component={GoodsEventListFetcher}
                  />
                  <PrivateRoute
                    exact
                    path="/live-event"
                    component={
                      !isLiveEventMaintenance || this.props.isAdmin
                        ? LiveEvent
                        : Top
                    }
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/videos/:videoId"
                    component={VideoBase}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account"
                    component={Account}
                    authed={isLogin}
                  />
                  <Route path="/account/ticket">
                    <AccountTicketBase>
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/account/ticket"
                          component={AccountTicket}
                          authed={isLogin}
                        />
                        <PrivateRoute
                          exact
                          path="/account/ticket/vod"
                          component={AccountVodTicket}
                          authed={isLogin}
                        />
                        <PrivateRoute
                          exact
                          path="/account/ticket/:eventId"
                          component={AccountTicketDigital}
                          authed={isLogin}
                        />
                      </Switch>
                    </AccountTicketBase>
                  </Route>
                  <PrivateRoute
                    exact
                    path="/account/ticket-receive"
                    component={TicketReceive}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/lottery"
                    component={AccountLottery}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/settlement/history"
                    component={AccountSettlementHistory}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/paw-charge-history"
                    component={AccountPawChargeHistory}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/paw-use-history"
                    component={AccountPawUseHistory}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/edit"
                    component={AccountEdit}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/edit/delete"
                    component={AccountDelete}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/edit/password"
                    component={AccountEditPassword}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/credit"
                    component={AccountCredit}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/address"
                    component={AccountAddressBase}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/notifications"
                    component={AccountNotificationList}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/notifications/:notificationId"
                    component={AccountNotification}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/account/provision-of-personal-info"
                    component={ProvisionOfPersonalInfo}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/settlement"
                    component={
                      isGoodsMaintenance || isTicketMaintenance
                        ? Admin
                        : Settlement
                    }
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/purchase"
                    component={PurchaseContainer}
                    authed={isLogin}
                  />
                  <PrivateRoute
                    exact
                    path="/purchase-complete"
                    component={PurchaseComplete}
                    authed={isLogin}
                  />
                  {/* <PrivateRoute exact path='/settlement-renewal' component={isGoodsMaintenance|| isTicketMaintenance? Admin: SettlementRenewal} authed={isLogin} /> */}
                  <Route exact path="/voucher" component={Voucher} />
                  {/* HOTFIX https://github.com/balus-co-ltd/spwn/issues/297 */}
                  <Route
                    exact
                    path="/voucher-210829-TOFES"
                    component={VoucherTOFES}
                  />
                  <Route exact path="/event-notes" component={Warning} />
                  <Route exact path="/faq" component={Question} />
                  <Route exact path="/company" component={Company} />
                  <Route exact path="/law" component={Rule} />
                  <Route
                    exact
                    path="/basic-terms-of-service"
                    component={BasicTermsOfService}
                  />
                  <Route
                    exact
                    path="/fanclub-terms-of-service"
                    component={FanclubTermsOfService}
                  />
                  <Route
                    exact
                    path="/cms-terms-of-service"
                    component={CmsTermsOfService}
                  />
                  <Route
                    exact
                    path="/music-copyright-guidelines"
                    component={MusicCopyrightGuidelines}
                  />
                  <Route
                    exact
                    path="/creator-privacy-policy"
                    component={CreatorPrivacyPolicy}
                  />
                  <Route
                    exact
                    path="/terms-of-service"
                    component={TermsOfService}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  <Route
                    exact
                    path="/terms-of-service-app"
                    component={ViewerAppTerms}
                  />
                  <Route exact path="/contact" component={Contact} />
                  <Route
                    exact
                    path="/privacy-policy-app"
                    component={ViewerAppPrivacyPolicy}
                  />
                  <Route exact path="/paw-terms" component={PawTerms} />
                  <Route
                    exact
                    path="/payment-services-act"
                    component={PaymentServicesAct}
                  />
                  <Route
                    exact
                    path="/plica-privacy-policy"
                    component={PlicaPrivacyPolicy}
                  />
                  <Route exact path="/manual" component={Manual} />
                  <Route
                    exact
                    path="/streaming-test"
                    component={StreamingViewTest}
                  />
                  <Route
                    exact
                    path="/ar-app-login-ehgeywoyhoebavro"
                    component={ARAppLogin}
                  />{" "}
                  {/** only use for ar app login */}
                  <Route
                    exact
                    path="/ar-app-login-required"
                    component={ARAppLoginRequired}
                  />{" "}
                  {/** only use for ar app login */}
                  <Route
                    exact
                    path="/viewer-app-login"
                    component={ViewerAppLogin}
                  />{" "}
                  {/** only use for ar app login */}
                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                  />
                  <Route exact path="/openid" component={OpenId} />
                  {appConfig.logDebug ? (
                    <Route exact path="/debug" component={DebugLogin} />
                  ) : null}
                  <Route
                    exact
                    path="/qrcode-redeemer"
                    component={QrcodeReader}
                  />
                  <Route
                    exact
                    path="/streams/:streamingId"
                    /**
                     * ログインをしていなかったら、ログインモーダルを表示するコンポーネント
                     * FIXME 今後このコンポーネントを採用する場合は、経由させるコンポーネントを作成するなどリファクタリングを行う。
                     */
                    component={() => (
                      <SwitchIfNotLoggedIn switchComponent={<Loading />}>
                        <CrewStreamingFetcher />
                      </SwitchIfNotLoggedIn>
                    )}
                  />
                  {/* 関係者限定配信ページ */}
                  <Route
                    exact
                    path="/streams/p/:videoId"
                    component={RestrictedStreaming}
                  />
                  <Route exact component={Top} />
                </Switch>
                <Loading />
                <Email />
                <Error />
                <Notice />
                <PreFetchRecommendedEvents />
                <ActionModal />
                <ModalLoginMethod />
                <ModalLogin />
                <RegisterCard />
                <Questionary />
                <PawCharge />
              </ScrollToTop>
            </div>

            <Footer />
          </Box>
        </ConnectedRouter>
      </React.Fragment>
    );
  }
}

/**
 * need login router
 */
// @ts-expect-error TS7031
const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const dispatch = useDispatch();

  if (authed === null || typeof authed === "undefined") {
    // ログインが確認できるまではrenderしない
    return <React.Fragment></React.Fragment>;
  }
  const parentProps = !rest.parentProps ? {} : rest.parentProps;
  if (authed === false) {
    dispatch(modalActions.toggleLoginMethod());
    // TODO@later 直リンクでPrivateRouteのコンポーネントに飛ぶとTopがrenderされない問題
    dispatch(push(changeStyleWithHosting().commonSettings.route.home));
    return null;
  }
  return (
    // prettier-ignore
    <Route
      {...rest}
      render={(props) =>
      // topにリダイレクトしたらログインpopupが出るようにする
      {
        return (authed === true ? (
          <Component {...props} openLoginModal={false} {...parentProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location, openLoginModal: true },
            }}
          />
        ))
      }
      }
    />
  );
};

const mapStateToProps = (state: Store) => {
  return {
    user: state.auth.user,
    isLogin: state.auth.isLogin,
    location: state.router.location,
    isAdmin: state.admin.isAdmin,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listenLoginState: () => {
      dispatch(authActions.listenLoginState.started());
    },
    // @ts-expect-error TS7006
    addLoginAction: (action) => {
      dispatch(authActions.addLoginAction(action));
    },
    fetchMyTickets: () => {
      dispatch(ticketActions.fetchMyTickets.started({ callConfirmTran: true }));
    },
    // @ts-expect-error TS7006
    checkMaintenanceUser: (payload) => {
      dispatch(adminActions.checkMaintenanceUser.started(payload));
    },
    getMyCart: () => {
      dispatch(cartActions.getMyCart.started({}));
    },
    watchUserNotifications: () => {
      dispatch(notificationActions.watchUserNotifications.started());
    },
    fetchPickUpNews: () => {
      dispatch(notificationActions.fetchPickUpNews.started());
    },
    fetchIsAddressRegisterd: () => {
      dispatch(authActions.fetchIsAddressRegisterd.started());
    },
    checkBelongedHosting: () => {
      dispatch(adminActions.checkBelongedHosting());
    },
    toggleLoginMethod: () => {
      dispatch(modalActions.toggleLoginMethod());
    },
    setLocalTimeDiff: () => {
      dispatch(authActions.setLocalTimeDiff.started({}));
    },
    // @ts-expect-error TS7006
    toggleError: (msg) => {
      dispatch(modalActions.toggleError({ msg }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const AppDesign = () => {
  const belongedHosting = getBelongedHosting();
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;

  const backgroundImage = hostingBaseStyleMap.backgroundImage
    ? "url(" + hostingBaseStyleMap.backgroundImage + ")"
    : "none";

  if (belongedHosting === "vtuber1") {
    return {
      container: css`
        background-color: ${hostingBaseStyleMap.baseColor};
        background-image: ${backgroundImage};
        background-size: auto 1500px;
        background-position: 0% 100%;
        @media screen and (min-width: 768px) {
          background-size: auto 6000px;
          background-repeat: repeat-y;
          background-position: 50% 0px;
        }
      `,
    };
  }
  if (belongedHosting === "balus-1") {
    return {
      container: css`
        background-color: ${hostingBaseStyleMap.baseColor};
        background-image: ${backgroundImage};
        background-size: 100% auto;
        background-repeat: repeat-y;
      `,
    };
  }
  return {
    container: css`
      background-color: ${hostingBaseStyleMap.baseColor};
      background-image: ${backgroundImage};
      background-size: 500px auto;
    `,
  };
};
