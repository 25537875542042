import React from "react";
import Box from "@material-ui/core/Box";

export const SubContentsLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      component={"main"}
      id="sub-contents"
      minHeight={"calc(100vh - 65px - 190px)"}
      paddingBottom={"80px"}
      bgcolor={"#fff"}
    >
      {children}
    </Box>
  );
};
