import { i18nextT } from "../../../hooks/i18n/i18n";
import type { HostingStyleMap } from "utility/hosting";
import logo from "designs/images/external/pokopea/logo.png";

/**
 * spwn basic style
 */
export const baseStyles = (): HostingStyleMap => {
  return {
    baseStyles: {
      baseColor: "#fcfcfc",
      mainColor: "#ffcc00",
      accentColor: "#66cc00",
      fontPrimaryColor: "#000",
      fontSecondaryColor: "#000",
      backgroundImage: null,
    },

    commonSettings: {
      auth: {
        loginMethod: {
          twitter: true,
          facebook: false,
        },
      },
      route: {
        home: "/",
      },
      meta: {
        favicon: null,
        appleTouchIcon: null,
        title: i18nextT("hosting.templates.pokopea-1.common.meta.title"),
        description: i18nextT(
          "hosting.templates.pokopea-1.common.meta.description"
        ),
        copyright: i18nextT("hosting.templates.pokopea-1.footer.copyright"),
      },
      header: {
        logo: {
          isDisplay: true,
          path: logo,
        },
        textColor: "#fff",
        svgColor: "#fff",
      },
      menu: {
        home: {
          isDisplay: true,
        },
        event: {
          isDisplay: true,
          text: i18nextT(
            "hosting.templates.pokopea-1.common.section.event.name"
          ),
        },
        archive: {
          isDisplay: true,
          text: i18nextT(
            "hosting.templates.pokopea-1.common.section.archive.name"
          ),
        },
        account: {
          isDisplay: true,
        },
        pastEvents: {
          isDisplay: true,
          text: i18nextT(
            "hosting.templates.pokopea-1.common.section.pastEvents.name"
          ),
        },
        eventNotes: {
          isDisplay: true,
          path: null,
        },
        guide: {
          isDisplay: true,
        },
        contact: {
          isDisplay: true,
          path: null,
        },
      },
      isDisplayBreadcrumb: true,
      isDisplayEventDate: true,
      isDisplayEventTime: true,
    },

    pageSettings: {
      topPage: {
        upcomingHeadline: {
          isDisplay: true,
          mainText: null,
          subText: null,
        },
        isDisplayTodaysEvent: true,
        isDisplayInfoArea: false,
        isDisplayDeliveryDisplayArea: false,
        isDisplayCatchCopy: false,
      },
      eventListPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventDatetimeLabel: false,
        /** ------------------------- */
      },
      eventPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventState: true,
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayShareButton: true,
        /** ------------------------- */
        linkInPage: {
          color: "#fff",
          link01: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.linkInEvent.link01.text"
            ),
          },
          link02: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.linkInEvent.link02.text"
            ),
          },
          link03: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.linkInEvent.link03.text"
            ),
          },
          link04: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.linkInEvent.link04.text"
            ),
          },
          link05: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.linkInEvent.link05.text"
            ),
          },
        },
        artistInfo: {
          isDisplayArtistStateLabel: true,
          headline: {
            isDisplay: true,
            text: i18nextT(
              "hosting.templates.pokopea-1.event.artistInfo.heading.text"
            ),
            subText: i18nextT(
              "hosting.templates.pokopea-1.event.artistInfo.heading.subText"
            ),
          },
        },
        purchaseButton: {
          path: null,
        },
      },
      goodsPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayGoodsInfo: true,
        /** ------------------------- */
      },
      streamingPage: {
        isDisplaySuperChatArea: true,
      },
      accountTicketPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayShareButton: true,
        /** ------------------------- */
      },
    },
  };
};
