import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const TicketIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
      style={{ width: "24px", height: "24px" }}
    >
      <title>03_ico/account/ticket</title>
      <desc>Created with Sketch.</desc>
      <g
        id="03_ico/account/ticket"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M20.8828,26.2026 C19.4868,26.2026 18.3538,27.2916 18.3538,28.6346 C18.3538,29.9776 19.4868,31.0666 20.8828,31.0666 C22.2798,31.0666 23.4118,29.9776 23.4118,28.6346 C23.4118,27.2916 22.2798,26.2026 20.8828,26.2026"
          id="Fill-1"
          fill="#3D3E44"
        ></path>
        <path
          d="M20.8828,32.9146 C19.4868,32.9146 18.3538,34.0036 18.3538,35.3466 C18.3538,36.6896 19.4868,37.7786 20.8828,37.7786 C22.2798,37.7786 23.4118,36.6896 23.4118,35.3466 C23.4118,34.0036 22.2798,32.9146 20.8828,32.9146"
          id="Fill-3"
          fill="#3D3E44"
        ></path>
        <path
          d="M50,41 L23.15,41 C23.051,40.803 22.922,40.621 22.773,40.458 C22.768,40.451 22.762,40.444 22.756,40.437 C22.605,40.275 22.434,40.132 22.243,40.014 C22.235,40.008 22.226,40.005 22.218,40 C22.051,39.899 21.868,39.823 21.678,39.761 C21.629,39.745 21.582,39.73 21.531,39.717 C21.324,39.664 21.108,39.626 20.883,39.626 C20.657,39.626 20.442,39.664 20.234,39.717 C20.185,39.73 20.137,39.745 20.088,39.761 C19.897,39.823 19.715,39.899 19.548,40 C19.54,40.005 19.531,40.008 19.523,40.014 C19.333,40.132 19.161,40.275 19.011,40.437 C19.004,40.444 18.999,40.451 18.992,40.458 C18.844,40.622 18.716,40.803 18.616,41 L14,41 L14,22 L18.362,22 L18.362,22.001 C18.384,22.653 18.671,23.241 19.123,23.666 C19.134,23.676 19.147,23.683 19.158,23.693 C19.262,23.787 19.371,23.875 19.49,23.951 C19.529,23.976 19.573,23.992 19.613,24.015 C19.71,24.07 19.807,24.126 19.912,24.168 C19.99,24.2 20.075,24.217 20.158,24.241 C20.231,24.263 20.303,24.291 20.379,24.306 C20.542,24.338 20.71,24.355 20.883,24.355 C21.056,24.355 21.225,24.338 21.387,24.306 C21.464,24.291 21.534,24.263 21.608,24.241 C21.69,24.217 21.775,24.2 21.854,24.168 C21.959,24.126 22.055,24.07 22.151,24.016 C22.191,23.993 22.236,23.976 22.275,23.951 C22.395,23.875 22.504,23.787 22.607,23.693 C22.618,23.684 22.632,23.676 22.643,23.666 C23.095,23.241 23.382,22.653 23.404,22.001 L23.404,22 L50,22 L50,41 Z M53,18 L11,18 C10.447,18 10,18.448 10,19 L10,44 C10,44.552 10.447,45 11,45 L53,45 C53.553,45 54,44.552 54,44 L54,19 C54,18.448 53.553,18 53,18 L53,18 Z"
          id="Fill-5"
          fill="#3D3E44"
        ></path>
      </g>
    </SvgIcon>
  );
};
