/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { getEventIdFromHref, getNowTimestamp } from "utility";
import { EventSaleStatus, eventActions } from "modules/event";
import { modalActions } from "modules/modal";
import { push } from "connected-react-router";
import { Redirect } from "react-router";
import EventFanPage from "./EventFanPage";
import { ticketActions } from "modules/ticket";
import EventDetailInfo from "./eventDetail/EventDetailInfo";
import EventDetailArtist from "./eventDetail/EventDetailArtist";
import { changeStyleWithHosting } from "utility/hosting";
import { TicketPurchaseButton } from "./eventDetail/TicketPurchaseButton";
import { EventDetailThumbnail } from "./eventDetail/EventDetailThumbnail";
import { EventDetailLinks } from "./eventDetail/EventDetailLinks";
import { VoiceEventList } from "./eventDetail/VoiceEventList";
import { EventPlacesAndTickets } from "./eventDetail/EventPlacesAndTickets";
import GoodsThumbnail from "./eventDetail/GoodsThumbnail";
import FlowerStandThumbnail from "./eventDetail/FlowerStandThumbnail";
import { HAS_GOODS_PAGE } from "utility/streaming";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { Breadcrumb } from "../../components/atoms/Breadcrumb";
import { EventDetailHeading } from "./eventDetail/EventDetailHeading";
import { EventDetailVideoList } from "./eventDetail/EventDetailVideoList";
import { EventDetailTimetable } from "./eventDetail/EventDetailTimetable";
import back_img from "../../tmp/timetable/back.jpg";
import { Link } from "react-router-dom";
// location型定義用。保留。
// import { Location } from "history";
// import { RedirectState } from "modules/router";

// const Container = styled.div`
//   margin: 0 auto 100px;
//   @media screen and (min-width: 768px) {
//     margin-bottom: 200px;
//   }
//
//   ${breadcrumbMixin};
// `;

const Event: React.FC = () => {
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const eventInfo = useSelector((state: Store) => state.event.displayEvent);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useSelector((state: Store) => state.router.location as any); //!!
  const eventJson = useSelector((state: Store) => state.event.eventJson);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);

  const [style, setStyle] = useState<"fixed" | "block">("fixed");

  const dispatch = useDispatch();
  const classes = styles();
  const eventId = getEventIdFromHref(pathname, "events");

  useEffect(() => {
    const scrollFunction = () => {
      const threshold = getThreshold();
      const scrollTopLength = scrollTop();
      // @ts-expect-error TS18048
      setStyle(threshold < scrollTopLength ? "block" : "fixed");
    };

    // @ts-expect-error TS2322
    dispatch(eventActions.getEventJson.started({ eventId }));
    dispatch(ticketActions.fetchEventTickets.started({ eventId }));

    window.addEventListener("scroll", scrollFunction, true);

    if (location.state) {
      if (location.state.openNoticeNotOnSale) {
        noticeNotOnSale(location.state.eventSaleStatus);
      }
      if (location.state.noticeMsg) {
        dispatch(
          modalActions.toggleNotice({
            caption: location.state.noticeCaption,
            msg: location.state.noticeMsg,
            ...(location.state.modalType && {
              modalType: location.state.modalType,
            }),
          })
        );
      }
    }
    return () => {
      window.removeEventListener("scroll", scrollFunction, true);
      // clear event json
      dispatch(eventActions.clearEventJson());
      dispatch(ticketActions.clearStateByKey("eventTickets"));
      dispatch(ticketActions.clearStateByKey("eventTicketMap"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // !!

  const noticeNotOnSale = (
    saleStatus: EventSaleStatus,
    // @ts-expect-error TS2322
    ticketUrl: string = null
  ) => {
    if (isAdmin) {
      dispatch(push(`/events/${eventId}/ticket`));
    }
    let msg;
    switch (saleStatus) {
      case "ON_SALE":
        if (!ticketUrl) {
          dispatch(push(`/events/${eventId}/ticket`));
        } else {
          // @ts-expect-error TS2345
          window.open(ticketUrl, null, "noopener");
        }
        return;
      case "BEFORE_SALE":
        msg = "チケットの販売期間前です";
        break;
      case "END":
        msg = "チケットの販売は終了しました";
        break;
      default:
        msg = "チケットの販売期間外です";
        break;
    }
    dispatch(modalActions.toggleNotice({ msg }));
  };

  if (!eventInfo || (eventInfo.open.seconds > getNowTimestamp() && !isAdmin)) {
    // display only public event
    return <Redirect to="/" />;
  } else if (isAdmin) {
    // throw if admin
  }

  if (!eventJson || !eventInfo) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  if (eventInfo.isFanPage) {
    return <EventFanPage event={eventInfo} />;
  }

  // TODO(takahashi): firestore 移行完了時に artists_data を消す
  const { basic_data, artists_data, tickets_data, goods_data, places_data } =
    eventJson;
  const hasGoods =
    (HAS_GOODS_PAGE(eventInfo.eventId) &&
      eventInfo.goodsSaleStatus !== "BEFORE_SALE" &&
      eventInfo.goodsSaleStatus !== "NONE") ||
    isAdmin;
  const hasFlowerStand =
    eventInfo.flowerStandSaleStatus === "ON_SALE" || isAdmin;
  const voiceEventIds = eventInfo.havingVoiceEventIds;
  const _optionalMmdData = eventInfo.eventOptionalListData?.mmd;

  return (
    <React.Fragment>
      {/* パンクズリストを表示する */}
      {changeStyleWithHosting().commonSettings.isDisplayBreadcrumb && (
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [
              "/events",
              changeStyleWithHosting().commonSettings.menu.event.text,
            ],
            [null, displayEvent.eventTitle],
          ]}
        />
      )}

      <div id="contents" css={classes.root}>
        {/* thumbnail and page link */}
        <EventDetailThumbnail eventInfo={eventInfo} />

        {/* ページ内リンクを表示 */}
        {!displayEvent?.isCapsuleGoodsSellingEnabled && (
          <EventDetailLinks
            hasGoods={hasGoods}
            hasFlowerStand={hasFlowerStand}
          />
        )}

        <section css={classes.detail}>
          <div css={classes.layout}>
            {/* イベント日時、タイトル、アーティスト名を表示 */}
            <EventDetailHeading
              eventInfo={eventInfo}
              ticketsData={eventJson.tickets_data}
            />

            {/* ビデオリストを表示 */}
            <EventDetailVideoList />

            {/* ディスクリプション、イベント注意事項を表示 */}
            <EventDetailInfo eventInfo={eventInfo} basicData={basic_data} />

            {/* SPWNカプセルの遷移ボタンを表示する */}
            {displayEvent?.isCapsuleGoodsSellingEnabled && (
              <Link
                to={`/events/${displayEvent.eventId}/goods`}
                css={classes.capsule}
              >
                SPWNカプセル 特設ページへ
              </Link>
            )}
          </div>
        </section>

        {/* タイムテーブル */}
        {/* // if dayRefs is exist, display timetable */}
        {displayEvent.dayRefs && displayEvent.dayRefs.length > 0 && (
          <div css={classes.timetable}>
            <div css={classes.layout}>
              <EventDetailTimetable />
            </div>
          </div>
        )}

        {/* event artist info */}
        {/* TODO(takahashi): firestore 移行完了時に artistsData を消す */}
        {/* TODO: タイムテーブルが存在する時は背景を白、存在しない時は黒にする */}
        {/* TODO: タイムテーブルが存在する時は文字色を黒、存在しない時はhosting.jsonの指定の色にする */}
        {(!!artists_data?.artists?.length ||
          !!displayEvent?.artistRefs?.length) && (
          <div css={classes.artist}>
            <div css={classes.layout}>
              {/*
               // @ts-expect-error TS2322 */}
              <EventDetailArtist artistsData={artists_data} eventId={eventId} />
            </div>
          </div>
        )}

        <div css={classes.other}>
          <div css={classes.layout}>
            {/* event place and ticket info */}
            <EventPlacesAndTickets
              placesData={places_data}
              ticketsData={tickets_data}
            />

            {/* event goods, flower stand img if there is */}
            {hasGoods && !displayEvent?.isCapsuleGoodsSellingEnabled ? (
              <GoodsThumbnail goodsData={goods_data} eventInfo={eventInfo} />
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {(voiceEventIds && voiceEventIds.length > 0) ||
            (isAdmin && voiceEventIds && voiceEventIds.length > 0) ? (
              <VoiceEventList voiceEventIds={voiceEventIds} />
            ) : null}

            {/* MMD コンテンツを表示する */}
            {/* {optionalMmdData &&
              optionalMmdData?.releaseDateTime.seconds < getNowTimestamp() && (
                <EventOptionalList optionalMmdData={optionalMmdData} />
              )} */}

            {hasFlowerStand && (
              <FlowerStandThumbnail
                goodsData={goods_data}
                eventInfo={eventInfo}
              />
            )}
          </div>
        </div>
      </div>

      {eventInfo.isFanPage ||
      (eventInfo.isClosed && !eventInfo.hasVOD) ||
      eventInfo.isFesEvent ? null : (
        <TicketPurchaseButton
          event={eventInfo}
          style={style}
          noticeNotOnSale={noticeNotOnSale}
        />
      )}
    </React.Fragment>
  );
};

const scrollTop = () => {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
};

const getThreshold = () => {
  const element: HTMLDivElement | null = document.querySelector(
    ".ticketPurchaseButtonLayout"
  );
  if (!element) {
    return;
  }
  const elementPosition = element.offsetTop;
  const windowHeight = document.documentElement.clientHeight;
  const elementHeight = element.clientHeight;

  return elementPosition - windowHeight + elementHeight;
};

export default Event;

const styles = () => {
  return {
    root: css``,
    detail: css`
      padding: 30px 0;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        padding: 50px 0 50px;
      }
    `,
    layout: css`
      width: 90%;
      max-width: 980px;
      margin: 0 auto;
    `,
    timetable: css`
      padding: 100px 0;
      background-color: #000;
      background-image: url(${back_img});
    `,
    artist: css`
      padding: 40px 0 0;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        padding: 60px 0 0;
      }
    `,
    other: css`
      padding: 40px 0;
      @media screen and (min-width: 768px) {
        padding: 60px 0;
      }
    `,
    capsule: css`
      display: block;
      padding: 12px 0;
      margin: 40px auto 0;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 1px;
      background-color: #00c2ae;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 880px;
        padding: 15px 0;
        margin: 140px auto 90px;
      }
    `,
  };
};
