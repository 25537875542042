import { useSelector } from "react-redux";
import { Store } from "../store";

export const usePawFeatureFlag = () => {
  const currentHost =
    typeof window !== "undefined" ? window.location.hostname : "";
  const user = useSelector((state: Store) => state.auth.user);

  if (currentHost.includes("localhost")) {
    return true;
  }

  return user.email?.endsWith("@balus.co") ?? false;
};
