/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "firebase/auth";

import { AccountRegisterInfo } from "../../components/account/molecules/AccountRegisterInfo";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import defaultIconImg from "../../designs/images/icon_profile.svg";
import { useI18n } from "../../hooks/i18n/i18n";
import { useEmoBalance } from "../../hooks/paw/useEmoBalance";
import { authActions } from "../../modules/auth";
import { modalActions } from "../../modules/modal";
import type { Store } from "../../store";
import { fontDinMixin, Paw } from "../../styles";
import Box from "@material-ui/core/Box";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { AccountMenuSection } from "features/AccountMenuSection";
import { AccountMenuItem } from "features/AccountMenuItem";
import { PaymentIcon } from "features/icon/PaymentIcon";
import { TicketIcon } from "features/icon/TicketIcon";
import { PawChargeIcon } from "features/icon/PawChargeIcon";
import { PawUseIcon } from "features/icon/PawUseIcon";
import { ProfileIcon } from "features/icon/ProfileIcon";
import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";
import { AccountCurrentPaw } from "features/AccountCurrentPaw";
import { hasEmailProviderData } from "utility/auth";
import { LogoutIcon } from "features/icon/LogoutIcon";
import { PawBanner } from "./PawBanner";

const Container = styled.div`
  padding-bottom: 60px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding-bottom: 160px;
  }
  .mypage_info {
    padding: 20px 5%;
    border-bottom: 1px solid #e6e5e6;
    @media screen and (min-width: 768px) {
      padding: 30px 0 40px;
    }
    .mypage_info_layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 760px;
        margin: 0 auto;
      }
      .img {
        width: 20%;
        @media screen and (min-width: 768px) {
          width: 200px;
        }
        img {
          width: 100%;
          border: 2px solid #dedede;
          border-radius: 50%;
        }
      }
      .profile_area {
        width: 75%;
        @media screen and (min-width: 768px) {
          width: 520px;
        }
        /* PC表示の時のみ表示する */
        .mypage_spwnid_layout {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
          }
          .heading {
            @media screen and (min-width: 768px) {
              font-size: 18px;
            }
          }
          .id {
            @media screen and (min-width: 768px) {
              ${fontDinMixin};
              font-size: 35px;
            }
            span {
              margin-left: 15px;
              color: #00c5cb;
              font-size: 13px;
              text-decoration: underline;
            }
          }
        }
      }
      .profile_layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: 768px) {
          width: 520px;
          margin-bottom: 40px;
        }
        .profile {
          .name {
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              margin-bottom: 10px;
              font-size: 30px;
            }
          }
          .address {
            color: #8a8f8f;
            font-size: 12px;
            @media screen and (min-width: 768px) {
              font-size: 20px;
            }
          }
        }
      }
    }

    /* スマホ表示の時のみ表示する */
    .mypage_spwnid_layout {
      @media screen and (min-width: 768px) {
        display: none;
      }
      .heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 13px;
        span {
          display: block;
          margin-left: 15px;
          color: #19d0d8;
          font-size: 11px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .id {
        display: flex;
        flex-wrap: wrap;
        ${fontDinMixin};
        font-size: 23px;
      }
    }
  }
`;

export const Account: FC = () => {
  const { t } = useI18n();
  const user = useSelector((state: Store) => state.auth.user);
  const emoBalance = useEmoBalance();

  const dispatch = useDispatch();

  const copyToClipboard = (target: string) => {
    const uid = document.getElementById(target);
    const textArea = document.createElement("textarea");
    // @ts-expect-error TS2322
    textArea.value = uid.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const logout = () => {
    dispatch(authActions.logout.started());
  };

  const userDisplayName =
    user.displayName === null || user.displayName === undefined
      ? t("account.notSetUserName")
      : user.displayName;
  const userEmail =
    user.email === null || user.email === undefined
      ? t("account.notSetEmail")
      : user.email;
  let userPhotoURL =
    user.photoURL === null || user.photoURL === undefined
      ? defaultIconImg
      : user.photoURL;
  if (userPhotoURL.includes("pbs.twimg.com")) {
    userPhotoURL = userPhotoURL.replace("_normal.", "_200x200.");
  } else if (userPhotoURL.includes("graph.facebook.com")) {
    userPhotoURL += "?width=200&height=200";
  }

  const pawBalance = (() => {
    if (emoBalance.paidValue !== undefined) {
      return <Paw>{emoBalance.paidValue}</Paw>;
    }

    if (user !== undefined && user !== null && user.emailVerified === false) {
      return (
        <React.Fragment>{t("account.emailUnauthenticated")}</React.Fragment>
      );
    }

    return <BorderLoader />;
  })();

  return (
    <Container>
      <BreadcrumbNavigation
        breadcrumbs={[
          { label: "ホーム", link: "/" },
          { label: t("common.routes.account"), link: null },
        ]}
      />

      <PawBanner />

      {/* @TODO コンポーネントの整理したい。
          - 188 ~ 204までの内容をpropsで直渡しする方がよさそう。
          - class名などを削除したい。
      */}
      <AccountRegisterInfo
        user={user}
        userPhotoURL={userPhotoURL}
        userDisplayName={userDisplayName}
        userEmail={userEmail}
        copyToClipboard={copyToClipboard}
      />

      <AccountCurrentPaw
        pawBalance={pawBalance}
        onClick={togglePawChargeModal}
      />

      <Box maxWidth={"740px"} margin={"40px auto 0"}>
        <AccountMenuSection heading={t("account.news.title")} isEmergency>
          <Box component="ul">
            <AccountMenuItem
              to="/account/notifications"
              icon={<InfoOutlinedIcon />}
              menuName={t("account.news.list")}
            />
          </Box>
        </AccountMenuSection>
      </Box>

      <Box maxWidth={"740px"} margin={"40px auto 0"}>
        <AccountMenuSection heading={t("account.ticket.title")}>
          <Box component="ul">
            <AccountMenuItem
              to="/account/ticket"
              icon={<TicketIcon />}
              menuName={t("account.ticket.myTicket")}
            />

            <AccountMenuItem
              to="/account/ticket/vod"
              icon={<TicketIcon />}
              menuName={t("account.ticket.myLive")}
            />

            <AccountMenuItem
              to="/voucher"
              icon={<TicketIcon />}
              menuName={t("account.ticket.voucher")}
            />
          </Box>
        </AccountMenuSection>
      </Box>

      <Box maxWidth={"740px"} margin={"40px auto 0"}>
        <AccountMenuSection heading={t("account.history.title")}>
          <Box component="ul">
            <AccountMenuItem
              to="/account/settlement/history"
              icon={<PaymentIcon />}
              menuName={t("account.history.settlementHistory")}
            />

            <AccountMenuItem
              to="/account/lottery"
              icon={<TicketIcon />}
              menuName={t("account.history.lotteryApplicationList")}
            />

            <AccountMenuItem
              to="/account/paw-charge-history"
              icon={<PawChargeIcon />}
              menuName={t("account.history.topUpHistory")}
            />

            <AccountMenuItem
              to="/account/paw-use-history"
              icon={<PawUseIcon />}
              menuName={t("account.history.usageHistory")}
            />
          </Box>
        </AccountMenuSection>
      </Box>

      <Box maxWidth={"740px"} margin={"40px auto 0"}>
        <AccountMenuSection heading={t("account.account.title")}>
          <Box component="ul">
            <AccountMenuItem
              to="/account/edit"
              icon={<ProfileIcon />}
              menuName={t("account.account.editProfileInfo")}
            />

            <AccountMenuItem
              to="/account/credit"
              icon={<CreditCardIcon />}
              menuName={t("account.account.creditCardInfo")}
            />

            <AccountMenuItem
              to="/account/address"
              icon={<HomeOutlinedIcon />}
              menuName={t("account.account.editShippingAddress")}
            />

            {0 < Object.keys(user).length && hasEmailProviderData(user) && (
              <AccountMenuItem
                to="/account/edit/password"
                icon={<LockOpenIcon />}
                menuName={t("account.editPassword")}
              />
            )}

            <AccountMenuItem
              to="/"
              icon={<LogoutIcon />}
              menuName={t("account.account.logout")}
              onClick={logout}
            />
          </Box>
        </AccountMenuSection>
      </Box>
    </Container>
  );
};
