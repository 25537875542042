/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { push } from "connected-react-router";

import {
  getSlashSeparatedDateTimeStringFromFbTimestamp,
  convertMapToValues,
  getNowTimestamp,
} from "utility";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContentsLayout } from "ui/SubContentsLayout";

import spwnAvatorImg from "designs/images/avator/spwn_avator.png";
import type { UserNotification } from "@spwn/types/firebase/firestore";

type Props = {};
export const AccountNotificationList: React.FC<Props> = (_props) => {
  const notificationMap = useSelector(
    (state: Store) => state.notification.notificationMap
  );

  const dispatch = useDispatch();
  const { t } = useI18n();
  const classes = accountNotificationListStyle();

  const handleClickListItem = (notification: UserNotification) => {
    dispatch(push(`/account/notifications/${notification._id}`));
  };

  // check if render is enable
  if (
    !notificationMap ||
    (notificationMap && Object.keys(notificationMap).length === 0)
  ) {
    return <NoNoticesLayout />;
  }

  const nowTimestamp = getNowTimestamp();
  const displayNotifications = convertMapToValues(notificationMap).filter(
    (noification) => {
      return noification.startAt.seconds < nowTimestamp;
    }
  );

  if (displayNotifications.length === 0) {
    return <NoNoticesLayout />;
  }

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.notifications")],
        ]}
      />

      <div css={classes.root}>
        <h2>{t("notifications.title")}</h2>

        <ul css={classes.noticeList}>
          {displayNotifications
            .sort((a, b) => b.startAt.seconds - a.startAt.seconds)
            .map((userNotification, i) => {
              const sendAt = getSlashSeparatedDateTimeStringFromFbTimestamp(
                userNotification.startAt
              );

              return (
                <li
                  key={i}
                  css={classes.noticeItem}
                  onClick={() => handleClickListItem(userNotification)}
                >
                  <div css={classes.icon}>
                    <img src={spwnAvatorImg} alt="spwn_avator" />
                  </div>

                  <div css={classes.info}>
                    <h3>{userNotification.subject}</h3>
                    <p>{sendAt}</p>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </SubContentsLayout>
  );
};

const NoNoticesLayout = () => {
  const { t } = useI18n();
  const classes = accountNotificationListStyle();
  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.notifications")],
        ]}
      />

      <div css={classes.root}>
        <h2>{t("notifications.title")}</h2>
        <p>{t("notifications.empty")}</p>
      </div>
    </SubContentsLayout>
  );
};

const accountNotificationListStyle = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 40px 0 80px;
      margin: 0 auto;
      h2 {
        padding-bottom: 8px;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: normal;
        border-bottom: 1px solid #e6e5e6;
      }
      p {
        font-size: 14px;
      }
    `,
    noticeList: css``,
    noticeItem: css`
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid #e6e5e6;
      cursor: pointer;
    `,
    icon: css`
      margin-right: 24px;
      border: 2px solid #dedede;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 56px;
        height: 56px;
        object-fit: cover;
      }
    `,
    info: css`
      width: calc(100% - 80px);
      h3 {
        margin-bottom: 8px;
        line-height: 1.3em;
      }
      p {
        color: #8c8c8c;
      }
    `,
  };
};
