import { StreamingType } from "modules/streaming";

const getUseCloudfrontFromLocalStorage = (): boolean => {
  try {
    const useCloudfrontLocalstorage = localStorage.getItem("useCloudfront");
    if (useCloudfrontLocalstorage === null) {
      return false;
    }
    const { expiredAt } = JSON.parse(useCloudfrontLocalstorage);

    if (!expiredAt || typeof expiredAt !== "number") {
      return false;
    }

    const currentTime = new Date().getTime();

    if (expiredAt < currentTime) {
      return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const checkIfUseCloudFlare = (streamingType: StreamingType): boolean => {
  try {
    // useCloudflareWorkersが指定されている場合は、true
    const location = window.location.search;
    const queryParams = new URLSearchParams(location);
    const useCloudflareWorkers = queryParams.get("useCloudflareWorkers");
    if (useCloudflareWorkers != null) {
      return true;
    }

    // Liveの場合はcloudfrontを使用する
    if (streamingType === "Live") {
      return false;
    }

    // localstorageにcloudfrontを使うという情報がある場合は、false
    if (getUseCloudfrontFromLocalStorage()) {
      return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
