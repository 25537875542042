/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { push } from "connected-react-router";

import { authActions } from "modules/auth";
import { loadingActions } from "modules/loading";

import { useI18n } from "hooks/i18n/i18n";

import appConfig from "constants/appConfig";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { Button } from "components/atoms/button/Button";
import { BorderLinkButton } from "components/atoms/button/BorderLinkButton";
import { SubContentsLayout } from "ui/SubContentsLayout";

import icon_done from "designs/images/icon_done.svg";

interface CheckListTypes {
  value1: boolean;
  value2: boolean;
  value3: boolean;
  value4: boolean;
  value9: boolean;
}

interface Props {}
export const AccountDelete: React.FC<Props> = (_props) => {
  const user = useSelector((state: Store) => state.auth.user);
  // @ts-expect-error TS2345
  const [checkedList, setCheckedList] = useState<CheckListTypes>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const { t } = useI18n();
  const dispatch = useDispatch();
  const classes = styles();

  const didMount = () => {
    return () => {};
  };
  useEffect(didMount, []);

  const watchCheckedList = () => {
    if (
      checkedList &&
      checkedList.value1 &&
      checkedList.value2 &&
      checkedList.value3 &&
      checkedList.value4 &&
      checkedList.value9
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  useEffect(watchCheckedList, [checkedList]);

  // TODO: inputのbooleanを取得する処理 → Notionに記載する
  // @ts-expect-error TS7006
  const handleCheck = (event, key: keyof CheckListTypes) => {
    const value = event.currentTarget.checked;
    setCheckedList({ ...checkedList, [key]: value });
  };

  const fetchFbToken = async (_noUseUid = false) => {
    const { currentUser } = firebase.auth();
    if (!currentUser) {
      return null;
    }
    // use token even when local debug
    return currentUser.getIdToken(true);
  };

  const accountDelete = async () => {
    dispatch(loadingActions.toggleLoading({ msg: "アカウントを削除中" }));

    const fbToken = await fetchFbToken();
    // @ts-expect-error TS2769
    const response = await fetch(appConfig.PaymentSystem.deleteAccount, {
      method: "POST",
      headers: { Authorization: `'Bearer ${fbToken}` },
    });
    // console.log(response.status)

    dispatch(loadingActions.toggleLoading({}));
    if (response.status === 200) {
      dispatch(push(`/`));
      dispatch(authActions.logout.started());
    } else {
      setIsError(true);
    }
  };

  if (isError) {
    return (
      <SubContentsLayout>
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            ["/account/edit", t("common.routes.accountEdit")],
            [null, "アカウントを削除"],
          ]}
        />

        <section css={classes.error}>
          <p>{t("accountDelete.error.text1")}</p>
          <p>{t("accountDelete.error.text2")}</p>
        </section>
      </SubContentsLayout>
    );
  }

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          ["/account/edit", t("common.routes.accountEdit")],
          [null, "アカウントを削除"],
        ]}
      />

      <section css={classes.root}>
        <h2>{t("accountDelete.h2")}</h2>
        <div css={classes.text}>
          <p>{t("accountDelete.message.text1")}</p>
          <p>{t("accountDelete.message.text2")}</p>
        </div>

        <h3>{t("accountDelete.h3")}</h3>
        <div css={classes.user}>
          <div css={classes.icon}>
            {/*
             // @ts-expect-error TS2322 */}
            <img src={user.photoURL} alt={user.displayName} />
          </div>

          <div css={classes.info}>
            <p className="name">{user.displayName}</p>
            <p className="email">{user.email}</p>
            <p className="uid">{user.uid}</p>
          </div>
        </div>

        <div css={classes.input}>
          <div>
            <label css={classes.inputItem}>
              <input
                type="checkbox"
                onChange={(event) => handleCheck(event, "value1")}
              />
              <span>{t("accountDelete.item.text1")}</span>
            </label>
          </div>

          <div>
            <label css={classes.inputItem}>
              <input
                type="checkbox"
                onChange={(event) => handleCheck(event, "value2")}
              />
              <span>{t("accountDelete.item.text2")}</span>
            </label>
          </div>

          <div>
            <label css={classes.inputItem}>
              <input
                type="checkbox"
                onChange={(event) => handleCheck(event, "value3")}
              />
              <span>{t("accountDelete.item.text3")}</span>
            </label>
          </div>

          <div>
            <label css={classes.inputItem}>
              <input
                type="checkbox"
                onChange={(event) => handleCheck(event, "value4")}
              />
              <span>{t("accountDelete.item.text4")}</span>
            </label>
          </div>

          <div>
            <label css={classes.inputItem}>
              <input
                type="checkbox"
                onChange={(event) => handleCheck(event, "value9")}
              />
              <span>{t("accountDelete.item.text9")}</span>
            </label>
          </div>
        </div>

        {/* button */}
        <div css={classes.button}>
          <Button
            text={t("accountDelete.h2")}
            color={"#ff0000"}
            disabled={isDisabled}
            onClick={accountDelete}
          />

          <BorderLinkButton
            url={"/account/edit"}
            label={t("accountDelete.cancel")}
          />
        </div>
      </section>
    </SubContentsLayout>
  );
};

const styles = () => {
  return {
    root: css`
      width: 90%;
      max-width: 640px;
      padding: 40px 0;
      margin: 0 auto;
      h2 {
        margin-bottom: 16px;
        font-size: 20px;
      }
      h3 {
        margin-bottom: 16px;
        font-size: 16px;
      }
    `,
    text: css`
      margin-bottom: 40px;
      p {
        font-size: 14px;
        line-height: 1.5em;
      }
    `,
    user: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
      }
    `,
    icon: css`
      width: 80px;
      margin-right: 16px;
      @media screen and (min-width: 768px) {
        width: 120px;
        margin-right: 24px;
      }
      img {
        width: 80px;
        height: 80px;
        border: 1px solid #dedede;
        border-radius: 50%;
        object-fit: cover;
        @media screen and (min-width: 768px) {
          width: 120px;
          height: 120px;
        }
      }
    `,
    info: css`
      width: calc(100% - 96px);
      @media screen and (min-width: 768px) {
        width: calc(100% - 144px);
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .email {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 1.3em;
      }
      .uid {
        font-size: 14px;
        line-height: 1.3em;
      }
    `,
    input: css`
      margin-bottom: 56px;
    `,
    inputItem: css`
      line-height: 1.5em;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      input[type="checkbox"] {
        opacity: 0;
        appearance: none;
        position: absolute;
      }
      span {
        display: inline-flex;
        font-size: 14px;
      }
      input[type="checkbox"]:checked + span {
        color: #00c2ae;
      }
      input[type="checkbox"]:disabled + span {
        color: #d2d2d2;
      }
      input[type="checkbox"] + span::before {
        display: block;
        content: "";
        width: 1rem;
        height: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 4px;
        margin-right: 0.5rem;
      }
      input[type="checkbox"]:checked + span::before {
        color: #00c2ae;
        background-image: url(${icon_done});
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-color: #00c2ae;
        border: 1px solid #00c2ae;
      }
      input[type="checkbox"]:disabled + span::before {
        color: #d2d2d2;
        background-color: #d2d2d2;
        border: 1px solid #d2d2d2;
      }
    `,
    button: css`
      max-width: 400px;
      margin: 0 auto;
      button:not(:last-child) {
        margin-bottom: 12px;
      }
    `,
    error: css`
      padding: 40px 5%;
      text-align: center;
      p {
        font-size: 14px;
        line-height: 1.8em;
      }
    `,
  };
};
