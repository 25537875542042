/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import { purchaseActions, PawBalance } from "../../modules/purchase";
import { eventActions, DownloadContents } from "../../modules/event";
import { modalActions } from "../../modules/modal";
import type {
  Event,
  CheeringItem,
  DlcInfo,
  DlcSettings,
  DlcItem,
} from "@spwn/types/firebase/firestore";

import {
  EventInfo,
  convertMapToValuesWithId,
  getNowTimestamp,
  getEventIdFromHref,
  getEventDisplayInfo,
} from "../../utility";

import { sanitizer } from "../../utility/sanitize";
import { fetchFirestoreDocument } from "../../utility/firebase";
import { BreadcrumbArea } from "components/common/Link";
import { changeStyleWithHosting } from "utility/hosting";
import { isDisplayPeriod } from "utility/index";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import styled from "@emotion/styled";
import { breadcrumbMixin, fontDinMixin, Paw } from "../../styles";
import { useEmoBalance } from "hooks/paw/useEmoBalance";

interface Props {
  event: Event;
}

interface TextByContentsCategory {
  headline: string;
  subText?: string;
  caution: JSX.Element;
}

const Container = styled.div`
  margin: 0 auto;
  ${breadcrumbMixin};
  .event_eye_catch {
    background-color: #000;
    .eyecatch_layout {
      width: 980px;
      max-width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .event_info {
    padding: 30px 0 80px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      padding: 50px 0 100px;
    }
    .event_info_layout {
      width: 980px;
      max-width: 90%;
      margin: 0 auto 50px;
      @media screen and (min-width: 768px) {
        margin: 0 auto 80px;
      }
      .event_name {
        margin-bottom: 25px;
        font-size: 25px;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 32px;
        }
      }
      .artist_name {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-bottom: 45px;
          font-size: 24px;
        }
      }
    }
    .all_download {
      width: 980px;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .downloadButton {
    max-width: 500px;
    width: 100%;
    padding: 15px 0;
    margin: 0 auto;
    text-align: center;
    background-color: #00c2ae;
    cursor: pointer;
    &.finished {
      background-color: #d4d4d4;
    }
    .buttonLabel {
      color: #fff;
      font-weight: bold;
    }
  }
  .eventThumbnailLayout {
    background-color: #000;
    .eventThumbnail {
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
      }
      .thumbnail {
        width: 100%;
      }
      .labelDelivery {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 100px;
      }
    }
  }
  .fanClubPageInfoLayout {
    padding: 30px 0 80px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      padding: 50px 0 100px;
    }
    .fanClubPageInfo {
      width: 980px;
      max-width: 90%;
      margin: 0 auto 50px;
      @media screen and (min-width: 768px) {
        margin: 0 auto 80px;
      }
      .pageTitlle {
        margin-bottom: 25px;
        font-size: 25px;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 32px;
        }
      }
      .artistName {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-bottom: 45px;
          font-size: 24px;
        }
      }
    }
    .guidanceToDownload {
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
      .HeadingDesign {
        margin-bottom: 20px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
        }
        h2 {
          ${fontDinMixin};
          padding: 5px 0 0 15px;
          margin-bottom: 5px;
          font-size: 35px;
          border-left: 5px solid #01ffe1;
          @media screen and (min-width: 768px) {
            margin-bottom: 0;
            letter-spacing: 3px;
          }
        }
        p {
          padding-left: 21px;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .downloadCautionText {
        margin-bottom: 70px;
        line-height: 1.5em;
      }
      .selectDownloadItem {
        margin: 0 auto 30px;
        .downloadDescription {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
        }
        select {
          max-width: 600px;
          width: 100%;
          padding: 8px 28px 8px 17px;
          font-size: 16px;
          font-weight: bold;
          border: 1px solid #dedede;
          background: url("/images/select_pulldown.png") right 50% no-repeat;
          background-size: 29px, 100%;
          border-radius: 0;
          appearance: none;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .sendPawLayout {
        margin: 0 auto 30px;
        .sendPawDescription {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
        }
        select {
          width: 200px;
          padding: 8px 28px 8px 17px;
          font-size: 16px;
          font-weight: bold;
          border: 1px solid #dedede;
          background: url("/images/select_pulldown.png") right 50% no-repeat;
          background-size: 29px, 100%;
          border-radius: 0;
          appearance: none;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .currentPawLayout {
        margin: 0 auto 70px;
        @media screen and (min-width: 768px) {
          margin: 0 auto 50px;
        }
        .currentPaw {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @media screen and (min-width: 768px) {
            width: 300px;
            margin: 0 auto;
          }
          .info {
            display: flex;
            align-items: flex-end;
            .text {
              margin-right: 20px;
              padding-bottom: 5px;
            }
            .paw {
              font-weight: bold;
              &:after {
                width: 25px;
                height: 25px;
                @media screen and (min-width: 768px) {
                  width: 25px;
                  height: 25px;
                  margin-left: 10px;
                }
              }
            }
          }
          .charge {
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background-color: #00c2ae;
            border-radius: 50%;
            span {
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

const DownloadPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  > .inner {
    width: 90%;
    height: 85vh;
    padding: 30px 5%;
    margin: 60px auto 0;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      width: 550px;
      height: auto;
      padding: 50px 2.5%;
      overflow-y: auto;
    }
    > .confirm {
      .event_conf {
        margin-bottom: 40px;
        .check_send_paw {
          margin-bottom: 20px;
          font-size: 18px;
          text-align: center;
          line-height: 1.5em;
          @media screen and (min-width: 768px) {
            margin-bottom: 40px;
            font-size: 20px;
          }
        }
        img {
          width: 100%;
          margin-bottom: 15px;
        }
        .event_name {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
        .artist_name {
          margin-bottom: 20px;
          font-weight: bold;
        }
        .info {
          color: gray;
          font-size: 14px;
          text-align: right;
          line-height: 1.5em;
        }
        .info_layout {
          margin-top: 20px;
          .info_inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            .text {
              font-size: 15px;
              font-weight: bold;
            }
            .paw {
              font-size: 25px;
              font-weight: bold;
              &:after {
                width: 20px;
                height: 20px;
                @media screen and (min-width: 768px) {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
      .download_button {
        width: 100%;
        max-width: 90%;
        margin: 0 auto 20px;
        text-align: center;
        background-color: #00c2ae;
        border: 2px solid #00c2ae;
        cursor: pointer;
        .button {
          color: #fff;
          font-weight: bold;
          &.download_wait {
            padding: 11px 0;
          }
        }
        a {
          display: block;
          padding: 11px 0;
          color: #fff;
        }
      }
      .cansel_button {
        width: 100%;
        max-width: 90%;
        padding: 11px 0;
        margin: 0 auto;
        text-align: center;
        background-color: #fff;
        border: 2px solid #00c2ae;
        cursor: pointer;
        .button {
          color: #00c2ae;
          font-weight: bold;
        }
      }
    }
  }
`;

const CautionPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  > .inner {
    width: 90%;
    height: 85vh;
    padding: 40px 5%;
    margin: 60px auto 0;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      width: 550px;
      height: auto;
      padding: 50px 2.5%;
      overflow: auto;
    }
    > .event_conf {
      margin-bottom: 40px;
      .check_send_paw {
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 20px;
        }
      }
      img {
        width: 100%;
        margin-bottom: 15px;
      }
      .event_name {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .artist_name {
        margin-bottom: 20px;
        font-weight: bold;
      }
      .info_layout {
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .text {
            font-size: 15px;
            font-weight: bold;
          }
          .paw {
            font-size: 25px;
            font-weight: bold;
            &:after {
              width: 20px;
              height: 20px;
              @media screen and (min-width: 768px) {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
    > .download_button {
      width: 100%;
      max-width: 90%;
      margin: 0 auto 20px;
      text-align: center;
      background-color: #00c2ae;
      border: 2px solid #00c2ae;
      cursor: pointer;
      .button {
        width: 100%;
        padding: 13px 0;
        color: #fff;
        font-weight: bold;
        text-align: center;
        background-color: #00c2ae;
        cursor: pointer;
      }
      a {
        display: block;
        padding: 11px 0;
        color: #fff;
      }
    }
    .cansel_button {
      width: 100%;
      max-width: 90%;
      padding: 11px 0;
      margin: 0 auto;
      text-align: center;
      background-color: #fff;
      border: 2px solid #00c2ae;
      cursor: pointer;
      .button {
        color: #00c2ae;
        font-weight: bold;
      }
    }
  }
`;

const EventFanPage: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const auth = useSelector((state: Store) => state.auth.isLogin);
  const user = useSelector((state: Store) => state.auth.user);
  const emoBalance = useEmoBalance();
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const cheering: CheeringItem[] = useSelector(
    (state: Store) => state.event.cheeringItemMap
  );
  const fileDownloadInfo: DownloadContents = useSelector(
    (state: Store) => state.event.fileDownloadInfo
  );
  const isSuccessUsePAW: boolean = useSelector(
    (state: Store) => state.purchase.isSuccessUsePAW
  );

  // @ts-expect-error TS2345
  const [eventId, setEventId] = useState<string>(null);
  // @ts-expect-error TS2345
  const [dlcItems, setDlcItems] = useState<DlcItem[]>(null);
  // @ts-expect-error TS2345
  const [dlcSettings, setDlcSettings] = useState<DlcSettings>(null);
  const [contentsCategoryTextMap, setContentsCategoryTextMap] = useState(null);
  // @ts-expect-error TS2345
  const [selectedDlcItem, setSelectedDlcItem] = useState<DlcItem>(null);
  const [isDisplayModal, setDisplayModal] = useState<boolean>(false);
  const [isDisplayCaution, setDisplayCaution] = useState<boolean>(false);
  const [isPurchaseModal, setIsPurchaseModal] = useState<boolean>(false);
  const [isProcessingAttachment, setProcessingAttachment] =
    useState<boolean>(false);
  const [voiceTransactionsList, setVoiceTransactionsList] = useState(null);
  const [isTransactionsExists, setIsTransactionsExists] =
    useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let _timer: any; // !!
  const eventInfo: EventInfo = getEventDisplayInfo(props.event);
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;

  const didMount = () => {
    // @ts-expect-error TS2322
    dispatch(eventActions.fetchCheeringItems.started({ eid: props.event._id }));
    // @ts-expect-error TS2322
    const getEventId: string = getEventIdFromHref(pathname, "events");
    fetchVoiceTransactions(getEventId);
    setEventId(getEventId);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []); // TODO

  useEffect(() => {
    if (eventId) {
      getDownloadContens();
    }

    /**
     * ダウンロードリンクの有効期限を設定する
     * setTimeoutがアタッチされたかをwatchし、再度アタッチされないようにStateを参照している
     */
    if (
      fileDownloadInfo &&
      isProcessingAttachment === false &&
      isDisplayModal === true
    ) {
      setProcessingAttachment(true);
      const validTime = fileDownloadInfo.seconds * 1000;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      _timer = window.setTimeout(() => {
        setProcessingAttachment(false);
        setDisplayModal(false);
      }, validTime);
      // !!!
    }

    if (isProcessingAttachment === true && isDisplayModal === false) {
      if (_timer) {
        clearTimeout(_timer);
        setProcessingAttachment(false);
        setDisplayModal(false);
      }
    }
  }, [fileDownloadInfo, isProcessingAttachment, isDisplayModal, eventId]);

  // successUsePAWStateがtrueになったらリンクを作成する
  useEffect(() => {
    if (isSuccessUsePAW) {
      const payload = {
        eid: eventId,
        cid: selectedDlcItem._id, //content1 ~ content3
      };
      dispatch(eventActions.fileDownload.started(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUsePAW]);

  const watchdlcItemMap = () => {
    let contentsCategoryTextMap: TextByContentsCategory;
    if (!dlcSettings || dlcSettings.contentsCategory === "voice") {
      contentsCategoryTextMap = {
        headline: "VOICE",
        subText: "ボイス",
        caution: (
          <React.Fragment>
            <p>※各ボイスのファイル形式はAACです。</p>
            <p>
              ※スマートフォン・タブレットでもお楽しみいただけますが、iOS（iPhone/iPad等）をお使いの方はPCからダウンロードの上、iTunes等よりお楽しみください。
            </p>
            <p>
              ※フィーチャーフォンには対応しておりませんので、PCをご利用ください。
            </p>
            <p>
              ※各種再生用プレーヤーの使用方法についてご不明の点は、各種ソフトのヘルプ等をご確認ください。
            </p>
            <p>
              ※サイトアクセスならびに、ファイルダウンロード等にかかる通信費はお客様のご負担となります。
            </p>
            <p>
              ※ご購入いただいたボイスは、期限内であればこちらのページから再度ダウンロードが可能です。
            </p>
          </React.Fragment>
        ),
      };
    } else if (dlcSettings.contentsCategory === "mmd") {
      contentsCategoryTextMap = {
        headline: "DOWNLOAD",
        subText: "ダウンロード",
        caution: <React.Fragment></React.Fragment>,
      };
    } else if (dlcSettings.contentsCategory === "music") {
      contentsCategoryTextMap = {
        headline: "MUSIC",
        subText: "音楽",
        caution: (
          <React.Fragment>
            <p>※各音源のファイル形式はWAVです。</p>
            <p>
              ※スマートフォン・タブレットでもお楽しみいただけますが、iOS（iPhone/iPad等）をお使いの方はPCからダウンロードの上、iTunes等よりお楽しみください。
            </p>
            <p>
              ※フィーチャーフォンには対応しておりませんので、PCをご利用ください。
            </p>
            <p>
              ※各種再生用プレーヤーの使用方法についてご不明の点は、各種ソフトのヘルプ等をご確認ください。
            </p>
            <p>
              ※サイトアクセスならびに、ファイルダウンロード等にかかる通信費はお客様のご負担となります。
            </p>
            <p>
              ※ご購入いただいた音源は、期限内であればこちらのページから再度ダウンロードが可能です。
            </p>
          </React.Fragment>
        ),
      };
    }

    // @ts-expect-error TS2345
    setContentsCategoryTextMap(contentsCategoryTextMap);
  };
  useEffect(watchdlcItemMap, [dlcSettings]);

  // @ts-expect-error TS7006
  const fetchVoiceTransactions = async (eventId) => {
    // @ts-expect-error TS7034
    const voiceTransactionsMap = [];
    if (!user.uid) {
      return null;
    }
    const fetchTransactionsMap = await firebase
      .database()
      .ref(`/usageLogs/${user.uid}/dates`)
      .orderByChild("eid")
      .equalTo(eventId)
      .once("value");
    fetchTransactionsMap.forEach((transactionsItem) => {
      voiceTransactionsMap.push(transactionsItem.val());
    });
    // @ts-expect-error TS2345
    setVoiceTransactionsList(voiceTransactionsMap);
  };

  // @ts-expect-error TS7006
  const checkTransactionsExists = (itemId) => {
    const isTransactions =
      // @ts-expect-error TS18047
      voiceTransactionsList.filter(
        // @ts-expect-error TS7006
        (transaction) => transaction.itemId === itemId
      ).length > 0;
    return isTransactions;
  };

  // @ts-expect-error TS7006
  const setSendPawValue = (dlcId, dlcInfo) => {
    const isTransactionsExists = checkTransactionsExists(dlcInfo.itemId);

    setSelectedDlcItem(dlcInfo);
    setIsTransactionsExists(isTransactionsExists);

    if (isTransactionsExists) {
      const payload = {
        eid: eventId,
        cid: dlcId, //content1 ~ content3
      };
      dispatch(eventActions.fileDownload.started(payload));
      setDisplayModal(true);
      return;
    }

    //所持PAWのチェックを行う
    if (!isTransactionsExists && emoBalance.paidValue < dlcInfo.price) {
      setDisplayCaution(true);
      return;
    }

    setIsPurchaseModal(true);
  };

  const usePawAndDownload = () => {
    //PAWを使う処理
    const payload = {
      suppressMsg: false,
      eid: eventId,
      itemId: selectedDlcItem.itemId,
      freePrice: 0,
      paidPrice: selectedDlcItem.price,
    };
    dispatch(purchaseActions.usePAW.started(payload));
    //セレクトボックスとstateを初期化する
    setIsPurchaseModal(false);
    setDisplayModal(true);
  };

  const initialize = () => {
    fetchVoiceTransactions(eventId);
    setDisplayModal(false);
    setIsPurchaseModal(false);
    setIsTransactionsExists(false);
    // @ts-expect-error TS2345
    setSelectedDlcItem(null);
    dispatch(eventActions.clearStateByKey("fileDownloadInfo"));
    dispatch(purchaseActions.clearStateByKey("isSuccessUsePAW"));
  };

  const logDownload = () => {
    dispatch(
      eventActions.incrementDownload.started({
        eid: eventId,
        // @ts-expect-error TS2322
        itemId: selectedDlcItem._id,
      })
    );
  };

  const togglePawChargeModal = () => {
    setDisplayCaution(false);
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const getDownloadContens = async () => {
    // moduleで取得してstoreに入れたみ
    const dlcItemMap = await fetchFirestoreDocument<DlcInfo>(`/DLC/${eventId}`);
    const dlcData = convertMapToValuesWithId(dlcItemMap);
    // contentとsettingsが1ドキュメントに同列で格納されているため、取得時点で切り分ける
    const dlcItems = dlcData.filter(
      (el) => el._id?.indexOf("content") !== -1
    ) as DlcItem[];
    const dlcSettings = dlcData?.filter(
      (el) => el._id?.indexOf("settings") !== -1
    )[0] as DlcSettings;
    setDlcItems(dlcItems);
    setDlcSettings(dlcSettings);
  };

  const classesFanPageStyles = fanPageStyles();

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/events", breadcrumbText],
          [null, eventInfo.eventTitle],
        ]}
      />

      <div className="eventThumbnailLayout">
        <div className="eventThumbnail">
          <img src={eventInfo.thumbnail} alt={eventInfo.eventTitle} />
        </div>
      </div>

      <div className="event_info fanClubPageInfoLayout">
        <article className="fanClubPageInfo">
          <h2 className="pageTitlle" style={{ whiteSpace: "pre-line" }}>
            {eventInfo.eventTitle}
          </h2>
          <p className="artistName">{eventInfo.artists}</p>
          <div
            css={classesFanPageStyles.description}
            className="eventDescription"
            dangerouslySetInnerHTML={{
              __html: sanitizer(eventInfo.description),
            }}
          />
        </article>

        <div className="all_download guidanceToDownload">
          <div className="HeadingDesign">
            {/*
             // @ts-expect-error TS2339 */}
            <h2>{contentsCategoryTextMap?.headline}</h2>
            {/*
             // @ts-expect-error TS2339 */}
            <p>{contentsCategoryTextMap?.subText}</p>
          </div>

          {eventInfo.isClosed ? (
            <div className="downloadButton finished">
              <p className="buttonLabel">ダウンロード期間は終了しました</p>
            </div>
          ) : (
            <DisplayInfoToDownload
              // @ts-expect-error TS2322
              auth={auth}
              user={user}
              dlcItems={dlcItems}
              emoBalance={emoBalance}
              cheering={cheering}
              // @ts-expect-error TS2322
              contentsCategoryTextMap={contentsCategoryTextMap}
              setSendPawValue={setSendPawValue}
            />
          )}
        </div>
      </div>

      {/* ダウンロードリンクを作成・ダウンロードを行う */}
      {isDisplayModal === true ? (
        <DownloadPopup id="download_popup">
          <div className="inner" id="download_popup_inner">
            <div className="confirm">
              {/* 応援PAWの選択で表示を変える */}
              <div className="event_conf">
                {/* <img src={eventInfo.thumbnail} alt={eventInfo.title}/> */}
                <h3 className="event_name" style={{ whiteSpace: "pre-line" }}>
                  {selectedDlcItem.name}
                </h3>
                <p className="artist_name">{selectedDlcItem.artist}</p>
                <p className="info">
                  ファイル形式：{selectedDlcItem.extension}
                </p>
                <p className="info">
                  ファイルサイズ：{selectedDlcItem.fileSize}
                </p>
              </div>

              <div className="download_button">
                {fileDownloadInfo ? (
                  <p className="button">
                    <a
                      href={fileDownloadInfo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      onClick={() => {
                        logDownload();
                        initialize();
                      }}
                    >
                      ダウンロード
                    </a>
                  </p>
                ) : (
                  <p className="button download_wait">
                    ダウンロードリンク作成中...
                  </p>
                )}
              </div>

              <div
                className="cansel_button"
                onClick={() => {
                  setDisplayModal(false);
                  initialize();
                }}
              >
                <p className="button">キャンセル</p>
              </div>
            </div>
          </div>
        </DownloadPopup>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {/* 購入を行う */}
      {isPurchaseModal === true ? (
        <DownloadPopup id="download_popup">
          <div className="inner" id="download_popup_inner">
            <div className="confirm">
              <div className="event_conf">
                {/* <img src={eventInfo.thumbnail} alt={eventInfo.title}/> */}
                <h3 className="event_name" style={{ whiteSpace: "pre-line" }}>
                  {selectedDlcItem.name}
                </h3>
                <p className="artist_name">{selectedDlcItem.artist}</p>
                <p className="info">
                  ファイル形式：{selectedDlcItem.extension}
                </p>
                <p className="info">
                  ファイルサイズ：{selectedDlcItem.fileSize}
                </p>

                <div className="info_layout">
                  <div className="info_inner">
                    <p className="text">必要PAW</p>
                    {isTransactionsExists ? (
                      <Paw className="paw">0</Paw>
                    ) : (
                      <p className="paw">{selectedDlcItem.price}</p>
                    )}
                  </div>
                  <div className="info_inner">
                    <p className="text">所持PAW</p>
                    <Paw className="paw">{emoBalance.paidValue}</Paw>
                  </div>
                </div>
              </div>

              <div className="download_button" onClick={usePawAndDownload}>
                <p className="button download_wait">購入する</p>
              </div>

              <div
                className="cansel_button"
                onClick={() => {
                  setDisplayModal(false);
                  initialize();
                }}
              >
                <p className="button">キャンセル</p>
              </div>
            </div>
          </div>
        </DownloadPopup>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {/* PAWが足りなかったことの通知を行う */}
      {isDisplayCaution ? (
        <CautionPopup id="caution_popup">
          <div className="inner" id="caution_popup_inner">
            <div className="event_conf">
              <h3 className="check_send_paw">
                購入するためのPAWが不足しています。
              </h3>
              <img src={eventInfo.thumbnail} alt={eventInfo.title} />
              <h4 className="event_name" style={{ whiteSpace: "pre-line" }}>
                {eventInfo.eventTitle}
              </h4>
              <div className="info_layout">
                <div className="info">
                  <p className="text">必要PAW</p>
                  <Paw className="paw">{selectedDlcItem.price}</Paw>
                </div>
                <div className="info">
                  <p className="text">所持PAW</p>
                  <Paw className="paw">{emoBalance.paidValue}</Paw>
                </div>
              </div>
            </div>

            <div className="download_button">
              <p className="button" onClick={togglePawChargeModal}>
                PAWチャージへ
              </p>
            </div>

            <div
              className="cansel_button"
              onClick={() => {
                setDisplayCaution(false);
                initialize();
              }}
            >
              <p className="button">キャンセル</p>
            </div>
          </div>
        </CautionPopup>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Container>
  );
};
export default EventFanPage;

const fanPageStyles = () => {
  return {
    description: css`
      img {
        max-width: 100%;
      }
    `,
  };
};

interface DisplayInfoToDownloadProps {
  auth: boolean;
  // @ts-expect-error TS7008
  user;
  dlcItems: DlcItem[];
  emoBalance: PawBalance;
  cheering: CheeringItem[];
  contentsCategoryTextMap: TextByContentsCategory;
  // @ts-expect-error TS7006
  setSendPawValue: (dlcId, dlcInfo) => void;
}

const DisplayInfoToDownload: React.FC<DisplayInfoToDownloadProps> = (props) => {
  const dispatch = useDispatch();
  const classesDisplayInfoToDownload = displayInfoToDownloadDesign();
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  return (
    <React.Fragment>
      <div css={classesDisplayInfoToDownload.cautionText}>
        {props.auth ? (
          props.contentsCategoryTextMap?.caution
        ) : (
          <React.Fragment>
            <p>ダウンロードを行うには、ログインを行う必要がございます。</p>
            <p>
              アカウントをお持ちでない方は、下のボタンから新規登録を行い、ログインしてください。
            </p>
          </React.Fragment>
        )}
      </div>

      <div css={classesDisplayInfoToDownload.cardDesignLayout}>
        {props.auth ? (
          props.dlcItems && props.dlcItems.length !== 0 ? (
            props.dlcItems
              .filter(
                (el) =>
                  (el.openTime.seconds < getNowTimestamp() &&
                    isDisplayPeriod(el.display, el.openTime, el.closeTime)) ||
                  isAdmin
              )
              .sort((a, b) => Number(a.id) - Number(b.id))
              .map((dlc, index) => {
                return (
                  <Card
                    css={classesDisplayInfoToDownload.cardDesignRoot}
                    key={index}
                  >
                    <div css={classesDisplayInfoToDownload.cardDesignDetails}>
                      <CardContent
                        css={classesDisplayInfoToDownload.cardDesignContent}
                      >
                        <Typography component="h5" variant="h5">
                          {dlc.name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {dlc.price.toLocaleString()}PAW
                        </Typography>
                      </CardContent>
                      <div
                        css={classesDisplayInfoToDownload.cardDesignControls}
                      >
                        <IconButton
                          aria-label="download"
                          onClick={() => props.setSendPawValue(dlc._id, dlc)}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Card>
                );
              })
          ) : null
        ) : (
          <div
            className="downloadButton"
            onClick={() => dispatch(modalActions.toggleLoginMethod())}
          >
            <p className="buttonLabel">ログイン</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

interface DisplayInfoToDownloadDesign {}

const displayInfoToDownloadDesign = (_props?: DisplayInfoToDownloadDesign) => {
  return {
    cautionText: css`
      margin-bottom: 40px;
      p {
        padding-left: 1em;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 1.5em;
        text-indent: -1em;
        letter-spacing: 1px;
      }
    `,
    cardDesignLayout: css`
      display: flex;
      flex-wrap: wrap;
    `,
    cardDesignRoot: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 25px;
      @media screen and (min-width: 768px) {
        width: calc(50% - 50px);
        margin-right: 25px;
      }
    `,
    cardDesignDetails: css`
      display: flex;
      width: 100%;
    `,
    cardDesignContent: css`
      flex: 1 0 auto;
      width: calc(100% - 50px);
      h5 {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h6 {
        font-size: 16px;
      }
    `,
    cardDesignCover: css`
      width: 151px;
    `,
    cardDesignControls: css`
      display: flex;
      align-items: center;
      width: 50px;
    `,
    cardDesignPlayIcon: css`
      height: 38px;
      width: 38px;
    `,
  };
};
