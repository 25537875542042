import { isCloudFrontDomain, isLocalhost } from "./domain";

/*
 * 動的にauthDomainを変えないと、Safariで正常にリダイレクトできない
 * GCP承認済みのリダイレクトURIにもドメインの追加を行う必要がある
 * 動的に変える必要があるのはFirebase Hostingを使用している環境のみ
 * CloudFront + S3構成のdev環境は適用しないようにする
 */
const { host } = window.location;

export const authDomain = isCloudFrontDomain(host)
  ? "spwn.jp"
  : isLocalhost(host)
  ? process.env.REACT_APP_FB_AUTH_DOMAIN
  : host;

const appConfig = {
  GmoApi: {
    getCardToken: process.env.REACT_APP_GMO_GET_CARD_TOKEN,
  },
  PostcodeJP: {
    revealJpAddress: process.env.REACT_APP_POST_CODE_JP_API,
    apiKey: process.env.REACT_APP_POST_CODE_JP_API_KEY,
  },
  Youbora: {
    accountCode: process.env.REACT_APP_YOUBORA_ACCOUNT_CODE,
  },
  PaymentSystem: {
    chargeCard: process.env.REACT_APP_YAZ_CHARGE_CARD,
    chargeMember: process.env.REACT_APP_YAZ_CHARGE_MEMBER,
    confirmCharge: process.env.REACT_APP_YAZ_CONFIRM_CHARGE,
    paymentRegistration: process.env.REACT_APP_YAZ_PAYMENT_REGISTRATION,
    useValue: process.env.REACT_APP_YAZ_USE_VALUE,

    validatePurchase: process.env.REACT_APP_YAZ_VALIDATE_PURCHASE,
    purchaseCard: process.env.REACT_APP_YAZ_PURCHASE_CARD,
    purchaseMember: process.env.REACT_APP_YAZ_PURCHASE_MEMBER,
    purchaseCVS: process.env.REACT_APP_YAZ_PURCHASE_CVS,
    purchasePhone: process.env.REACT_APP_YAZ_PURCHASE_PHONE,
    purchaseFree: process.env.REACT_APP_YAZ_PURCHASE_FREE,
    giveTicket: process.env.REACT_APP_YAZ_GIVE_TICKET,

    registerUserPersonalData:
      process.env.REACT_APP_YAZ_REGISTER_USER_PERSONAL_DATA,
    addToCart: process.env.REACT_APP_YAZ_ADD_TO_CART,
    deleteFromCart: process.env.REACT_APP_YAZ_DELETE_FROM_CART,
    errorReporting: process.env.REACT_APP_YAZ_ERROR_REPORTING,

    deleteAccount: process.env.REACT_APP_YAZ_DELETE_ACCOUNT,
  },
  CloudFunctions: {
    sendQuery: process.env.REACT_APP_BLS_SEND_QUERY,
    checkPermission: process.env.REACT_APP_BLS_CHECK_PERMISSION,
    confirmTran: process.env.REACT_APP_BLS_CONFIRM_TRAN,
    getMyTickets: process.env.REACT_APP_BLS_GET_MY_TICKETS,
    getUnprocessedMyTickets:
      process.env.REACT_APP_BLS_GET_UNPROCESSED_MY_TICKETS,
    getSellingTickets: process.env.REACT_APP_BLS_GET_SELLING_TICKETS,
    getSellingGoods: process.env.REACT_APP_BLS_GET_SELLING_GOODS,
    getMyPurchaseHistory: process.env.REACT_APP_BLS_GET_MY_PURCHASE_HISTORY,
    checkServerState: process.env.REACT_APP_BLS_CHECK_SERVER_STATE,
    collectTicket: process.env.REACT_APP_BLS_COLLECT_A_TICKET,
    redeemTicket: process.env.REACT_APP_BLS_REDEEM_A_TICKET,
    generateTicketSharingKey:
      process.env.REACT_APP_BLS_GENERATE_TICKET_SHARING_KEY,
    receiveSharedTicket: process.env.REACT_APP_BLS_RECEIVE_SHARED_TICKET,
    applyTicketLottery: process.env.REACT_APP_BLS_APPLY_TICKET_LOTTERY,
    cancelTicketLottery: process.env.REACT_APP_BLS_CANCEL_TICKET_LOTTERY,
    assignAVoucher: process.env.REACT_APP_BLS_ASSIGN_A_VOUCHER,
    getStreamingKey: process.env.REACT_APP_BLS_GET_STREAMING_KEY,
    getTestPageStreamingKey:
      process.env.REACT_APP_BLS_GET_TEST_PAGE_STREAMING_KEY,
    createMySmallIconUrl: process.env.REACT_APP_BLS_CREATE_MY_SMALL_ICON_URL,
    checkGeo: process.env.REACT_APP_BLS_CHECK_GEO,
    getSignedURL: process.env.REACT_APP_BLS_GET_SIGNED_URL,
    issueATicket: process.env.REACT_APP_BLS_ISSUE_A_TICKET,
    storeAddressInfo: process.env.REACT_APP_BLS_STORE_ADDRESS_INFO,
    loadAddressInfo: process.env.REACT_APP_BLS_LOAD_ADDRESS_INFO,
    getSendingFee: process.env.REACT_APP_BLS_GET_SENDING_FEE,
    sendEmailVerificationUrlViaEmail:
      process.env.REACT_APP_BLS_SEND_EMAIL_VERIFICATION_URL_VIA_EMAIL,
    storePersonalData: process.env.REACT_APP_BLS_STORE_PERSONAL_DATA,
    revealVoucherCodeInfo: process.env.REACT_APP_BLS_REVEAL_VOUCHER_CODE_INFO,
    redeemVoucherCode: process.env.REACT_APP_BLS_REDEEM_VOUCHER_CODE,
    startDSignIn: process.env.REACT_APP_BLS_START_D_SIGN_IN,
    startDAccountLink: process.env.REACT_APP_BLS_START_D_ACCOUNT_LINK,
    storeSurveyData: process.env.REACT_APP_BLS_STORE_SURVEY_DATA,
    fetchEventTicketSurveyResult:
      process.env.REACT_APP_BLS_FETCH_EVENT_TICKET_SURVEY_RESULT,
    assignVoucherTOFES: process.env.REACT_APP_BLS_ASSIGN_VOUCHER_TOFES,
    purchaseCapsule: process.env.REACT_APP_BLS_PURCHASE_CAPSULE,
  },
  FlowerStand: {
    customImgFileSize: Number(process.env.REACT_APP_FSTND_CUSTOM_IMG_FILE_SIZE),
  },
  Cloudflare: {
    vodWorkerUrl: process.env.REACT_APP_VOD_CLOUDFLARE_WORKER,
  },
  crewlDomain: process.env.REACT_APP_CREW_DOMAIN,
  authDomain,
  storageDomain: process.env.REACT_APP_APP_STORAGE_DOMAIN,
  publicStorageDomain: process.env.REACT_APP_APP_STORAGE_URL_PUBLIC,
  bucketPath: process.env.REACT_APP_GCP_BUCKET_PATH,

  isProd: process.env.REACT_APP_PRODUCTION,
  debug: process.env.REACT_APP_DEBUG_LOCAL,
  logDebug: process.env.REACT_APP_LOG_DEBUG,

  eventPageDomain: process.env.REACT_APP_EVENT_PAGE_DOMAIN,
  eventPageFileName: process.env.REACT_APP_EVENT_PAGE_FILE_NAME,
  eventJsonName: process.env.REACT_APP_EVENT_JSON_NAME,
  arApp: {
    dynamicLink: {
      domain: process.env.REACT_APP_AR_DYNAMIC_LINK_DOMAIN,
      deepLinkDomain: process.env.REACT_APP_AR_DYNAMIC_LINK_DEEP_LINK_DOMAIN,
      apn: process.env.REACT_APP_AR_DYNAMIC_LINK_APN,
      ibi: process.env.REACT_APP_AR_DYNAMIC_LINK_IBI,
      isi: process.env.REACT_APP_AR_DYNAMIC_LINK_ISI,
    },
  },
  viewerApp: {
    dynamicLink: {
      domain: process.env.REACT_APP_VIEWER_DYNAMIC_LINK_DOMAIN,
      deepLinkDomain:
        process.env.REACT_APP_VIEWER_DYNAMIC_LINK_DEEP_LINK_DOMAIN,
      apn: process.env.REACT_APP_VIEWER_DYNAMIC_LINK_APN,
      ibi: process.env.REACT_APP_VIEWER_DYNAMIC_LINK_IBI,
      isi: process.env.REACT_APP_VIEWER_DYNAMIC_LINK_ISI,
    },
  },
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  indexRoot: process.env.REACT_APP_INDEX_ROOT || "root", // render target id of index.html
  accountsApp: {
    // アカウントアプリケーションを開発環境で有効にする
    developmentEnabled:
      process.env.REACT_APP_ACCOUNTS_APPLICATION_DEVELOPMENT_ENABLED,
    appUrl: process.env.REACT_APP_ACCOUNTS_APP_URL,
    defaultReturnCrewUrl:
      process.env.REACT_APP_ACCOUNTS_APP_CREW_DEFAULT_RETURN_URL ??
      "https://crew.spwn.jp/login",
    defaultReturnCMSUrl:
      process.env.REACT_APP_ACCOUNTS_APP_CMS_DEFAULT_RETURN_URL,
    // 利用規約のURL
    tosUrl: "https://spwn.jp/terms-of-service",
  },

  backendApiUrl: process.env.REACT_APP_BACKEND_API_URL,

  buildUnixTime: process.env.REACT_APP_BUILD_UNIX_TIME,
};

export default appConfig;
