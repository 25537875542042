import React from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ErrorIcon from "@material-ui/icons/Error";

import { useChargeHistory } from "../../hooks/paw/useChargeHistory";

// TODO: PAWの移行完了後に削除
export const PawBanner = () => {
  const { pawHistoryContainer } = useChargeHistory();
  const isRefundTarget = pawHistoryContainer
    .filter(
      (p) => p.expiresAt >= new Date("2025-03-31T12:00:00+09:00").getTime()
    )
    .some((p) => p.value > 0);
  if (!isRefundTarget) return null;

  return (
    <Box
      color="#FF2600"
      bgcolor="#FFDDDD"
      padding="8px 20px"
      display="flex"
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      gridGap={8}
    >
      <Box display="flex" gridGap={8}>
        <ErrorIcon />
        <span>
          <strong>
            2025年3月31日以前にPAWを購入し、2025年3月31日時点で未使用のPAWがあるユーザーは全額返金の対象です。
          </strong>
        </span>
      </Box>
      <Link
        color="inherit"
        underline="always"
        href="https://spwn.zendesk.com/hc/ja/articles/44463762516377--%E9%87%8D%E8%A6%81-PAW%E6%89%95%E6%88%BB%E3%81%97-%E8%B3%87%E9%87%91%E6%B1%BA%E6%B8%88%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E6%B3%95%E5%BE%8B%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8FPAW%E6%89%95%E6%88%BB%E3%81%97%E5%AE%9F%E6%96%BD%E3%81%AE%E3%81%94%E6%A1%88%E5%86%85"
        target="_blank"
        rel="noopener"
        style={{ alignContent: "center" }}
      >
        払戻し方法をFAQで確認
      </Link>
    </Box>
  );
};
