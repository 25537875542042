/** @jsxRuntime classic /
/** @jsx jsx */
import icon_play from "../../designs/images/icon_video_play.svg";
import { loadCurrentTimeFromSessionStorage } from "../../utility/streaming";
import { create, isPlayerSupported } from "amazon-ivs-player";
import type { THEOSetting } from "@spwn/types/firebase/firestore";
import { StreamingType } from "modules/streaming";
import appConfig from "constants/appConfig";
import { fetchFbTokenWithoutTokenRefresh, fetchUserId } from "utility/firebase";
import { checkIfUseCloudFlare } from "tmp/canaryRelease/cloudflareVOD";
import { getEventIdFromHref } from "utility";

export const AddDoNotScrollStyle = () => {
  document.documentElement.style.overflow = "hidden";
  document.body.style.overflow = "hidden";
  document.documentElement.style.touchAction = "none";
  document.body.style.touchAction = "none";
};

export const RemoveDoNotScrollStyle = () => {
  document.documentElement.removeAttribute("style");
  document.body.removeAttribute("style");
};

export const createAWSPlayer = () => {
  if (isPlayerSupported) {
    try {
      const params = {
        domain: window.location.hostname, // Cache Busting for multi-domain CORS
      };
      const player = create({
        wasmWorker:
          "https://public.spwn.jp/spwn-steaming/amazon-ivs-player-1.2.0/dist/assets/amazon-ivs-wasmworker.min.js?" +
          new URLSearchParams(params),
        wasmBinary:
          "https://public.spwn.jp/spwn-steaming/amazon-ivs-player-1.2.0/dist/assets/amazon-ivs-wasmworker.min.wasm?" +
          new URLSearchParams(params),
      });
      player.setAutoplay(false); // play only if when LL-mode is selected
      player.setLiveLowLatencyEnabled(true);
      player.attachHTMLVideoElement(
        // @ts-expect-error TS2345
        document.querySelector(".aws-video-player")
      );
      return player;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    return null;
  }
};

/**
 * playerを作成する
 * @see https://docs.theoplayer.com/api-reference/web/theoplayer.playerconfiguration.md
 */
export const createPlayer = (
  poster: string,
  // player作成後に、sourceを更新するケースがある
  url: string | "" | null,
  setting: THEOSetting,
  streamingType: StreamingType,
  vid: string | null = null
) => {
  const win = window as unknown;
  // @ts-expect-error TS7008
  const { THEOplayer } = win as { THEOplayer };
  const element = document.querySelector(".theoplayer-container");
  const player = new THEOplayer.Player(element, {
    allowNativeFullscreen: setting.allowNativeFullscreen,
    // initialRendition: 'low',
    /**
     * https://portal.theoplayer.com/app/bdac9bf2-8a66-460c-944d-8235876c0e5c/players/808556fc-fb19-4fd7-8633-20184fda147e
     * 有効期限: November 30, 2025
     */
    license:
      "sZP7IYe6T6fr0SC_CohcCmzt3QaoFSxl3Dh-TSbt36k6IK0_CLfc0uez0SC6FOPlUY3zWokgbgjNIOf9fKPeCD0rCKCZFS113lC-3uCiCZzr3uxeFSRZ0Lh_3LIl0QhkCZfVfK4_bQgZCYxNWoryIQXzImf90Sbo3L5_TuRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3L0z3SetTuh_0u5zFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya",
    libraryLocation: "https://cdn.jsdelivr.net/npm/theoplayer@2.92.0/",
    // libraryLocation:
    //   "https://cdn.myth.theoplayer.com/808556fc-fb19-4fd7-8633-20184fda147e",
    mutedAutoplay: "all",
    ui: {
      aspectRatio: "16:9",
      playbackRates: setting.playBackRates,
    },
    allowMixedContent: true,
    pip: setting.enablePiP
      ? {
          visibility: 0.5,
          position: "bottom-right",
          retainPresentationModeOnSourceChange: true,
        }
      : {},
  });
  // player.autoplay = true;
  // player.src = 'https://wowza.peer5.com/live/smil:bbb_abr.smil/playlist.m3u8';
  player.abr.targetBuffer = setting.abr.targetBuffer;
  player.abr.strategy = setting.abr.strategy;
  player.muted = false; // default is unmuted
  player.poster = poster;
  player.preload = "auto";
  if (url !== null && url !== "") {
    player.source = {
      sources: [
        {
          src: url,
          type: "application/x-mpegurl",
          lowLatency: true,
          liveOffset: setting.initialLiveOffset,
          crossOrigin: "use-credentials",
        },
      ],
    };
    if (streamingType === "VOD" && !!vid) {
      const previousPlayHeadPosition = loadCurrentTimeFromSessionStorage(vid);
      if (previousPlayHeadPosition) {
        player.currentTime = previousPlayHeadPosition;
      }
    }
  }

  // THEOplayerの再生ボタンを変更する
  const playButtonContainer = document.getElementsByClassName(
    "theo-big-play-button-svg-container"
  );
  // @ts-expect-error TS2532
  playButtonContainer[0].innerHTML = "<img src=" + icon_play + ' alt=""/>';

  const httpErrorInterceptor = (response: {
    status: number;
    request: { type: string; url: string };
  }) => {
    if (response.status >= 400 && response.status <= 599) {
      const errorDetails = {
        status: response.status,
        fileType: response.request.type,
        url: response.request.url,
      };

      playerErrorHandler(errorDetails);
    }
  };

  let playerErrorHappened = false;

  const playerErrorHandler = (errorData: unknown) => {
    try {
      (async () => {
        if (checkIfUseCloudFlare(streamingType)) {
          playerErrorHappened = true;
          localStorage.setItem("playerErrorHappened_v1", "true");
        }

        const object = { expiredAt: new Date().getTime() + 1800 * 1000 };
        localStorage.setItem("useCloudfront", JSON.stringify(object));

        const fbToken = await fetchFbTokenWithoutTokenRefresh();
        const userId = fetchUserId();
        const url = `${appConfig.backendApiUrl}/portal/python-functions/playerErrorLogging`;

        const eventId = getEventIdFromHref(window.location.pathname, "events");
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const { userAgent, language } = window.navigator;

        await fetch(url, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify({
            error: errorData,
            eventId,
            userAgent,
            userId,
            language,
            timezone,
          }),
          credentials: "include",
        });

        // 現在のURLにクエリパラメータを追加してリフレッシュ
        // const currentUrl = new URL(window.location.href);
        // currentUrl.searchParams.set("useCf", "true");
        // window.location.href = currentUrl.toString();
      })();
    } catch (e) {
      console.error(e);
    }
  };

  const playerLogHandler = (logData: unknown) => {
    try {
      (async () => {
        console.log(logData);

        const playerErrorHappened_v1 = localStorage.getItem(
          "playerErrorHappened_v1"
        );

        if (!(playerErrorHappened_v1 === "true" || playerErrorHappened)) {
          return;
        }

        const fbToken = await fetchFbTokenWithoutTokenRefresh();
        const userId = fetchUserId();
        const url = `${appConfig.backendApiUrl}/portal/python-functions/playerLogging`;

        const eventId = getEventIdFromHref(window.location.pathname, "events");
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const { userAgent, language } = window.navigator;

        await fetch(url, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify({
            userLog: logData,
            eventId,
            userAgent,
            userId,
            language,
            timezone,
          }),
          credentials: "include",
        });
      })();
    } catch (e) {
      console.error(e);
    }
  };

  player.network.addResponseInterceptor(httpErrorInterceptor);

  player.addEventListener("error", playerErrorHandler);

  player.addEventListener(
    [
      "play",
      "playing",
      "durationchange",
      "stalled",
      "ended",
      "seeking",
      "seeked",
      "waiting",
      "pause",
      "volumechange",
    ],
    playerLogHandler
  );

  return player;
};
