/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContentsLayout } from "ui/SubContentsLayout";

interface Props {}
// eslint-disable-next-line unused-imports/no-unused-vars
export const PawTerms: React.FC<Props> = (props) => {
  const classesPawTermsStyles = pawTermsStyles();

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "PAW約款"],
        ]}
      />

      <div css={classesPawTermsStyles.root}>
        <h2>PAW約款</h2>
        <p css={classesPawTermsStyles.introduction}>
          PAW約款(以下「本約款」といいます。)は、プリカ基本約款及びPAW利用特約で構成されます。利用者(プリカ基本約款第2条第5号において定義します。)は、本約款をよくお読みいただき、内容に同意の上、「PAW」をご利用ください。利用者が、PAWをご利用いただいた場合には、本約款に同意いただいたものとします。
        </p>

        <article css={classesPawTermsStyles.section}>
          <h3>プリカ基本約款</h3>
          <p className="indent_none">
            プリカ基本約款(以下「本基本約款」といいます。)は、GMOペイメントゲートウェイ株式会社(以下「PG」といいます。)が発行する、PGのウェブサイトに掲載の「PAW」(以下、本基本約款においては、「プリカ」といい、第2条第1号で定義します。)に適用される共通の約款です。プリカをご利用の際には、本基本約款のほかPAW利用特約(以下「特約」といいます。)が適用され、本基本約款と特約が異なる部分については、特約が優先されます。プリカの仕様の詳細は、特約をご確認ください。
            <br />
            また、プリカについては、本基本約款と特約のほか、これらに付随してPGが定め、PGのウェブサイト等に掲載する利用方法その他の細則(以下、本基本約款、特約と併せて「本約款等」といいます。)が適用されますので、ご利用の際にはこれらも併せてご確認ください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１条（目的）</h3>
          <p className="indent_none">
            本約款等は、PGが発行、管理し、プリカ取扱サイト(第2条第7号において定義します。)で代金の支払いに利用することができるプリカによるお取引について規定するもので、PGは、プリカを利用したお取引について、本約款等に従い取扱うものとし、利用者(第2条第5号において定義します。)は、本約款等に従いお取引をしていただきます。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２条（定義）</h3>
          <p className="indent_none">
            本約款において使用する用語の定義は、特約または細則で特に定める場合を除き、次のとおりとします。
          </p>
          <ul>
            <li>
              (1)
              プリカ(PAW)&emsp;PGが、電磁的方法により記録される金額に応じた対価を利用者(第5号において定義します。)から得て発行する前払式支払手段(資金決済に関する法律第3条第1項第1号)であって、利用者が、本約款等に基づき、1PAW=1円として、加盟店(第6号において定義します。)との間の商品の購入等、役務の提供その他の取引における代金の支払いに利用することができるもの
            </li>
            <li>
              (2)
              プリカ番号&emsp;プリカを識別し、その残高のデータを管理するために、プリカ毎に付与される番号、記号
            </li>
            <li>
              (3)
              プリカサービス&emsp;利用者と加盟店との間の商品の購入、役務の提供その他の取引において、PGが提供するプリカを利用した決済手段であって、利用者が、本約款等に従い対価の全部または一部の支払いにプリカを利用し、PGが本約款等に基づき、加盟店に対して利用されたプリカ相当額を支払うサービス
            </li>
            <li>
              (4)
              プリカマーク&emsp;プリカ取扱サイト(第7号において定義します。)、プリカサービスに係るものに使用される商標としてPGが指定するもの
            </li>
            <li>
              (5) 利用者&emsp;本約款等に基づき、プリカを保有し、これを利用する者
            </li>
            <li>
              (6)
              加盟店&emsp;PGに対し、プリカ取扱サイト(第7号において定義します。)における取引の代金の決済にプリカを取扱う旨の申込みをなし、PGがこれを承認した者で、PGとの間でプリカの取扱いに関する加盟店契約を締結した者
            </li>
            <li>
              (7)
              プリカ取扱サイト&emsp;加盟店が指定し、PGが承認したプリカ取引(第8号において定義します。)を行うウェブサイト及びアプリ等
            </li>
            <li>
              (8)
              プリカ取引&emsp;利用者が加盟店との間の商品の購入、役務の提供その他の取引において、本約款等に従って、金銭等に換えてプリカにより代金を支払う取引。その支払いについては、第7条に定めるとおりとします
            </li>
            <li>
              (9)
              プリカ取引金額&emsp;1回のプリカ取引によって加盟店が利用者から商品等の代金として受領したプリカを現金に換算した金額(なお、1PAW=1円とする)
            </li>
            <li>
              (10) 発行&emsp;プリカに金額を加算し、利用可能な状態にすること
            </li>
            <li>
              (11)
              プリカシステム&emsp;プリカの発行、利用、残高照会、利用履歴等のプリカの電子情報を処理することができるシステムであって、PGが運営管理するものを総称していう
            </li>
            <li>
              (12)
              偽造&emsp;PGの承認を受けずに複製等により、プリカと同様の又は類似の機能を有しプリカと誤認されうる電子的情報を作出すること
            </li>
            <li>
              (13)
              変造&emsp;PGの承認を受けずにプリカに変更を加え、元のプリカと内容が異なり、かつプリカと同様又は類似の機能を有しプリカと誤認されうる電子的情報を作出すること
            </li>
            <li>
              (14)
              利用者情報&emsp;利用者又はプリカの利用を申込もうとする者が、プリカの発行申込み及び利用に関し、PGに届け出た氏名、生年月日、住所、電話番号等の情報及びPGの利用履歴等の情報
            </li>
          </ul>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第３条（プリカ利用環境のご準備等）</h3>
          <p>
            1
            利用者は、プリカを利用するにあたり、プリカを利用するのに必要な電子機器、インターネット接続環境、電子メールアドレス等を利用者ご自身の費用と負担により準備するものとします。
          </p>
          <p>
            2
            プリカの発行を受け又は利用する場合には、プリカ取扱サイトにログインをする必要があります。プリカ取扱サイトによっては、当該サイトを運営する加盟店が利用者に付与するID及びパスワードが必要になる場合がありますので、あらかじめ、プリカ取扱サイトを運営する加盟店の定めに従って、ID及びパスワードをご準備ください。
          </p>
          <p>
            3
            利用者は、善良なる管理者の注意をもって前項のID及びパスワードを管理してください。利用者は、ID及びパスワードを、ご自身の責任により管理し、他人に知られることのないように十分ご注意ください。利用者がID又はパスワードを失念した場合には、当該ID又はパスワードを付与した加盟店所定の手続に従ってください。
          </p>
          <p>
            4
            利用者のID及びパスワードが使用されてプリカ取扱サイトにログインされ、プリカの発行又は利用がなされた場合には、第三者による使用であった場合であっても、PGは、当該ID及びパスワードの付与を受けた利用者によるものとして取扱い、利用者に生じた損害について、責任を負いません。また、第三者によるプリカの発行又は利用がなされたことを理由としてプリカの利用可能残高の返還・払戻しはいたしません
            。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第４条（プリカの発行）</h3>
          <p>
            1
            プリカサービスの利用を希望される方又は利用者は、プリカ取扱サイトにログインし、当該サイト上のプリカ発行用のページにおいて、プリカの発行に必要な情報(支払方法、発行希望金額等PGがプリカ発行用ページで指定する事項)を入力することにより、プリカの発行の申込みを行うものとし、第2項各号に定める方法により発行を希望する金額を支払った場合に、プリカの発行を受けることができます。
          </p>
          <p>
            2
            前項の申込みを行った利用者は、以下のいずれかの方法により、発行を希望する金額を支払うものとします。なお、PGは以下の方法のうちPGが提供可能と判断した方法を提供するものとし、以下のすべての方法を利用者に提供することを保証するものではありません。また、提供中の方法につき、PGはやむを得ない理由により提供を停止する場合があり、当該停止によって利用者が被った損害について、PGは責任を負いません。
          </p>
          <ul>
            <li>(1) クレジットカードによる支払い</li>
            <p className="indent_none">
              前項の発行の申込みの際に、プリカ発行用のページにおいて、PGが指定する事項を入力することによりクレジットカードを登録し、当該クレジットカードを使用して支払う方法(なお、利用者において既に登録したクレジットカードがあり、これを利用する場合には、再度、クレジットカードを登録する必要はありません。)
            </p>
          </ul>
          <ul>
            <li>(2) コンビニエンスストアの店頭での支払い</li>
            <p className="indent_none">
              前項の発行の申込みの際に、本号の支払方法を選択し、プリカ発行用のページに表示される支払期限までに、同ページに表示される払込専用番号をコンビニエンスストアで申告し、店頭で支払う方法
            </p>
          </ul>
          <ul>
            <li>(3) Pay-easyによる支払い</li>
            <p className="indent_none">
              前項の発行の申込みの際に、本号の支払方法を選択し、プリカ発行用のページに表示される支払期限までに、同ページに表示される収納機関番号、お客様番号、確認番号を所定の銀行ATM又はオンラインバンキングに入力し、Pay-easyの機能を利用して支払う方法
            </p>
          </ul>
          <p>
            3
            前項の支払いが完了した場合には、PGは、以下の各号に掲げる方法により、利用者に対し、発行金額等を通知することにより、プリカを発行します。
          </p>
          <ul>
            <li>(1) クレジットカードによる支払い</li>
            <p className="indent_none">
              前項第1号により、登録されたクレジットカードにより有効に決済が完了した時点で、所定のページ上に発行金額等を表示する方法
            </p>
          </ul>
          <ul>
            <li>(2) コンビニエンスストアの店頭での支払い</li>
            <p className="indent_none">
              前項第2号により、店頭での支払いが完了した時点で、当該店頭において発行金額等を表示したレシートを交付する方法
            </p>
          </ul>
          <ul>
            <li>(3) Pay-easyによる支払い</li>
            <p className="indent_none">
              前項第3号により、Pay-easyによる支払いが完了した時点で、当該ATMにおいて発行金額等を表示したレシートを交付する方法又はオンラインバンキングの画面上に発行金額等を表示する方法
            </p>
          </ul>
          <p>
            4
            プリカの発行金額の単位、上限等の条件は、プリカ取扱サイト他、別途PG所定の方法で示すとおりとします。
          </p>
          <p>
            5
            第3項各号に掲げる方法により通知を受けた場合には、利用者は、当該発行金額等の通知の内容をご確認いただくものとし、通知された時に利用者から特段の申し出がない限り、利用者は、当該発行金額等に誤りがないことをご確認いただいたものとします。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第５条（プリカの発行を受けられない場合）</h3>
          <p>
            1
            利用者は、次の各号に掲げるときは、プリカの発行を受けることができません。
          </p>
          <ul>
            <li>
              (1)
              プリカシステムのメンテナンス等プリカシステムの稼働時間外であるとき
            </li>
            <li>
              (2) 停電、システム障害、端末の故障その他やむをえない事由があるとき
            </li>
            <li>
              (3) 利用者が、本約款等に違反し、又は違反するおそれがあるとき
            </li>
            <li>
              (4)
              プリカ取扱サイトにおいて、当該サイトを運営する加盟店の定める規約等により、プリカの発行を受けることができないとき
            </li>
            <li>(5) その他PGが不適切と判断したとき</li>
          </ul>
          <p>
            2 前項に基づき利用者が
            プリカの発行を受けることができないことにより利用者に損害等が生じた場合であっても、PGは、その責任を負いませんので、ご了承ください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第６条（利用可能残高の確認等）</h3>
          <p>
            1
            プリカの利用可能残高は、プリカ取扱サイトにログインし、所定のページにおいて、所定の情報を入力することにより、ご確認いただくことができます。
          </p>
          <p>
            2
            利用者がプリカ番号を複数お持ちの場合、その利用可能残高は、プリカ番号毎に個別に算定され、各プリカの利用可能残高を1つのプリカ番号に統合することはできません。
          </p>
          <p>
            3
            プリカのご利用履歴は、プリカ取扱サイトにログインし、所定のページにおいて、所定の情報を入力することによりご確認いただくことができます。所定のページ上において表示されるプリカのご利用履歴の範囲等については、PGが定めるところによりますので、ご了承ください。
          </p>
          <p>
            4
            第1項にかかわらず、第10条に定める有効期間を過ぎたプリカの利用可能残高を確認することはできません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第７条（プリカの利用）</h3>
          <p>
            1
            利用者は、プリカ取扱サイトにログインし、所定の情報を入力することにより、プリカを利用することができます。
          </p>
          <p>
            2
            プリカシステムにおいて、利用者が選択した取引の代金額が、プリカの利用可能残高の範囲内であることが確認された場合には、PGは、加盟店に対してその旨を通知し、加盟店がこれを承認した場合には、プリカの利用可能残高の範囲内で、プリカの残高から取引代金相当額を差し引くことにより、当該代金額のお支払いがあったものとみなされます。
          </p>
          <p>
            3
            前項の場合、代金額及びお支払い後のプリカの利用可能残高がプリカ取扱サイト上に表示されますので、利用者はそれらに誤りがないことをご確認ください。
          </p>
          <p>
            4
            利用者が前項のプリカの利用可能残高に誤りがあることを申立てた場合、又はPGないし加盟店がプリカの利用可能残高の誤りを発見した場合には、PGは当該プリカ取引の代金額を確認の上、当該利用者のプリカの利用可能残高を訂正します。
          </p>
          <p>
            5
            利用者のプリカの利用可能残高が代金額に満たない場合には、取引の支払いは完了しません。加盟店が別途認める方法により、残額をお支払いください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第８条（プリカの利用ができない場合）</h3>
          <p>
            1
            利用者は、次の各号に掲げるときは、プリカを利用することができません。
          </p>
          <ul>
            <li>
              (1)
              プリカ取扱サイトにおいて入力されたプリカ番号に係るプリカの電子的情報が偽造若しくは変造され、又は当該電子的情報が不正に作り出されたものであるとき
            </li>
            <li>
              (2)
              プリカ番号が違法に取得されたものであるとき、違法に取得されたことを知りながら、若しくは知ることができる状態で取得したとき、又はプリカが違法に保有されるに至ったものであるとき
            </li>
            <li>
              (3) 利用者が、本約款等に違反し、又は違反するおそれがあるとき
            </li>
            <li>
              (4)
              利用者のプリカ利用状況等に照らし、プリカの利用者として不相当とPGが判断したとき
            </li>
            <li>
              (5)
              プリカシステムの障害、停電、天災地変その他やむを得ない事由があるとき
            </li>
            <li>
              (6)
              システムメンテナンス、システム管理会社の休業日又は休業時間、その他システム上の理由により一時的にプリカの利用を停止するとき
            </li>
            <li>
              (7)
              プリカ取扱サイトにおいて、加盟店の定める規約等により、プリカを利用して行おうとした取引を行うことができないとき、又は、同規約等により、プリカを利用することができないとき
            </li>
            <li>(8) その他PGが不適切であると判断したとき</li>
          </ul>
          <p>
            2
            前項に基づき利用者がプリカを利用できないことにより利用者に損害等が生じた場合であっても、PGは、その責任を負いませんので、ご了承ください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第９条（利用者の遵守事項）</h3>
          <p>
            1 利用者は、プリカの利用に関し、次の行為をすることができません。
          </p>
          <ul>
            <li>
              (1) 違法、不正又は公序良俗に反する目的でプリカを利用すること
            </li>
            <li>(2) 営利の目的でプリカを利用すること</li>
            <li>
              (3)
              プリカに係るソフトウエア、ハードウエア、その他プリカシステム、プリカについて、これを破壊、分解、解析若しくは複製等を行い又はかかる行為に協力すること
            </li>
            <li>
              (4)
              プリカの電子的情報が偽造若しくは変造され、又はプリカの電子的情報が不正に作り出されたものであるとき、またはその疑いがあるときに、これを利用すること
            </li>
            <li>
              (5) 上記に該当するおそれのある行為その他PGが不適切と判断する行為
            </li>
          </ul>
          <p>
            2
            利用者が、前項各号の規定に反してプリカ取引を行ったときは、PGは当該利用者の保有するプリカの利用を停止することとします。この場合、当該プリカ番号に紐づいて記録されたプリカの利用可能残高は返還・払戻しいたしません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１０条（プリカの有効期間）</h3>
          <p className="indent_none">
            プリカの有効期間は、プリカの発行日から 1
            年間とします。有効期間を経過したプリカは失
            効し、利用ができなくなりますのでご留意ください。この場合、プリカの利用可能残高にかか
            わらず返金・払戻しはしないものとします。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１１条（加盟店との関係）</h3>
          <p>
            1
            利用者がプリカ取扱サイト上においてプリカを利用された際に、万一、商品の購入、役務の提供その他の取引について、返品、瑕疵その他の問題が生じた場合には、加盟店との間で解決していただくものとし、PGは、その責任を負いません。
          </p>
          <p>
            2
            前項の場合において、加盟店が返品に応じた場合であって、PGが特段の異議を述べない場合には、PGは、プリカ取扱サイト上の所定のページにおいて、プリカ利用代金相当額を利用者のプリカ番号に加算することにより返還します。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１２条（譲渡等の禁止）</h3>
          <p className="indent_none">
            利用者は、プリカ及びプリカ番号について、他人に貸与し、譲渡し、又は質入れ等の担保に
            供することはできません。ただし、PG 所定の方法によりギフト用として PG
            からプリカ及び
            プリカ番号の交付を受けた利用者は、当該プリカ及びプリカ番号を譲渡することができます。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１３条（払戻しの原則禁止）</h3>
          <p>
            1
            本条第2項に定める場合を除き、プリカの利用可能残高の払戻しはいたしませんので、ご了承ください。
          </p>
          <p>
            2
            利用者は、次のいずれかに該当する場合、本条第3項及び第4項の規定に従い、プリカの利用可能残高の払戻しを受けることができます。
          </p>
          <ul>
            <li>
              (1) 第8条第1項第7号に該当する場合においてPGが相当と認めたとき。
            </li>
            <li>
              (2)
              第15条第2項又は第17条第3項により法令に基づきプリカの利用可能残高を払い戻すべきとき。
            </li>
            <li>(3) その他PGがやむを得ないと認める相当の事由があるとき。</li>
          </ul>
          <p>
            3
            前項の場合、利用者は、プリカ番号、利用者の氏名、住所、払戻金額の受領方法その他PGが指定する事項をPG所定の方法で申請することにより、PGに対し、払戻しの申し出を行うものとします。PGは、当該プリカの未使用残高につき払戻しが相当であると認めた場合、当該払戻し相当と認めた金額について払戻しします。なお、この場合、PGは、PGが定める手数料を控除することができるものとします(資金決済に関する法律第20条第1項に基づき払戻しを行う場合は除きます。)。
          </p>
          <p>
            4
            プリカ番号が判明しない場合又はプリカの利用可能残高が判明しない場合には、PGは、払戻しの義務を負いません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１４条（プリカサービスの停止、終了等）</h3>
          <p>
            1
            PGは、次のいずれかに該当したときは、利用者に対して事前に通知又は催告することなく、プリカサービスの全部又は一部を一時停止又は終了することができます。
          </p>
          <ul>
            <li>(1) 利用者が本約款等に違反したとき。</li>
            <li>
              (2)
              利用者のプリカ利用状況等に照らして、プリカの利用者として不相当とPGが判断したとき。
            </li>
          </ul>
          <p>
            2
            前項の場合、利用者は、プリカを利用することができません。また、PGは、所定の方法により、プリカ番号をPGシステム上から削除する場合があります。この場合、プリカシステム上に記録されたプリカの利用可能残高は返還・払戻しいたしませんので、ご了承ください。
          </p>
          <p>
            3
            利用者が死亡した場合には、利用者の資格は喪失され、一切のプリカサービスを利用できなくなります。この場合、プリカ番号は削除されるものとし、また、プリカの利用可能残高の返還・払戻しも行われません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１５条（PGによるプリカサービスの終了）</h3>
          <p>
            1
            PGは、天災地変、社会情勢の変化、法令の改廃、その他技術上又は営業上の判断等により、プリカサービスを終了させることがあります。
          </p>
          <p>
            2
            前項の場合、PGは、プリカ取扱サイトへの掲載その他PG所定の方法により、プリカサービスを終了させる旨及びプリカの払戻方法について周知の措置をとります。この場合のプリカの払戻手続については、法令の定めに従うほか、第13条第3項及び第4項の規定を準用します。なお、PGが定めた払戻申出期間経過後は、払戻しを行わないことといたしますので、ご了承ください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１６条（PGの責任）</h3>
          <p className="indent_none">
            プリカを利用することができなかったことにより利用者に生じた損害等について、PGに故意又は重過失がない限り、PGはその責任を負いません。なお、PGに故意又は重過失がある場合であっても、PGは、逸失利益について損害賠償の責任を負いません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１７条（取扱いの変更）</h3>
          <p>
            1
            本約款を変更する場合、PGは、あらかじめ利用者に対してプリカ取扱サイトへの掲載その他PG所定の方法により、一定の予告期間をおいて変更内容について周知の措置をとります。
          </p>
          <p>
            2 本約款の変更は、次のいずれか早い時点で効力を生じるものとします。
          </p>
          <ul>
            <li>(1) 前項の予告期間を経過したとき</li>
            <li>
              (2)
              前項のお知らせ後、利用者がプリカの発行を受け又は利用を行ったとき
            </li>
          </ul>
          <p>
            3
            前項の規定にかかわらず、本約款の変更が利用者に不利益なものであると認められる相当の事由があり、第1項の予告期間内に利用者から異議のお申し出があった場合には、PGは、プリカを払戻します。この場合、第13条第3項及び第4項の規定を準用します。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１８条（個人情報の収集・利用）</h3>
          <p className="indent_none">
            PGは、利用者の個人情報について、PGが別途定める「プリカサービスのプライバシーポリシー」に記載した使用目的のために、必要な保護措置を行った上で収集・利用します。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第１９条（反社会的勢力の排除）</h3>
          <p>
            1
            利用者ないしプリカサービスを利用しようとする者は、それらの者が以下の各号のいずれにも該当しないこと、かつ将来にわたっても該当しないことを確約します。
          </p>
          <ul>
            <li>
              (1)
              暴力団、暴力団構成員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団その他これらに準ずる集団又は個人(以下「反社会的勢力」という)であること、又は反社会的勢力であったこと
            </li>
            <li>
              (2)
              役員又は実質的に経営を支配する者が反社会的勢力であること、又は反社会的勢力であったこと
            </li>
            <li>
              (3)
              反社会的勢力に自己の名義を利用させ、本約款に基づく契約を締結すること
            </li>
          </ul>
          <p>
            2
            利用者ないしプリカサービスを利用しようとする者は、それらの者が以下の各号のいずれの行為もしないこと、かつ将来にわたってもしないことを確約します。
          </p>
          <ul>
            <li>(1) 脅迫的な言動をすること、若しくは暴力を用いること</li>
            <li>
              (2) 偽計又は威力を用いて業務を妨害し、又は名誉・信用を棄損すること
            </li>
            <li>(3) 法的責任を超えた不当な要求をすること</li>
            <li>
              (4)
              自ら又はその役員若しくは実質的に経営を支配する者が反社会的勢力への資金提供を行う等、その活動を助長する行為を行うこと
            </li>
            <li>(5) 前各号に準ずる行為を行うこと</li>
            <li>
              (6) 第三者をして前各号のいずれかに該当する行為を行わせること
            </li>
          </ul>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２０条（業務委託）</h3>
          <p className="indent_none">
            PGは、本約款等に基づくプリカサービス運営管理業務について、業務の一部を第三者に委託することができるものとします。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２１条（届出事項等）</h3>
          <p>
            1
            利用者は、PGに対して氏名・住所・電子メールアドレス等、プリカサービスの利用の申込み時又はその後の利用時にPGに届け出た事項がある場合において、当該届出事項に変更が生じた場合は、PG所定の方法によりPGへ届け出るものとします。
          </p>
          <p>
            2
            利用者に対する通知は、PG所定のウェブサイトへの掲載、利用者が届け出た電子メールアドレスに電子メールの送付その他PGがその都度任意に選択する方法によって行います。
          </p>
          <p>
            3
            第1項の届出がないために、前項の通知等が延着し、又は到着しなかった場合には、通常到着すべき時に利用者に到着したものとみなします。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２２条（準拠法）</h3>
          <p className="indent_none">本規約の準拠法は日本法とします。</p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２３条（合意管轄裁判所）</h3>
          <p className="indent_none">
            利用者は、プリカサービスに関して利用者とPGとの間に紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とし、他の裁判所に申立てをしないことに合意します。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <h3>第２４条（ご相談窓口）</h3>
          <p className="indent_none">
            プリカサービス、又は本約款に関するご質問又はご相談は、プリカ取扱サイト上の所定のページをご参照ください。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <p>施行 2019年2月1日</p>
          <p>(改定:2019年11月5日 施行:2019年12月3日)</p>
          <p>(改定：2021年9月1日 施行：2021年10月1日)</p>
          <p>以上</p>
        </article>
      </div>

      <div css={classesPawTermsStyles.root}>
        <h2>PAW 利用特約</h2>

        <div css={classesPawTermsStyles.section}>
          <p className="indent_none">
            PAW利用特約(以下「本特約」といいます。)は、バルス株式会社(以下「バルス」といいます)が運営するウェブサイト(https://spwn.jp)及びアプリ等でご利用いただける「PAW」に関して規定するものです。「PAW」は、GMOペイメントゲートウェイ株式会社(以下「PG」といいます)が発行、管理する前払式支払手段(資金決済に関する法律第3条第1項第1号)です。
          </p>
          <p className="indent_none">
            利用者は、本特約をよくお読みいただき、内容に同意の上、「PAW」をご利用ください。「PAW」をご利用いただいた場合には、本特約に同意いただいたものとします。
          </p>
          <p className="indent_none">
            本特約で特に定めた事項を除き、用語の定義はプリカ基本約款の定めに従うものとし、PAWをご利用の際には、本特約のほか、プリカ基本約款が適用されます。なお、本特約とプリカ基本約款の規定が矛盾抵触するときは、本特約の定めが優先するものとします。
          </p>
        </div>

        <article css={classesPawTermsStyles.section}>
          <h3>特約事項</h3>
          <p>
            1.PAWの発行を受ける場合には、あらかじめ、バルスが運営するSPWNのウェブサイトにおいてSPWN会員として登録をし、SPWNにログインするためにバルスが発行するID(以下「バルスID」といいます。)を取得してください。バルスIDの登録(SPWN会員登録)が完了しておらず、同IDをお持ちでない方は、PAWの発行を受けることができません。
          </p>
          <p>
            2.プリカ基本約款第2条第(2)号及び第6条の定めにかかわらず、PAWにはプリカ番号は付与されません。利用者が、自らが保有するバルスIDを入力し、プリカ基本約款第4条第1項に基づく発行の申込みを行った場合には、当該申込みごとにPAWの券面(以下「PAW券面」をいいます。)が表示され、同条第2項に基づく支払いが完了した時点で同条第3項に基づく金額が表示され、PAWの発行が完了したものとします。
          </p>
          <p>
            3.プリカ基本約款第2条第(7)号に定める「プリカ取扱サイト」はバルスの「SPWN」のみとします。
          </p>
          <p>
            4.利用者は、複数のバルスIDをお持ちの場合でも、PAWは、表示される券面ごとに個別に発行、利用可能残高が管理され、複数の券面をお持ちの場合でも、各PAW券面の利用可能残高を1つのPAW券面に統合することはできません。
          </p>
          <p>
            5.利用者は、1回の取引で、複数のPAW券面を利用することはできません。
          </p>
          <p>
            6.バルスの定める規約等により、発行を受けることができない場合があります。詳細はバルスの定める規約等をご確認ください。
          </p>
          <p>
            7.バルスの定める規約等により、発行を受けたPAWを利用して行おうとした取引を行うことができない場合、又は、同規約等により、PAWを利用することができない場合があります。詳細はバルスの定める規約等をご確認ください。
          </p>
          <p>
            8.利用者は、500円から300,000円の範囲で、かつ1日あたり30回までを上限にPAWの発行を受けることができます(1円=1PAW)。
          </p>
          <p>
            9.PAWの有効期間は、PAWの発行日から1年間とします。有効期間を経過したPAWは失効し、利用ができなくなりますのでご留意ください。この場合、PAWの利用可能残高にかかわらず返金・払戻しはしないものとします。なお、複数のバルスIDをお持ちの利用者に係るPAWの有効期間は、バルスIDごとに算定されるものとします。
          </p>
          <p>
            10.SPWN会員登録をした利用者は、プリカ取扱いサイト上で定める方法で、退会することができます。PAWは、当該退会と同時に失効となります。退会に当たって、PAWの利用可能残高は、返金・払戻しされません。
          </p>
          <p>
            11.基本約款第13条第2項の定めは、利用者都合によるSPWNの退会、又はプリカサービスの終了の場合には、適用されません。
          </p>
        </article>

        <article css={classesPawTermsStyles.section}>
          <p>施行 2019年2月1日</p>
          <p>(改定:2019年11月5日 施行:2019年12月3日)</p>
          <p>(改定：2021年9月1日 施行：2021年10月1日)</p>
          <p>以上</p>
        </article>
      </div>
    </SubContentsLayout>
  );
};

const pawTermsStyles = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 80px 0;
      margin: 0 auto;
      h2 {
        margin-bottom: 80px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
      }
    `,
    introduction: css`
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 1px;
    `,
    section: css`
      margin-bottom: 40px;
      h3 {
        margin-bottom: 16px;
        line-height: 1.3em;
      }
      p {
        padding-left: 1em;
        margin-bottom: 8px;
        font-size: 14px;
        text-indent: -1em;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
      ul {
        padding-left: 1em;
        li {
          font-size: 14px;
          padding-left: 1.7em;
          margin-bottom: 8px;
          text-indent: -1.7em;
        }
      }
      .indent_none {
        padding-left: 0;
        text-indent: 0;
      }
    `,
  };
};
