import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import { useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";
import { pushDataLayerWithClientBuildUnixTime } from "utility/ga";

const styles: StyleRules = createStyles({
  buttonRoot: {
    width: "80vw",
    margin: "10px",
    color: "#fff",
    backgroundColor: "#000",
  },
});

interface Props {
  // classes: any;
  isSignup: boolean;
  // @ts-expect-error TS7006
  onClick: (provider) => void;
}
const GoogleLoginButton = (props: Props) => {
  const { isSignup } = props;
  const { t } = useI18n();
  const provider = new firebase.auth.GoogleAuthProvider();
  const actionText = isSignup
    ? t("login.buttons.registration")
    : t("login.buttons.login");

  const onClick = () => {
    props.onClick(provider);
    try {
      if (isSignup) {
        pushDataLayerWithClientBuildUnixTime({
          event: "sign_up",
          method: "Google",
        });
      } else {
        pushDataLayerWithClientBuildUnixTime({
          event: "login",
          method: "Google",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      text={`${t("login.loginMethod.google")}${actionText}`}
      color={"#d84437"}
      onClick={onClick}
    />
  );
};
export default withStyles(styles)(GoogleLoginButton);
