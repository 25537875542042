import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import { modalActions, NoticeData } from "../../modules/modal";
import { fetchFbToken, fetchFirestoreDocument } from "../../utility/firebase";
import appConfig from "../../constants/appConfig";
import { loadingActions } from "../../modules/loading";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import { createStyles } from "@material-ui/core/styles";

import { isSmartPhone } from "../../utility";
import { ConfirmationDialog } from "components/common/Modal";
import styled from "@emotion/styled";
import { PopupWindow, LinkButton, PopupWindowButtonList } from "../../styles";
import type { UserPersonalDataState } from "@spwn/types/firebase/firestore";

const styles: StyleRules = createStyles({
  modalScroll: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
});

const QuestionaryLayout = styled.div`
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
`;
QuestionaryLayout.displayName = "QuestionaryLayout";

type Props = ValueProps &
  FuncProps & {
    // @ts-expect-error TS7008
    classes;
  };
interface ValueProps {
  // @ts-expect-error TS7008
  user;
}
interface FuncProps {
  toggleNotice: (msgObj: NoticeData) => void;
  toggleLoading: (obj: { msg?: string }) => void;
}

interface States {
  isOpen: boolean;
  hasGenderCode: boolean;
  hasBirthYear: boolean;
  hasResidenceCode: boolean;
  genderCode: string;
  birthYear: string;
  residenceCode: string;
  errorInfo: {
    genderCode: string;
    birthYear: string;
    residenceCode: string;
  };
}

class Questionary extends Component<Props, States> {
  uncsubscribeRgisterState = null;
  timer = null;
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      // @ts-expect-error TS2322
      isOpen: null,
      // @ts-expect-error TS2322
      hasGenderCode: null,
      // @ts-expect-error TS2322
      hasBirthYear: null,
      // @ts-expect-error TS2322
      hasResidenceCode: null,
      // @ts-expect-error TS2322
      genderCode: null,
      // @ts-expect-error TS2322
      birthYear: null,
      // @ts-expect-error TS2322
      residenceCode: null,
      errorInfo: {
        genderCode: "",
        birthYear: "",
        residenceCode: "",
      },
    };
  }

  componentDidMount() {
    this.watchRegisterState();
  }

  componentWillUnmount() {
    if (this.uncsubscribeRgisterState !== null) {
      // @ts-expect-error TS2349
      this.uncsubscribeRgisterState();
    }
  }

  closeModal = () => {
    this.setState({ isOpen: false });
    // 登録キャンセルされたら一定時間ごとに出す
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.openModalEveryFixedTime();
  };

  watchRegisterState = async () => {
    const { uid } = this.props.user;
    if (!uid || !this.props.user.emailVerified) {
      return;
    }

    // if access is from spwn-ar-app and smart phone, don't popup
    // can't scroll popup in unity browser...
    const userArAppInfo = await fetchFirestoreDocument(
      `/ARApp/root/users/${uid}`
    );
    if (userArAppInfo && isSmartPhone()) {
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .doc(`/users/${uid}/configs/personalData`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const status = doc.data() as UserPersonalDataState;
          const isOpen = !status.isRegistered;
          this.setState({
            isOpen,
            // hasGenderCode: isOpen,
            // hasBirthYear: isOpen,
            // hasResidenceCode: isOpen,
          });
        } else {
          this.setState({
            isOpen: true,
          });
          console.log("NoStatus");
        }
      });
    // @ts-expect-error TS2322
    this.uncsubscribeRgisterState = unsubscribe;
  };

  registerQuestionary = async () => {
    if (!this.state.hasGenderCode && !this.state.genderCode) {
      this.setState({
        errorInfo: {
          ...this.state.errorInfo,
          genderCode: "入力をお願いします",
        },
      });
      return;
    }
    if (!this.state.hasBirthYear && !this.state.birthYear) {
      this.setState({
        errorInfo: {
          ...this.state.errorInfo,
          birthYear: "入力をお願いします",
        },
      });
      return;
    }
    if (!this.state.hasResidenceCode && !this.state.residenceCode) {
      this.setState({
        errorInfo: {
          ...this.state.errorInfo,
          residenceCode: "入力をお願いします",
        },
      });
      return;
    }
    // 更新対象以外はnullを渡し、サーバ側で更新対象のみ更新するようにする。
    const msg = {
      gender: this.state.genderCode,
      residence: this.state.residenceCode,
      birthYear: this.state.birthYear,
    };
    console.dir(msg);
    try {
      this.props.toggleLoading({ msg: "送信中..." });
      const fbToken = await fetchFbToken();
      // @ts-expect-error TS2769
      const response = await fetch(appConfig.CloudFunctions.storePersonalData, {
        // const response = await fetch(appConfig.PaymentSystem.registerUserPersonalData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify(msg),
      });
      if (response.status === 200) {
        console.log("success");
        // 新規登録ステップを減らすために、回答後のモーダルを非表示にする: https://www.notion.so/balusco/19e98eee078b8028b6d3c6c8216a1e6d
        // TODO: トースト表示に変更して復活させる
        // this.props.toggleNotice({ caption: "ご協力ありがとうございました。" });
        if (this.timer !== null) {
          clearInterval(this.timer);
        }
      } else {
        throw new Error("failed");
      }
    } catch (error) {
      this.props.toggleNotice({
        msg: "予期せぬエラーが発生しました<br/>時間を置いてもう一度やり直してください",
      });
    } finally {
      this.props.toggleLoading({});
    }
  };

  /**
   * 未登録の場合、1分毎にポップアップが出るようにする
   */
  openModalEveryFixedTime = () => {
    // @ts-expect-error TS2322
    this.timer = setInterval(() => {
      const isOpen =
        !this.state.genderCode ||
        !this.state.birthYear ||
        !this.state.residenceCode;
      // don't popup in streaming page
      if (window.location.pathname.includes("streaming")) {
        return;
      }
      this.setState({ isOpen });
    }, 600000);
  };

  render() {
    // don't popup in streaming page
    if (
      this.state.isOpen === null ||
      window.location.pathname.includes("streaming")
    ) {
      return <></>;
    }

    return (
      <ConfirmationDialog
        open={this.state.isOpen}
        onBackdropClick={this.closeModal}
      >
        <PopupWindow>
          <QuestionaryLayout>
            <h2>ユーザー登録フォーム</h2>
            <div className="text_block">
              {/* <p className="heading">{this.askTargetJoin()}の入力にご協力のほどよろしくお願いいたします。</p> */}
              <p>今後のサービス改善のためご協力よろしくお願いいたします。</p>
            </div>
            {/* 入力対象のみたずねる */}
            {this.dataSelector()}
            <PopupWindowButtonList>
              <LinkButton onClick={this.registerQuestionary}>登録</LinkButton>
              <LinkButton className="cancel" onClick={this.closeModal}>
                キャンセル
              </LinkButton>
            </PopupWindowButtonList>
          </QuestionaryLayout>
        </PopupWindow>
      </ConfirmationDialog>
    );
  }

  askTargetJoin = () => {
    const askTarget = [];
    if (!this.state.hasGenderCode) {
      askTarget.push("性別");
    }
    if (!this.state.hasBirthYear) {
      askTarget.push("生まれた年");
    }
    if (!this.state.hasResidenceCode) {
      askTarget.push("居住地");
    }
    return askTarget.join("・");
  };

  /**
   * 入力されていないものだけpushしてリターン
   */
  dataSelector = () => {
    const questions = [];
    if (!this.state.hasGenderCode) {
      questions.push(
        <div className="form" key="1">
          <p>
            性別<span className="error">{this.state.errorInfo.genderCode}</span>
          </p>
          {this.selectGender()}
        </div>
      );
    }
    if (!this.state.hasBirthYear) {
      questions.push(
        <div className="form" key="2">
          <p>
            生まれた年
            <span className="error">{this.state.errorInfo.birthYear}</span>
          </p>
          {this.selectBirthYear()}
        </div>
      );
    }
    if (!this.state.hasResidenceCode) {
      questions.push(
        <div className="form" key="3">
          <p>
            居住地
            <span className="error">{this.state.errorInfo.residenceCode}</span>
          </p>
          {this.selectPrefecture()}
        </div>
      );
    }
    return <>{questions}</>;
  };

  /**
   * 性別のselect要素を返す
   * ISO 5218規格に従う
   */
  selectGender = () => {
    const genderItemList = [
      { id: 1, name: "男性" },
      { id: 2, name: "女性" },
      { id: 9, name: "その他" },
      { id: 0, name: "未回答" },
    ];
    return (
      <select
        name="gender"
        id="genderCode"
        defaultValue={"--"}
        onChange={(e) => this.setState({ genderCode: e.currentTarget.value })}
      >
        <option key="-1" value="">
          --
        </option>
        {genderItemList.map((item, i) => {
          return (
            <option key={i} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </select>
    );
  };

  /**
   * 生まれた年のselect要素を返す
   */
  selectBirthYear = () => {
    const today = new Date();
    const this_year = today.getFullYear();

    // 今日を基準として選択肢を出す
    const start = 1900;
    const end = this_year;

    // TODO: 副作用のないように書き換えるとベター
    const opt = []; // mutable
    for (let i = end; start <= i; i--) {
      if (i === start) {
        opt.push(
          <option key={i} value={i}>
            {i}以前
          </option>
        );
      } else {
        opt.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    opt.push(
      <option key="0" value="0">
        未回答
      </option>
    );

    return (
      <select
        name="birthYear"
        id="birthYear"
        onChange={(e) => this.setState({ birthYear: e.currentTarget.value })}
      >
        <option key="-1" value="">
          --
        </option>
        {opt}
      </select>
    );
  };

  /**
   * 都道府県のselect要素を返す
   * JIS X0401規格に従う
   */
  selectPrefecture = () => {
    const prefectureItemList = [
      { id: 1, name: "北海道" },
      { id: 2, name: "青森県" },
      { id: 3, name: "岩手県" },
      { id: 4, name: "宮城県" },
      { id: 5, name: "秋田県" },
      { id: 6, name: "山形県" },
      { id: 7, name: "福島県" },
      { id: 8, name: "茨城県" },
      { id: 9, name: "栃木県" },
      { id: 10, name: "群馬県" },
      { id: 11, name: "埼玉県" },
      { id: 12, name: "千葉県" },
      { id: 13, name: "東京都" },
      { id: 14, name: "神奈川県" },
      { id: 15, name: "新潟県" },
      { id: 16, name: "富山県" },
      { id: 17, name: "石川県" },
      { id: 18, name: "福井県" },
      { id: 19, name: "山梨県" },
      { id: 20, name: "長野県" },
      { id: 21, name: "岐阜県" },
      { id: 22, name: "静岡県" },
      { id: 23, name: "愛知県" },
      { id: 24, name: "三重県" },
      { id: 25, name: "滋賀県" },
      { id: 26, name: "京都府" },
      { id: 27, name: "大阪府" },
      { id: 28, name: "兵庫県" },
      { id: 29, name: "奈良県" },
      { id: 30, name: "和歌山県" },
      { id: 31, name: "鳥取県" },
      { id: 32, name: "島根県" },
      { id: 33, name: "岡山県" },
      { id: 34, name: "広島県" },
      { id: 35, name: "山口県" },
      { id: 36, name: "徳島県" },
      { id: 37, name: "香川県" },
      { id: 38, name: "愛媛県" },
      { id: 39, name: "高知県" },
      { id: 40, name: "福岡県" },
      { id: 41, name: "佐賀県" },
      { id: 42, name: "長崎県" },
      { id: 43, name: "熊本県" },
      { id: 44, name: "大分県" },
      { id: 45, name: "宮崎県" },
      { id: 46, name: "鹿児島県" },
      { id: 47, name: "沖縄県" },
      { id: 99, name: "該当なし" },
      { id: 0, name: "未回答" },
    ];
    return (
      <select
        name="prefecture"
        id="residenceCode"
        defaultValue={"--"}
        onChange={(e) =>
          this.setState({ residenceCode: e.currentTarget.value })
        }
      >
        <option key="-1" value="">
          --
        </option>
        {prefectureItemList.map((item, i) => {
          return (
            <option key={i} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </select>
    );
  };
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    user: state.auth.user,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    toggleNotice: (msgObj) => {
      dispatch(modalActions.toggleNotice(msgObj));
    },
    toggleLoading: (msgObj) => {
      dispatch(loadingActions.toggleLoading(msgObj));
    },
  };
  return func;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Questionary));
