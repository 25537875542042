import firebase from "firebase/app";

import appConfig from "../constants/appConfig";
import { createConnectClient } from "../utility/connectWeb";
import { fetchFbToken } from "../utility/firebase";

export const callConfirmCharge = async () => {
  const fbToken = await fetchFbToken();
  if (!fbToken) return;

  // @ts-expect-error TS2769
  const response = await fetch(appConfig.PaymentSystem.confirmCharge, {
    method: "GET",
    headers: { Authorization: `'Bearer ${fbToken}` },
  });
  // TODO: 新APIをここで呼ぶ
  return response;
};

export const callChargeMember = async (msg: {
  price: number;
  productId: string;
  securityCode: string;
  cardSeq: string;
}) => {
  const fbToken = await fetchFbToken();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const response = await fetch(appConfig.PaymentSystem.chargeMember!, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: Object.keys(msg)
      .map(
        (key) => key + "=" + encodeURIComponent(msg[key as keyof typeof msg])
      )
      .join("&"),
  });
  // TODO: 新APIをここで呼ぶ
  return response;
};

export const callChargeCard = async (payload: {
  token: string;
  price: number;
  productId: string;
}) => {
  const fbToken = await fetchFbToken();

  const { token, price, productId } = payload;
  const msg = {
    productId: String(productId),
    token,
    price: Number(price),
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const response = await fetch(appConfig.PaymentSystem.chargeCard!, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: Object.keys(msg)
      // @ts-expect-error TS7053
      .map((key) => key + "=" + encodeURIComponent(msg[key]))
      .join("&"),
  });
  // TODO: 新APIをここで呼ぶ
  return response;
};

export const callUsePAW = async (msg: {
  eid: string;
  itemId: string;
  freePrice: number;
  paidPrice: number;
  count: number;
  orderId: string | null;
  giftPlaces: string[] | null;
  displayValue: number | null;
}) => {
  const auth = firebase.auth();
  const fbToken = await fetchFbToken();

  // TODO: PAWのAPI切り替え後に消す
  const currentHost =
    typeof window !== "undefined" ? window.location.hostname : "";
  const isBalusUser = auth.currentUser?.email?.endsWith("@balus.co") ?? false;
  const RELEASE_FLAG = currentHost.includes("localhost") || isBalusUser;

  if (RELEASE_FLAG) {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.error("UsePaw: User not authed!");
        return;
      }
      const connectClient = createConnectClient(auth);
      const response = await connectClient.usePawValue({
        userId,
        itemId: msg.itemId,
      });
      if (response.status !== "SPWN_API_RESPONSE_SUCCESS") {
        throw new Error(`Failed usePaw:${response.status}`);
      }
      return new Response();
    } catch (e) {
      console.error(e);
      return new Response("", { status: 400 });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return await fetch(appConfig.PaymentSystem.useValue!, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: JSON.stringify(msg),
  });
};
