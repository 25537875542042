import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const AccountMenuItem: FC<{
  to: string;
  icon: JSX.Element;
  menuName: string;
  onClick?: () => void;
}> = ({ to, icon, menuName, onClick }) => {
  return (
    <Box component="li" borderBottom={"1px solid #e6e5e6"}>
      <Link to={to} onClick={onClick}>
        <Box
          display={"grid"}
          gridTemplateColumns={"1fr max-content"}
          gridGap={"8px"}
          alignItems={"center"}
          padding={"12px 8px"}
          margin={"0 auto"}
        >
          <Box
            display={"grid"}
            gridTemplateColumns={"max-content 1fr"}
            gridGap={"8px"}
            alignItems={"center"}
            color={"#38444b"}
            fontSize={"14px"}
          >
            {icon}
            <Box component={"p"}>{menuName}</Box>
          </Box>

          <Box color={"#38444b"}>
            <NavigateNextIcon />
          </Box>
        </Box>
      </Link>
    </Box>
  );
};
