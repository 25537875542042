import { getBelongedHosting } from "utility/storage";
import { DataLayerType } from "./gtmEventModel";
import appConfig from "constants/appConfig";

/**
 * @deprecated GA4移行の7/1以降に削除する。
 * 代わりに purchase イベントを使用したいが、必須パラメータの取得が現コードのままだとできない、また購入情報はデータ分析基盤でも確認できるので見送り
 * - https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=620233602796859159-AP&hl=ja&client_type=gtm#purchase
 */
export const measurementPurchase = (purchaseMethod: string, eid: string) => {
  window.dataLayer.push({
    event: "isPurchase",
    domain: getBelongedHosting(),
    purchaseMethod,
    eid,
  });
};

/**
 * イベントをpushする
 * @see https://www.notion.so/balusco/Google-7b4f4b11f85748d19eb4ce6e9ae94843 GAについて
 *
 * @see [React製サービスにおけるGTMとの付き合い方 - Qiita](https://qiita.com/cheez921/items/a9e8d257684098db55c3)
 *
 *  GA のデータ分析の詳細はこちらを参照してください
 * @see https://www.notion.so/balusco/GA-cb2d936deb8d4e599ba1aafc2fdd0509
 *
 * ※新しいイベントをGTMに送りたい場合は、GTM側でも設定をデプロイする必要があります
 */
export const pushDataLayer = (data: DataLayerType): void => {
  // FIXME prod環境のみ有効にする。ここで判定しても、一部イベントは発火するため、GA側でフィルターするなど別の方法を検討する。大きな影響がないため一旦FIXME。
  // if (appConfig.logDebug) {
  //   return;
  // }
  // dataLayerが存在しない場合の対策
  window.dataLayer = window.dataLayer || [];
  // ecommerceオブジェクトをクリアする。object は上書きされない限り、前回までのものがそのままpushされるため。
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(data);
};

export const pushDataLayerWithClientBuildUnixTime = (
  data: DataLayerType
): void => {
  const dataWithClientBuildUnixTime = {
    ...data,
    client_build_unix_time: appConfig.buildUnixTime,
  };

  window.dataLayer = window.dataLayer || [];
  // ecommerceオブジェクトをクリアする。object は上書きされない限り、前回までのものがそのままpushされるため。
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(dataWithClientBuildUnixTime);
};
