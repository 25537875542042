import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useI18n } from "hooks/i18n/i18n";
import { useMediaQuery } from "react-responsive";

export const AccountCurrentPaw: FC<{
  pawBalance: JSX.Element;
  onClick: () => void;
}> = ({ pawBalance, onClick }) => {
  const { t } = useI18n();
  /**
   * @description デスクトップ表示かどうか
   * formatterに変数名を勝手に変えられるので、isdesktopdisplayにしている。
   */
  const isdesktopdisplay = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Box
      component={"section"}
      padding={"15px 0"}
      bgcolor={"#f9f9f9"}
      borderBottom={"1px solid #e6e5e6"}
    >
      <Box
        display={"grid"}
        gridTemplateColumns={"auto max-content"}
        gridGap={"24px"}
        alignItems={"center"}
        width={"90%"}
        maxWidth={"740px"}
        margin={"0 auto"}
      >
        <Box>
          <Box component={"h2"} fontSize={"14px"}>
            {t("account.ownedPAW")}
          </Box>
          <Box>{pawBalance}</Box>
        </Box>

        <Box>
          <Button
            type="button"
            aria-label={t("account.topUpPAW")}
            style={{
              width: "100%",
              maxWidth: "270px",
              color: "#fff",
              fontSize: isdesktopdisplay ? "14px" : "12px",
              textAlign: "center",
              padding: isdesktopdisplay ? "8px 64px" : "8px 32px",
              backgroundColor: "#00c2ae",
              borderRadius: "0px",
            }}
            onClick={onClick}
          >
            {t("account.topUpPAW")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
