import firebase from "firebase/app";
import "firebase/auth";

const URI = process.env.REACT_APP_BACKEND_API_URL;

export type SpwnApiResponse =
  | "SPWN_API_RESPONSE_UNSPECIFIED"
  | "SPWN_API_RESPONSE_SUCCESS"
  | "SPWN_API_RESPONSE_ERROR";

export type PortalTicketBonusMedia = {
  ticketId: string;
  // アップロードされたファイルのID
  mediaId: string;
  // アップロードされたファイルの名前
  filename: string;
  // 特典名
  bonusName: string;
  // ダウンロード開始日時
  releaseAt?: Date;
  // ダウンロード期限
  expiredAt?: Date;
  // ダウンロードURL
  urlForDownload?: string;
};

export type PurchasePawResponse = {
  status: SpwnApiResponse;
  data: {
    charges: PurchasePawChargeHistory[];
  };
};
export type PurchasePawChargeHistory = {
  chargeHistoryId: string;
  value: BigInt;
  validValue: BigInt;
  expiresAt: BigInt;
  chargedAt: BigInt;
  paymentMethod:
    | "PAW_PAYMENT_METHOD_UNSPECIFIED"
    | "PAW_PAYMENT_METHOD_CARD"
    | "PAW_PAYMENT_METHOD_TOKEN"
    | "PAW_PAYMENT_METHOD_MEMBER"
    | "PAW_PAYMENT_METHOD_CVS"
    | "PAW_PAYMENT_METHOD_PAYEASY"
    | "PAW_PAYMENT_METHOD_SHOP";
  paymentStatus:
    | "PAW_PAYMENT_STATUS_UNSPECIFIED"
    | "PAW_PAYMENT_STATUS_UNPROCESSED"
    | "PAW_PAYMENT_STATUS_AUTHENTICATED"
    | "PAW_PAYMENT_STATUS_AUTHPROCESSING"
    | "PAW_PAYMENT_STATUS_PAYWAITING"
    | "PAW_PAYMENT_STATUS_CHARGE"
    | "PAW_PAYMENT_STATUS_PAYEXPIRED"
    | "PAW_PAYMENT_STATUS_PAYFAIL"
    | "PAW_PAYMENT_STATUS_PAYCANCEL";
};

type FetchPawChargeHistory = {
  status: SpwnApiResponse;
  data: PurchasePawChargeHistory[];
};

type Auth = firebase.auth.Auth;
// FIXME: main/packages/grpcを参考に型ファイルだけでも共有したい
type FetchConnectClient = {
  fetchPawBalance: (req: { userId: string }) => Promise<{
    data: {
      balance: BigInt;
    };
    status: SpwnApiResponse;
  }>;
  fetchPawUsageHistory: (req: { userId: string }) => Promise<{
    status: SpwnApiResponse;
    data: {
      pawUsageHistoryId: string;
      itemId: string;
      itemName: string;
      price: BigInt;
      usedAt: BigInt;
    }[];
  }>;
  fetchPawChargeHistory: (req: {
    userId: string;
  }) => Promise<FetchPawChargeHistory>;
  purchasePawWithCard: (req: {
    userId: string;
    chargeAmount: number;
    token: string;
  }) => Promise<PurchasePawResponse>;
  purchasePawWithMember: (req: {
    userId: string;
    chargeAmount: number;
    cardSeq: string;
    securityCode: string;
  }) => Promise<PurchasePawResponse>;
  updateUser: (req: { name: string; email: string }) => Promise<{
    userId: string;
    userFirestoreId: string;
    name: string;
    email: string;
  }>;
  getTicketsBonusMedia: (req: {
    eventId: string;
    ticketIds: string[];
  }) => Promise<{
    ticketBonusMedia?: PortalTicketBonusMedia[];
  }>;
  usePawValue: (req: {
    userId: string;
    itemId: string;
  }) => Promise<{ status: SpwnApiResponse }>;
};

export const createConnectClient: (
  auth: Auth | undefined
) => FetchConnectClient = (auth) => {
  const setHeader = async () => {
    const baseHeader = {
      "Content-Type": "application/json",
      "connect-protocol-version": "1",
    } as const;
    if (auth === undefined) return baseHeader;
    const token = await auth.currentUser?.getIdToken();
    return {
      ...baseHeader,
      Authorization: "Bearer " + token,
    };
  };

  return {
    fetchPawBalance: async (req) => {
      const headers = await setHeader();

      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/FetchPawBalance",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );

      if (!response)
        return {
          status: "SPWN_API_RESPONSE_ERROR",
        };

      return response.json();
    },
    fetchPawUsageHistory: async (req) => {
      const headers = await setHeader();
      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/FetchPawUsageHistory",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );

      if (!response)
        return {
          status: "SPWN_API_RESPONSE_ERROR",
        };

      return response.json();
    },
    fetchPawChargeHistory: async (req) => {
      const headers = await setHeader();
      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/FetchPawChargeHistory",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );

      if (!response) {
        return {
          status: "SPWN_API_RESPONSE_ERROR",
        };
      }

      return response.json();
    },
    purchasePawWithCard: async (req) => {
      const headers = await setHeader();

      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/PurchasePawWithCard",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );

      if (!response)
        return {
          status: "SPWN_API_RESPONSE_ERROR",
        };

      return response.json();
    },
    purchasePawWithMember: async (req) => {
      const headers = await setHeader();
      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/PurchasePawWithMember",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );

      if (!response)
        return {
          status: "SPWN_API_RESPONSE_ERROR",
        };

      return response.json();
    },
    updateUser: async (req) => {
      const headers = await setHeader();

      const response = await fetch(
        URI + "/spwn.account.v1.PortalAccountService/UpdateUser",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );
      return response.json();
    },
    getTicketsBonusMedia: async (req) => {
      const headers = await setHeader();
      const query = new URLSearchParams({
        connect: "v1",
        encoding: "json",
        message: JSON.stringify(req),
      });

      const response = await fetch(
        URI + "/spwn.event.v1.PortalEventService/GetTicketsBonusMedia?" + query,
        {
          method: "GET",
          headers: new Headers(headers),
        }
      );
      const res = (await response.json()) as {
        ticketBonusMedia?: (Omit<
          PortalTicketBonusMedia,
          "releaseAt" | "expiredAt"
        > & { releaseAt?: string; expiredAt?: string })[];
      };
      if (!res.ticketBonusMedia) return res as {};

      return {
        ticketBonusMedia: res.ticketBonusMedia.map<PortalTicketBonusMedia>(
          (bonus) => {
            const clone = bonus as PortalTicketBonusMedia;
            if (clone.releaseAt)
              clone.releaseAt = new Date(Number(clone.releaseAt));
            if (clone.expiredAt)
              clone.expiredAt = new Date(Number(clone.expiredAt));
            return clone;
          }
        ),
      };
    },
    usePawValue: async (req) => {
      const headers = await setHeader();

      const response = await fetch(
        URI + "/spwn.spwn.v1.SpwnService/UsePawValue",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(req),
        }
      );
      if (!response) {
        return { status: "SPWN_API_RESPONSE_ERROR" };
      }

      return response.json();
    },
  };
};
