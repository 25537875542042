import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../store";
import { FullScreenDialog } from "components/common/Modal";
import { modalActions, ActionData } from "../modules/modal";
import { authActions } from "../modules/auth";
import { purchaseActions } from "../modules/purchase";
import { push } from "connected-react-router";
import { ticketActions } from "../modules/ticket";
import { DialogStyles } from "../components/Styled";
import { replaceBr, AutoLink } from "../utility";
import { withTranslation, WithTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { encodeTicketQRCodeValue } from "utility/ticket";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  PopupWindow,
  PopupWindowButtonList,
  LinkButton,
  EditForm,
  ButtonList,
  Popup,
} from "../styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { cartActions } from "modules/cart";
import { getBelongedDomain } from "utility/hosting";
import { getBelongedHostingType } from "utility/event";
import { RecommendedEvents } from "../features/events/RecommendedEvents/RecommendedEvents";

const Caution = styled.div`
  p {
    color: #ff0000;
    text-align: center;
  }
`;

const overrideMixin = css`
  h2 {
    margin-bottom: 40px;
    color: #ff0000;
  }
`;

const ConfirmPopupWindow = styled(PopupWindow)(overrideMixin);

type Props = ValueProps & FuncProps & WithTranslation;
interface ValueProps {
  isOpen: boolean;
  actionData: ActionData;
}
interface FuncProps {
  toggleActionModal: (argument: {}) => unknown;
  reAuthWithEmail: (argument: {}) => unknown;
  removeRegisteredCardInfo: (trunk: ActionData["trunk"]) => void;
  jumpTo: (id: string) => void;
  collectTicket: (action: unknown) => void;
  toggleNotice: (notice: { msg: string }) => void;
  resetPassword: (inputData: {}) => void;
  sendVerificationEmail: (input: {}) => void;
  toggleMyCart: () => void;
}

interface States {
  onChangeField: OnChangeField;
}

type OnChangeField = AuthInputField;
type AuthInputField = {
  email?: string;
  password?: string;
};

class ActionModal extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);

    this.state = {
      onChangeField: {
        email: "",
        password: "",
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    // if there is default input value in content, set its
    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      this.props.actionData &&
      this.props.actionData.contents
    ) {
      const contentKeys = Object.keys(this.props.actionData.contents);
      if (contentKeys.length !== 0) {
        contentKeys.forEach((id) => {
          // @ts-expect-error TS2532
          const content = this.props.actionData.contents[id];
          // @ts-expect-error TS18048
          if (content.tagType === "input" && content.value) {
            this.setState({
              onChangeField: {
                ...this.state.onChangeField,
                // @ts-expect-error TS18048
                [id]: content.value,
              },
            });
          }
        });
      }
    }
  }

  static getDerivedStateFromProps(props: Props, state: States) {
    let { onChangeField } = state;
    if (!props.isOpen) {
      // clear input info
      onChangeField = {
        email: "",
        password: "",
      };
    }
    return { onChangeField };
  }

  closeModal = () => {
    if (
      ["confirmTransition", "settlementComplete"].includes(
        // @ts-expect-error TS2345
        this.props.actionData.actionModalType
      )
    ) {
      return;
    }
    this.props.toggleActionModal({});
    this.setState({
      onChangeField: {
        email: "",
        password: "",
      },
    });
  };

  closeAndTransition = (link: string) => {
    this.props.toggleActionModal({});
    this.props.jumpTo(link);
  };

  closeAndCartOpen = () => {
    this.props.toggleActionModal({});
    this.props.toggleMyCart();
  };

  closeAndAction = () => {
    this.props.toggleActionModal({});
    this.action();
  };

  triggerAction = () => {
    const { contents } = this.props.actionData;
    let inputData = {};
    // @ts-expect-error TS2769
    Object.entries(contents).forEach(([contentName, _content], _idx) => {
      inputData = {
        ...inputData,
        [contentName]: this.state.onChangeField.hasOwnProperty(contentName)
          ? // @ts-expect-error TS7053
            this.state.onChangeField[contentName]
          : "",
      };
    });
    console.log(inputData);
    if (this.props.actionData.callbackTarget === "reAuthWithEmail")
      this.props.reAuthWithEmail({
        ...inputData,
        ...this.props.actionData.trunk,
      });
    else if (
      this.props.actionData.callbackTarget === "removeRegisteredCardInfo"
    )
      this.props.removeRegisteredCardInfo({ ...this.props.actionData.trunk });
    else if (this.props.actionData.callbackTarget === "jumpTo")
      // @ts-expect-error TS2532
      this.props.jumpTo(this.props.actionData.trunk.link);
    else if (this.props.actionData.callbackTarget === "resetPassword")
      this.props.resetPassword({ ...inputData });
    else if (this.props.actionData.callbackTarget === "sendVerificationEmail")
      this.props.sendVerificationEmail({});
    // this.props.toggleActionModal({})
  };

  // @ts-expect-error TS7006
  onUpdateInputValue = (event) => {
    const val = event.currentTarget.value;
    const key = event.currentTarget.id;
    this.setState({
      onChangeField: { ...this.state.onChangeField, [key]: val },
    });
  };

  action = () => {
    // @ts-expect-error TS2722
    this.props.actionData.action(this.props.actionData.args);
  };

  render() {
    if (this.props.actionData === null) return <div />;

    const { isOpen } = this.props;

    return (
      <FullScreenDialog
        style={DialogStyles}
        open={isOpen}
        onBackdropClick={this.closeModal}
      >
        {this.renderSwither()}
      </FullScreenDialog>
    );
  }

  renderSwither = () => {
    switch (this.props.actionData.actionModalType) {
      case "shareTicket":
        return this.confirmShareTicket();
      case "eventCheckIn":
        return this.confirmEventCheckIn();
      case "rewardCheckIn":
        return this.confirmRewardCheckIn();
      case "goodsCheckIn":
        return this.confirmRewardCheckIn(true);
      case "goodsOrderSheetCheckIn":
        return this.confirmRewardCheckIn(true, true);
      case "confirmTransition":
        return this.confirmTransition();
      case "confirmAction":
        return this.confirmAction();
      case "settlementComplete":
        return this.settlementComplete();
      case "addedToCart":
        return this.addedToCart();
      default:
        return this.defaultModal();
    }
  };

  defaultModal = () => {
    const { t } = this.props;
    return (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2>{this.props.actionData.caption}</h2>
          {this.contents()}
          <PopupWindowButtonList>
            <LinkButton className="login" onClick={this.triggerAction}>
              {this.props.actionData.btnMsg}
            </LinkButton>
            <LinkButton className="cancel" onClick={this.closeModal}>
              {t("login.buttons.cancel")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    );
  };

  contents = () => {
    const { contents } = this.props.actionData;
    if (contents === undefined || Object.keys(contents).length === 0) {
      return <div />;
    }
    return Object.entries(contents).map(([contentName, content], idx) => {
      let elem = <div />;
      if (content.tagType === "input") {
        elem = (
          <input
            className={content.classAttr}
            type={content.typeAttr}
            onChange={this.onUpdateInputValue}
            id={contentName}
            placeholder={content.placeHolderAttr}
            defaultValue={content.value || ""} // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
            disabled={!!content.disable}
          />
        );
      } else if (content.tagType === "p") {
        elem = <p>{content.value}</p>;
      }
      return (
        <EditForm key={`actionModalContent_${contentName}_${idx}`}>
          <p className="heading">{content.title}</p>
          <p className="form">{elem}</p>
        </EditForm>
      );
    });
  };

  confirmShareTicket = () => {
    const { t } = this.props;
    // @ts-expect-error TS2339
    const { issuedTicket, event } = this.props.actionData.trunk;
    let title = t("modal.actionModal.checkShareTicket");
    let actionButton = (
      <LinkButton className="share" onClick={() => this.action()}>
        {t("modal.actionModal.createShareLink")}
      </LinkButton>
    );
    let contents = (
      <>
        <div className="text_block">
          <p>{t("modal.actionModal.shareMsg")}</p>
        </div>
        <Caution className="text_block">
          <p>{t("modal.actionModal.shareMsg2")}</p>
        </Caution>
      </>
    );
    if (issuedTicket.sharingCode !== undefined) {
      title = t("modal.actionModal.loadingShareTicket");
      // @ts-expect-error TS2532
      const eventId = this.props.actionData.args.eid;
      // @ts-expect-error TS2339
      const { ticketNumber } = this.props.actionData.args;
      const domain = getBelongedDomain(
        // @ts-expect-error TS2345
        getBelongedHostingType(event.belongedHostings)
      );
      console.log("SSSS", issuedTicket.sharingCode);
      console.log(
        "SSSS",
        `https://${domain}/account/ticket-receive?eid=${eventId}&tid=${issuedTicket.ticketId}&tnum=${ticketNumber}&code=${issuedTicket.sharingCode}`
      );
      actionButton = (
        <LinkButton className="share" onClick={this.copyToClipboard}>
          {t("modal.actionModal.copyShareLink")}
        </LinkButton>
      );
      contents = (
        <>
          <div className="text_block">
            <p className="heading">{t("modal.actionModal.shareLink")}</p>
            <textarea
              id="sharingURL"
              // リンク差し替え
              readOnly={true}
              value={`https://${domain}/account/ticket-receive?eid=${eventId}&tid=${issuedTicket.ticketId}&tnum=${ticketNumber}&code=${issuedTicket.sharingCode}`}
            />
          </div>
        </>
      );
    }
    if (issuedTicket.friend !== undefined) {
      title = t("modal.actionModal.loadingShareTicket");
      const friendName =
        issuedTicket.friendName !== undefined &&
        issuedTicket.friendName !== null &&
        issuedTicket.friendName !== ""
          ? issuedTicket.friendName
          : `SPWN ID: ${issuedTicket.friend} `;

      actionButton = <></>;
      contents = (
        <>
          <div className="text_block">
            <p>{t("modal.actionModal.friendShare", { name: friendName })}</p>
          </div>
        </>
      );
    }
    return (
      <ConfirmPopupWindow>
        <div className="popup_window_layout">
          <h2>{title}</h2>
          {contents}
          <PopupWindowButtonList>
            {actionButton}
            <LinkButton className="cancel" onClick={this.closeModal}>
              {t("modal.actionModal.close")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </ConfirmPopupWindow>
    );
  };

  makeEncodedQRCodeValue = (
    type: Parameters<typeof encodeTicketQRCodeValue>[0]["ticketType"]
  ) => {
    return encodeTicketQRCodeValue({
      // @ts-expect-error TS2532
      eventId: this.props.actionData.args.eid,
      // @ts-expect-error TS2532
      ticketNumber: this.props.actionData.args.ticketNumber,
      // @ts-expect-error TS2532
      authCode: this.props.actionData.args.authCode
        ? // @ts-expect-error TS2532
          this.props.actionData.args.authCode
        : null,
      ticketType: type,
      // @ts-expect-error TS2532
      orderId: this.props.actionData.args.orderId,
    });
  };

  confirmEventCheckIn = () => {
    // TODO@sumiya create value for qr code
    const { t } = this.props;
    // const {args} = this.props.actionData
    return (
      <ConfirmPopupWindow>
        <div className="popup_window_layout">
          <h2 className="recertification_heading">
            {t("modal.actionModal.receptionMsg")}
          </h2>
          <div className="text_block">
            <p>{t("modal.actionModal.noticeReceptionMsg")}</p>
          </div>

          <QRCode
            value={this.makeEncodedQRCodeValue("isChecked")}
            size={256}
            style={{
              display: "block",
              margin: "0 auto",
              border: "12px solid #c9c9c9",
            }}
          />

          <PopupWindowButtonList>
            <LinkButton className="cancel" onClick={this.closeModal}>
              {t("modal.actionModal.close")}
            </LinkButton>
            {/* TODO ボタンによる引換はなくなる予定 */}
            <ExpansionPanel style={{ marginTop: "24px" }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{"スタッフ専用"}</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <PopupWindowButtonList>
                  <LinkButton
                    className="modal_cvs_confirm"
                    onClick={() => this.action()}
                  >
                    {t("modal.actionModal.checkIn")}
                  </LinkButton>
                </PopupWindowButtonList>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </PopupWindowButtonList>
        </div>
      </ConfirmPopupWindow>
    );
  };

  confirmRewardCheckIn = (isRedeemGoods = false, isOrderSheet = false) => {
    const { t } = this.props;
    let redeemTarget = t("modal.actionModal.benefits");
    if (isRedeemGoods && isOrderSheet) {
      redeemTarget = "オーダーシート";
    }
    if (isRedeemGoods && !isOrderSheet) {
      redeemTarget = t("modal.actionModal.goods");
    }
    const redeemType = isRedeemGoods ? "isGoods" : "isRedeemed";
    // TODO@sumiya create value for qr code
    // const {args} = this.props.actionData;
    return (
      <ConfirmPopupWindow>
        <div className="popup_window_layout">
          <h2 className="recertification_heading">
            {t("modal.actionModal.receptionMsg")}
          </h2>
          <div className="text_block">
            <p>
              {t("modal.actionModal.redeemReceptionMsg", {
                target: redeemTarget,
              })}
            </p>
          </div>

          <QRCode
            value={this.makeEncodedQRCodeValue(redeemType)}
            size={256}
            style={{
              display: "block",
              margin: "0 auto",
              border: "12px solid #c9c9c9",
            }}
          />

          <PopupWindowButtonList>
            <LinkButton className="cancel" onClick={this.closeModal}>
              {t("modal.actionModal.close")}
            </LinkButton>
            {/* TODO ボタンによる引換はなくなる予定 */}
            <ExpansionPanel style={{ marginTop: "24px" }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{"スタッフ専用"}</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <PopupWindowButtonList>
                  <LinkButton
                    className="modal_cvs_confirm"
                    onClick={() => this.action()}
                  >
                    {redeemTarget}
                    {t("modal.actionModal.redeemTarget")}
                  </LinkButton>
                </PopupWindowButtonList>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </PopupWindowButtonList>
        </div>
      </ConfirmPopupWindow>
    );
  };

  /**
   * 確認して遷移させる
   */
  confirmTransition = () => {
    const {
      // @ts-expect-error TS2339
      trunk: { link },
      caption,
      msg,
      btnMsg,
    } = this.props.actionData;
    return (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2 className="recertification_heading">{caption}</h2>
          <div className="text_block" style={{ whiteSpace: "pre-line" }}>
            <p>{AutoLink(msg)}</p>
          </div>
          <PopupWindowButtonList>
            <LinkButton
              className="login"
              onClick={() => this.closeAndTransition(link)}
            >
              {btnMsg}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    );
  };

  /**
   * 決済完了
   * 未決済の商品がカートにある場合、ユーザに知らせる
   */
  settlementComplete = () => {
    const {
      // @ts-expect-error TS2339
      trunk: { link, cart },
      caption,
      msg,
      btnMsg,
    } = this.props.actionData;
    const { t } = this.props;
    return cart.length !== 0 ? (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2 className="recertification_heading">{caption}</h2>
          <div className="text_block" style={{ whiteSpace: "pre-line" }}>
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("modal.actionModal.unsettledItem1")}
              <br />
              {t("modal.actionModal.unsettledItem2")}
            </p>
          </div>
          <PopupWindowButtonList>
            <LinkButton className="login" onClick={this.closeAndCartOpen}>
              {t("modal.actionModal.checkToCart")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    ) : (
      <Popup>
        <h2 className="recertification_heading">{caption}</h2>
        <p
          className="popup_message"
          style={{ whiteSpace: "pre-line", textAlign: "center" }}
        >
          {AutoLink(msg)}
        </p>
        <ButtonList>
          <LinkButton
            className="login"
            onClick={() => this.closeAndTransition(link)}
          >
            {btnMsg}
          </LinkButton>
          <LinkButton
            className="cancel"
            onClick={() => {
              // settlementComplete 時は this.closeModal が無効になるため、直接モーダルを閉じる処理を記載する
              // this.closeModalの無効化処理は cart.length !== 0 の場合の考慮なので、cart.length === 0 であるここでは閉じても問題ない
              this.props.toggleActionModal({});
              this.setState({
                onChangeField: {
                  email: "",
                  password: "",
                },
              });
            }}
          >
            {
              t("modal.actionModal.close") // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
            }
          </LinkButton>
        </ButtonList>
        <RecommendedEvents
          type={"settlementCompleted"}
          onClickEventCard={() => {
            // settlementComplete 時は this.closeModal が無効になるため、直接モーダルを閉じる処理を記載する
            // this.closeModalの無効化処理は cart.length !== 0 の場合の考慮なので、cart.length === 0 であるここでは閉じても問題ない
            this.props.toggleActionModal({});
            this.setState({
              onChangeField: {
                email: "",
                password: "",
              },
            });
          }}
        />
      </Popup>
    );
  };

  /**
   * 確認して関数を実行する
   */
  confirmAction = () => {
    const { t } = this.props;
    const { caption, msg, btnMsg, closeBtnMsg } = this.props.actionData;
    return (
      <Popup>
        <h2>{caption}</h2>
        <p className="popup_message" style={{ whiteSpace: "pre-line" }}>
          {replaceBr(msg)}
        </p>
        <ButtonList>
          <LinkButton className="login" onClick={this.closeAndAction}>
            {btnMsg}
          </LinkButton>
          <LinkButton className="cancel" onClick={this.closeModal}>
            {
              closeBtnMsg || t("modal.actionModal.close") // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
            }
          </LinkButton>
        </ButtonList>
      </Popup>
    );
  };

  /**
   * カートに追加後
   */
  addedToCart = () => {
    const { t } = this.props;
    const { caption, msg, btnMsg, closeBtnMsg } = this.props.actionData;
    return (
      <Popup>
        <h2>{caption}</h2>
        <p className="popup_message" style={{ whiteSpace: "pre-line" }}>
          {replaceBr(msg)}
        </p>
        <ButtonList>
          <LinkButton className="login" onClick={this.closeAndAction}>
            {btnMsg}
          </LinkButton>
          <LinkButton className="cancel" onClick={this.closeModal}>
            {
              closeBtnMsg || t("modal.actionModal.close") // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
            }
          </LinkButton>
        </ButtonList>
        <RecommendedEvents
          type={"addedToCart"}
          onClickEventCard={() => {
            this.closeModal();
          }}
        />
      </Popup>
    );
  };

  copyToClipboard = () => {
    const { t } = this.props;
    const textarea = document.getElementById(
      "sharingURL"
    ) as HTMLTextAreaElement;
    textarea.select();
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const sel = window.getSelection();
    // @ts-expect-error TS18047
    sel.removeAllRanges();
    // @ts-expect-error TS18047
    sel.addRange(range);
    textarea.setSelectionRange(0, 999999);
    // let copytext = document.getElementById("sharingURL");
    // let range = document.createRange();
    // range.selectNode(copytext);
    // window.getSelection().addRange(range);
    document.execCommand("copy");
    this.props.toggleNotice({ msg: t("modal.actionModal.copy") });
  };
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    isOpen: state.modal.isOpenModalAction,
    actionData: state.modal.actionModalData,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    jumpTo: (link) => {
      dispatch(push(link));
    },
    toggleActionModal: (payload) => {
      dispatch(modalActions.toggleActionModal(payload));
    },
    reAuthWithEmail: (payload) => {
      dispatch(authActions.reAuthWithEmail.started(payload));
    },
    removeRegisteredCardInfo: (action) => {
      dispatch(purchaseActions.removeRegisteredCardInfo.started(action));
    },
    collectTicket: (action) => {
      // @ts-expect-error TS2345
      dispatch(ticketActions.collectTicket.started(action));
    },
    toggleNotice: (payload) => {
      dispatch(modalActions.toggleNotice(payload));
    },
    resetPassword: (payload) => {
      dispatch(authActions.resetPassword.started(payload));
    },
    sendVerificationEmail: (payload) => {
      dispatch(authActions.sendVerificationEmail.started(payload));
    },
    toggleMyCart: () => {
      dispatch(cartActions.toggleMyCart({}));
    },
  };
  return func;
};

const TransActionModal = withTranslation()(ActionModal);

export default connect(mapStateToProps, mapDispatchToProps)(TransActionModal);
