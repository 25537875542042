import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";
import "firebase/firestore";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import appConfig from "../constants/appConfig";
import {
  fetchFbToken,
  fetchFirestoreDocument,
  fetchDatabaseValue,
  fetchFirestoreCollectionGroupMap,
  fetchFirestoreCollection,
  fetchFirestoreCollectionMap,
  convertDateToTimestamp,
} from "../utility/firebase";
import { modalActions } from "../modules/modal";
import { loadingActions } from "../modules/loading";

import {
  IsJsonString,
  addKeyToObjectMap,
  convertMapToValues,
  convertArrayToDict,
} from "../utility";
import { fetchRetry } from "../utility/retryFetch";
import { errorReport } from "../utility/logger";
import { streamingActions } from "../modules/streaming";
import { isOpenEventStreamingPage, calcVODExpiredAt } from "../utility/event";
import { fetchMyOrders } from "../utility/purchase";
import { span } from "../utility/performance";
import { eventActions } from "./event";
import { i18nextT } from "../hooks/i18n/i18n";
import { Store } from "store";
import { unique } from "utility/array";
import { generateErrorMessage } from "./error";
import type { MyProductData } from "@spwn/types";
import type { ValueOf } from "@spwn/types/common";
import type {
  Event,
  IssuedTicket,
  LotteryAppForm,
  ProductData,
  TicketConfig,
  GoodsConfig,
  UserVoucherProduct,
  MyEventTicketData,
  ActiveTransaction,
  Ticket,
} from "@spwn/types/firebase/firestore";
import {
  PurchasedTicket,
  PurchasedTicketOrder,
  TicketData,
} from "@spwn/types/firebase/database";

const actionCreator = actionCreatorFactory("ticket");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ticketActions = {
  clearStateByKey: actionCreator<keyof ticketState>("clearStateByKey"),
  fetchEventTickets: actionCreator.async<any, any>("fetchEventTickets"),
  fetchEventGoods: actionCreator.async<any, any>("fetchEventGoods"),
  fetchMyTickets: actionCreator.async<ReqFetchMyTickets, any>("fetchMyTickets"),
  fetchUnProcessData: actionCreator.async<any, any>("fetchUnProcessData"),
  fetchProductConfig: actionCreator.async<any, any>("fetchProductConfig"),
  applyTicketLottery: actionCreator.async<any, any>("applyTicketLottery"),
  withdrawTicketLottery: actionCreator.async<any, any>("withdrawTicketLottery"),
  fetchWaitingTicketLotteryList: actionCreator.async<void, any>(
    "fetchWaitingTicketLotteryList"
  ),
  fetchTicketList: actionCreator.async<any, any>("fetchTicketList"),
  collectTicket: actionCreator.async<ReqCollectTicket, any>("collectTicket"),
  redeemTicket: actionCreator.async<ReqCollectTicket, any>("redeemTicket"),
  shareTicket: actionCreator.async<any, any>("shareTicket"),
  watchTicketStates: actionCreator.async<any, any>("watchTicketStates"),
  watchSharedTicketStates: actionCreator.async<any, any>(
    "watchSharedTicketStates"
  ),
  receiveTicket: actionCreator.async<any, any>("receiveTicket"),
  fetchEventTicketData: actionCreator.async<any, any>("fetchEventTicketData"),
  fetchRelatedTicketData: actionCreator.async<any, any>(
    "fetchRelatedTicketData"
  ),
  issueTicketNumIfPossible: actionCreator.async<
    ReqIssueTicketNumIfPossible,
    any
  >("issueTicketNumIfPossible"),
  startWatchingIssuedTicket: actionCreator<any>("startWatchingIssuedTicket"),
  fetchEventTicketSurveyResult: actionCreator.async<
    ReqFetchEventTicketSurveyResult,
    any
  >("fetchEventTicketSurveyResult"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface ticketState {
  eventTickets: ProductData[];
  eventTicketMap: Record<string, Ticket>;
  eventGoods: (ProductData & {
    fulfillmentShipment?: {
      title: string;
    };
  })[];
  eventGoodsMap: {
    [goodsId: string]: ProductData & {
      fulfillmentShipment?: {
        title: string;
      };
    };
  };
  myTickets: MyEventTicketData[];
  myTicketMap: { [eid: string]: MyEventTicketData };
  myUnprocessData: ActiveTransaction[];
  config: {
    ticketConfig: TicketConfig;
    goodsConfig?: GoodsConfig;
  };
  lotteryWaitingList: { [eventId: string]: LotteryAppForm };
  ticketList: Record<string, Record<string, Ticket>>;
  issuedTicketStates: {
    [eventId: string]: { [ticketNum: string]: IssuedTicket };
  };
  owner: { [eventId: string]: { [ticketNum: string]: IssuedTicket } };
  friend: { [eventId: string]: { [ticketNum: string]: IssuedTicket } };
  eventTicketData: {
    isError: boolean;
    ticketData: { tid?: TicketData };
    eventData: { eid?: Event };
    configData: unknown;
    placeData: unknown;
  };
  fetchingMyTickets: boolean;
  relatedTicketData: {
    [eventId: string]: { [ticketId: string]: MyProductData };
  };
  watchingIssuedTicketEventIds: string[];
  doneWatchingIssuedTicket: boolean;
  eventSurveyResult: (Omit<
    ValueOf<Event["ticketOptionalInputData"]>,
    "inputInfo"
  > & { inputText: string })[];
}
export type TicketLabelStatus = "shared" | "vod";
export interface ReqCollectTicket {
  eid: string;
  ticketNumber: string;
  isGoods?: boolean;
  authCode?: string;
  orderId?: number;
}
// export interface ReqRedeemTicket {
//   eid: string;
//   ticketNumber: string;
//   isGoods?: boolean;
//   authCode?: string;
//   orderId?: number;
// }
export interface ReqReceiveTicket {
  eid: string;
  ticketNum: string;
  tid: string;
  code: string;
}

type ReqFetchMyTickets = {
  callConfirmTran?: boolean;
};

type ReqFetchEventTicketSurveyResult = {
  eventId: string;
};

type ReqIssueTicketNumIfPossible = {
  eid: string;
  itemId: string;
  isGoods: boolean;
  orderId?: number | null;
};

const initialState: ticketState = {
  eventTickets: [],
  // @ts-expect-error TS2322
  eventTicketMap: null,
  eventGoods: [],
  // @ts-expect-error TS2322
  eventGoodsMap: null,
  myTickets: [],
  // @ts-expect-error TS2322
  myTicketMap: null,
  // @ts-expect-error TS2322
  myUnprocessData: null,
  config: {
    // @ts-expect-error TS2322
    ticketConfig: null,
    // @ts-expect-error TS2322
    goodsConfig: null,
  },
  // @ts-expect-error TS2322
  lotteryWaitingList: null,
  ticketList: {},
  issuedTicketStates: {},
  eventTicketData: {
    isError: false,
    ticketData: {},
    eventData: {},
    configData: {},
    placeData: {},
  },
  owner: {},
  friend: {},
  fetchingMyTickets: false,
  relatedTicketData: {},
  watchingIssuedTicketEventIds: [],
  doneWatchingIssuedTicket: false,
  // @ts-expect-error TS2322
  eventSurveyResult: null,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const eventReducer = reducerWithInitialState(initialState)
  .case(ticketActions.clearStateByKey, (state, key: keyof ticketState) => {
    return { ...state, [key]: initialState[key] };
  })
  .case(ticketActions.fetchEventTickets.done, (state, payload: any) => {
    return {
      ...state,
      eventTickets: payload.list,
      eventTicketMap: payload.map,
    };
  })
  .case(ticketActions.fetchEventGoods.done, (state, payload: any) => {
    return { ...state, eventGoods: payload.list, eventGoodsMap: payload.map };
  })
  .case(ticketActions.fetchMyTickets.done, (state, payload: any) => {
    if (
      payload.hasOwnProperty("fetchingMyTickets") &&
      Object.keys(payload).length === 1
    ) {
      return { ...state, fetchingMyTickets: payload.fetchingMyTickets };
    } else {
      return {
        ...state,
        fetchingMyTickets: payload.hasOwnProperty("fetchingMyTickets")
          ? payload.fetchingMyTickets
          : false,
        myTickets: payload.myTicketData,
      };
    }
  })
  .case(ticketActions.fetchUnProcessData.done, (state, payload: any) => {
    return { ...state, myUnprocessData: payload };
  })
  .case(ticketActions.fetchProductConfig.done, (state, payload: any) => {
    return { ...state, config: payload };
  })
  .case(
    ticketActions.fetchWaitingTicketLotteryList.done,
    (state, payload: any) => {
      return {
        ...state,
        lotteryWaitingList: { ...state.lotteryWaitingList, ...payload },
      };
    }
  )
  .case(ticketActions.fetchTicketList.done, (state, payload: any) => {
    return { ...state, ticketList: { ...state.ticketList, ...payload } };
  })
  .case(ticketActions.collectTicket.done, (state, _payload: any) => {
    return { ...state };
  })
  .case(ticketActions.redeemTicket.done, (state, _payload: any) => {
    return { ...state };
  })
  .case(ticketActions.shareTicket.done, (state, _payload: any) => {
    return { ...state };
  })
  .case(ticketActions.watchTicketStates.done, (state, payload: any) => {
    const newIssuedTickets: any = JSON.parse(
      JSON.stringify({ ...state.friend, ...state.issuedTicketStates })
    );
    Object.keys(payload).forEach((eventId) => {
      if (!newIssuedTickets.hasOwnProperty(eventId))
        newIssuedTickets[eventId] = {};
      Object.entries(payload[eventId]).forEach(([ticketNum, val]) => {
        newIssuedTickets[eventId][ticketNum] = val;
      });
    });
    // check if all watching is done
    const doneWatchingIssuedTicket =
      state.watchingIssuedTicketEventIds.length !== 0 &&
      Object.keys(newIssuedTickets).length ===
        state.watchingIssuedTicketEventIds.length;
    return {
      ...state,
      issuedTicketStates: newIssuedTickets,
      owner: { ...state.owner, ...payload },
      doneWatchingIssuedTicket,
    };
  })
  .case(ticketActions.watchSharedTicketStates.done, (state, payload: any) => {
    const newIssuedTickets: any = JSON.parse(
      JSON.stringify({ ...state.owner, ...state.issuedTicketStates })
    );
    Object.keys(payload).forEach((eventId) => {
      if (!newIssuedTickets.hasOwnProperty(eventId))
        newIssuedTickets[eventId] = {};
      Object.entries(payload[eventId]).forEach(([ticketNum, val]) => {
        newIssuedTickets[eventId][ticketNum] = val;
      });
    });
    // console.log("friend", newIssuedTickets)
    return {
      ...state,
      issuedTicketStates: newIssuedTickets,
      friend: { ...state.friend, ...payload },
    };
  })
  .case(ticketActions.receiveTicket.done, (state, _payload: any) => {
    return { ...state };
  })
  .case(ticketActions.fetchEventTicketData.done, (state, payload: any) => {
    return { ...state, eventTicketData: payload };
  })
  .case(ticketActions.fetchRelatedTicketData.done, (state, payload: any) => {
    return {
      ...state,
      relatedTicketData: {
        ...state.relatedTicketData,
        ...payload,
      },
    };
  })
  .case(ticketActions.startWatchingIssuedTicket, (state, payload) => {
    return { ...state, watchingIssuedTicketEventIds: payload.eventIds };
  })
  .case(
    ticketActions.fetchEventTicketSurveyResult.done,
    (state, payload: any) => {
      return { ...state, eventSurveyResult: payload };
    }
  );
/* eslint-disable @typescript-eslint/no-explicit-any */

export default eventReducer;

export function* ticketSaga() {
  yield takeEvery(ticketActions.fetchEventTickets.started, fetchEventTickets);
  yield takeEvery(ticketActions.fetchEventGoods.started, fetchEventGoods);
  yield takeEvery(ticketActions.fetchMyTickets.started, fetchMyTickets);
  yield takeEvery(ticketActions.fetchProductConfig.started, fetchProductConfig);
  yield takeEvery(ticketActions.applyTicketLottery.started, applyTicketLottery);
  yield takeEvery(
    ticketActions.fetchWaitingTicketLotteryList.started,
    fetchWaitingTicketLotteryList
  );
  yield takeEvery(ticketActions.fetchTicketList.started, fetchTicketList);
  yield takeEvery(
    ticketActions.withdrawTicketLottery.started,
    withdrawTicketLottery
  );
  yield takeEvery(ticketActions.collectTicket.started, collectTicket);
  yield takeEvery(ticketActions.redeemTicket.started, redeemTicket);
  yield takeEvery(ticketActions.shareTicket.started, shareTicket);
  yield takeEvery(ticketActions.watchTicketStates.started, watchTicketStates);
  yield takeEvery(
    ticketActions.watchSharedTicketStates.started,
    watchSharedTicketStates
  );
  yield takeEvery(ticketActions.receiveTicket.started, receiveTicket);
  yield takeEvery(
    ticketActions.fetchEventTicketData.started,
    fetchEventTicketData
  );
  yield takeEvery(
    ticketActions.fetchRelatedTicketData.started,
    fetchRelatedTicketData
  );
  yield takeEvery(
    ticketActions.issueTicketNumIfPossible.started,
    issueTicketNumIfPossible
  );
  yield takeEvery(
    ticketActions.fetchEventTicketSurveyResult.started,
    fetchEventTicketSurveyResult
  );
}

// @ts-expect-error TS7006
function* fetchRelatedTicketData(action) {
  const { eventId } = action.payload;
  const ref = `tickets/${eventId}/tickets`;
  // @ts-expect-error TS7057
  const tickets = yield fetchFirestoreCollection(ref);
  const ret = {};
  // @ts-expect-error TS7006
  tickets.forEach((val) => {
    // @ts-expect-error TS7053
    ret[val._id] = val;
  });
  yield put(
    ticketActions.fetchRelatedTicketData.done({
      [eventId]: ret,
    })
  );
}

// @ts-expect-error TS7006
function issuedTicketStateWatcherChannel(payload) {
  const { eventId } = payload;
  const { userId } = payload;
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tickets/${eventId}/issuedTickets`)
      .where("owner", "==", userId)
      .onSnapshot(
        (snapshot) => {
          const issuedTickets = {};
          snapshot.forEach((doc) => {
            // @ts-expect-error TS7053
            issuedTickets[doc.id] = doc.data();
          });
          if (Object.keys(issuedTickets).length === 0) {
            emitter({ [eventId]: {} });
          } else {
            emitter({ [eventId]: issuedTickets });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

// @ts-expect-error TS7006
function* watchTicketStates(action) {
  const { currentUser } = firebase.auth();
  if (
    currentUser === null ||
    currentUser === undefined ||
    currentUser.uid === undefined ||
    currentUser.uid === null
  ) {
    return;
  }
  const { eventId } = action.payload;
  const {
    firestore: { channels },
  } = yield select();
  //既にlisten済ならreturn
  if (isRegisteredChannel(channels, `${eventId}_IssuedTickets`)) return;
  try {
    // @ts-expect-error TS7057
    const channel = yield call(issuedTicketStateWatcherChannel, {
      eventId,
      userId: currentUser.uid,
    });
    // ループ回して変更をwatchする
    while (true) {
      // @ts-expect-error TS7057
      const states = yield take(channel);
      yield put(ticketActions.watchTicketStates.done(states));
      yield put(
        firestoreActions.addChannel({
          ...channel,
          name: `${eventId}_IssuedTickets`,
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

// @ts-expect-error TS7006
function sharedTicketStateWatcherChannel(payload) {
  const { eventId } = payload;
  const { userId } = payload;
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tickets/${eventId}/issuedTickets`)
      .where("friend", "==", userId)
      .onSnapshot(
        (snapshot) => {
          const issuedTickets = {};
          snapshot.forEach((doc) => {
            // @ts-expect-error TS7053
            issuedTickets[doc.id] = doc.data();
          });
          if (Object.keys(issuedTickets).length === 0) {
            emitter({ [eventId]: {} });
          } else {
            emitter({ [eventId]: issuedTickets });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

// @ts-expect-error TS7006
function* watchSharedTicketStates(action) {
  if (
    firebase.auth().currentUser === null ||
    firebase.auth().currentUser === undefined ||
    // @ts-expect-error TS2531
    firebase.auth().currentUser.uid === undefined ||
    // @ts-expect-error TS2531
    firebase.auth().currentUser.uid === null
  ) {
    return;
  }
  const { eventId } = action.payload;
  const {
    firestore: { channels },
  } = yield select();
  //既にlisten済ならreturn
  if (isRegisteredChannel(channels, `${eventId}_SharedTickets`)) return;

  try {
    // @ts-expect-error TS7057
    const channel = yield call(sharedTicketStateWatcherChannel, {
      eventId,
      // @ts-expect-error TS2531
      userId: firebase.auth().currentUser.uid,
    });
    // ループ回して変更をwatchする
    while (true) {
      // @ts-expect-error TS7057
      const states = yield take(channel);
      yield put(ticketActions.watchSharedTicketStates.done(states));
      yield put(
        firestoreActions.addChannel({
          ...channel,
          name: `${eventId}_SharedTickets`,
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

// @ts-expect-error TS7006
function* withdrawTicketLottery(action) {
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("ticket.lottery.withdrawal") })
  );
  const { eventId } = action.payload;
  // @ts-expect-error TS2531
  const fbToken = yield firebase.auth().currentUser.getIdToken(true);

  // @ts-expect-error TS2769
  const response = yield fetch(appConfig.CloudFunctions.cancelTicketLottery, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: JSON.stringify({
      eid: eventId,
    }),
  });
  switch (response.status) {
    case 200:
      yield fetchWaitingTicketLotteryList();
      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleNotice({
          msg: i18nextT("ticket.lottery.withdrawaled"),
        })
      );
      return;
  }
  const text = response.text();
  if (IsJsonString(text)) {
    yield put(loadingActions.toggleLoading({}));
    yield put(modalActions.toggleError({ msg: JSON.parse(text).msg }));
  } else {
    console.error(text);
    yield put(loadingActions.toggleLoading({}));
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.lottery.failWithdrawal"),
      })
    );
  }
}

// @ts-expect-error TS7006
function* fetchTicketList(action) {
  const { eventIds } = action.payload;
  const promises = [];
  for (const eventId of eventIds) {
    promises.push(fetchFirestoreCollectionMap(`/tickets/${eventId}/tickets`));
  }
  // @ts-expect-error TS7057
  const rets = yield Promise.all(promises);
  const ticketList = {};
  // @ts-expect-error TS7006
  rets.forEach((val, idx) => {
    // @ts-expect-error TS7053
    ticketList[eventIds[idx]] = val;
  });
  yield put(ticketActions.fetchTicketList.done(ticketList));
}

function* fetchWaitingTicketLotteryList() {
  try {
    if (
      firebase.auth().currentUser === null ||
      firebase.auth().currentUser === undefined
    ) {
      ticketActions.fetchWaitingTicketLotteryList.done({});
      return;
    }
    // @ts-expect-error TS2531
    const userId = firebase.auth().currentUser.uid;
    // @ts-expect-error TS7057
    const state = yield select();
    const waitingList = {};
    const docs = [];
    // @ts-expect-error TS7034
    const eventIds = [];
    for (const val of state.event.openEvents) {
      const eventId = val._id;
      eventIds.push(eventId);
      docs.push(fetchFirestoreDocument(`tickets/${eventId}/lottery/${userId}`));
    }
    if (docs.length === 0) return;
    // @ts-expect-error TS7057
    const rets = yield Promise.all(docs);
    // @ts-expect-error TS7006
    rets.forEach((val, idx) => {
      // @ts-expect-error TS2322
      waitingList[eventIds[idx]] = val;
    });
    yield put(ticketActions.fetchWaitingTicketLotteryList.done(waitingList));
  } catch (e) {
    console.error(e);
    modalActions.toggleError({ msg: i18nextT("ticket.lottery.fetchFail") });
  }
}

// @ts-expect-error TS7006
function* applyTicketLottery(action) {
  // @ts-expect-error TS2531
  const fbToken = yield firebase.auth().currentUser.getIdToken(true);
  const {
    purchase: { cardInfoList },
    auth: { _user },
  } = yield select();
  const { eventId, optionalInputData } = action.payload;
  const body = JSON.stringify({
    eid: action.payload.eventId,
    cart: action.payload.cart,
    orderType: action.payload.orderType,
    cvsParams: action.payload.cvsParams,
    fanClubId: action.payload.fanClubId,
    verificationCode: action.payload.verificationCode,
    cardSeqNum:
      action.payload.orderType === "MEM" &&
      action.payload.selectedCardIdx !== -1
        ? cardInfoList[action.payload.selectedCardIdx].cardSeq
        : "",
  });
  yield put(
    loadingActions.toggleLoading({
      msg: i18nextT("ticket.lottery.applyLottery"),
    })
  );

  // add optional input data log
  if (optionalInputData) {
    const optionalInputDataMap = {
      eventId,
      optionalInputData,
    };
    // @ts-expect-error TS7057
    const surveyResponse = yield fetch(
      // @ts-expect-error TS2769
      appConfig.CloudFunctions.storeSurveyData,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify(optionalInputDataMap),
      }
    );
    // @ts-expect-error TS7057
    const surveyResBody = yield surveyResponse.json();
    if (surveyResponse.status !== 200) {
      console.error(surveyResBody);
      yield put(
        modalActions.toggleError({
          msg: i18nextT("ticket.lottery.failApplyLottery"),
        })
      );
      yield put(loadingActions.toggleLoading({}));
      return;
    }
  }
  // @ts-expect-error TS2769
  const response = yield fetch(appConfig.CloudFunctions.applyTicketLottery, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body,
  });

  if (response.status !== 200) {
    const responseError: {
      isError: boolean;
      msg: string;
    } = JSON.parse(yield response.text());

    console.error(responseError);
    yield put(
      modalActions.toggleError({
        msg: responseError.msg,
      })
    );
    return;
  }
  yield put(loadingActions.toggleLoading({}));
  yield put(
    modalActions.toggleActionModal({
      actionModalType: "confirmAction",
      caption: i18nextT("ticket.lottery.successLottery"),
      msg: "",
      btnMsg: i18nextT("ticket.lottery.checkLottery"),
      action: action.payload.actionOnModal,
      // @ts-expect-error TS2322
      args: null,
    })
  );
  return;
}

// @ts-expect-error TS7006
function* fetchEventTickets(action) {
  const { eventId } = action.payload;
  // onceで取得する
  const ref = "/tickets/" + eventId + "/tickets";
  // @ts-expect-error TS7057
  const list = yield fetchFirestoreCollection(ref);
  const map = convertArrayToDict(list);
  const data: any = { list, map };
  yield put(ticketActions.fetchEventTickets.done(data));
}

// @ts-expect-error TS7006
function* fetchEventGoods(action) {
  try {
    const params = {
      eventId: action.payload.eventId,
      domain: window.location.hostname, // Cache Busting for multi-domain CORS
    };
    // @ts-expect-error TS7057
    const response = yield fetch(
      appConfig.CloudFunctions.getSellingGoods +
        "?" +
        new URLSearchParams(params),
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log(response);

    // @ts-expect-error TS7057
    const json = yield response.json();
    if (response.status !== 200) {
      if (json.hasOwnProperty("errors")) {
        yield put(
          modalActions.toggleError({ msg: i18nextT("goods.fetch.error") })
        );
      } else {
        throw new Error(json);
      }
    } else {
      if (json.success) {
        // timestamp(millisecond)をfirestore.Timestampに変換する
        const list = json.data.map((item: ProductData) => {
          for (const key of Object.keys(item)) {
            if (
              ["releaseDateTime", "closeDateTime", "expiredDateTime"].includes(
                key
              )
            ) {
              // @ts-expect-error TS7053
              item[key] = firebase.firestore.Timestamp.fromMillis(item[key]);
            }
          }
          return item;
        });
        const map = convertArrayToDict(list);
        const data: any = { list, map };
        yield put(ticketActions.fetchEventGoods.done(data));
      }
    }
  } catch (e) {
    console.error(e);
    yield put(
      modalActions.toggleError({ msg: i18nextT("goods.fetch.occurredError") })
    );
  }
}

// @ts-expect-error TS7006
function* fetchProductConfig(action) {
  const { eventId } = action.payload;
  try {
    const ticketConfig: TicketConfig = yield fetchFirestoreDocument(
      "/tickets/" + eventId
    );
    const goodsConfig: GoodsConfig = yield fetchFirestoreDocument(
      "/goods/" + eventId
    );
    const config: any = { ticketConfig, goodsConfig };
    yield put(ticketActions.fetchProductConfig.done(config));
  } catch (error) {
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.lottery.missedInformation"),
      })
    );
  }
}

/**
 * バウチャー付与したチケットデータを返す
 * @param uid
 * @returns
 */
export const fetchUserVoucherTicketProducts = async (
  uid: string
): Promise<MyProductData[]> => {
  // fetch my voucher ticket. voucher products is supposed to ticket only.
  const userVoucherProductMap: {
    [key: string]: UserVoucherProduct;
  } = await fetchFirestoreCollectionGroupMap(`voucherOrders`, [
    "uid",
    "==",
    uid,
  ]);
  const userVoucherProducts = convertMapToValues(userVoucherProductMap);
  const ticketPromises = [];
  const ticketRefPaths: string[] = [];

  // add ticket refPath from user voucher products
  for (const p of userVoucherProducts) {
    for (const tid of Object.keys(p.products)) {
      ticketRefPaths.push(`tickets/${p.eid}/tickets/${tid}`);
    }
  }
  // fetch ticket data from ref path
  for (const refPath of unique(ticketRefPaths)) {
    ticketPromises.push(fetchFirestoreDocument(refPath));
  }
  const tickets = await Promise.all(ticketPromises);
  // @ts-expect-error TS2322
  const ticketDataMap: { [pid: string]: MyProductData } = convertArrayToDict(
    tickets.filter((el) => el)
  );

  const products: MyProductData[] = [];
  for (const voucherProduct of userVoucherProducts) {
    for (const pid of Object.keys(voucherProduct.products)) {
      const ticketData = ticketDataMap[pid];
      products.push({
        ...ticketData,
        productId: pid,
        // @ts-expect-error TS2322
        count: voucherProduct.products[pid],
        status: "PAYSUCCESS",
        purchasedAt: voucherProduct?.updatedAt?.toDate(),
        isVoucher: true,
      });
    }
  }
  return products;
};

/**
 * 共有されたチケットデータを返す
 * @param uid
 */
export const fetchUserSharedTicketProducts = async (
  uid: string
): Promise<MyProductData[]> => {
  console.log("fetchUserSharedTicketProducts");
  // 現時点で共有されたチケットはpurchasedTicketsしか存在しない
  const ticketRef = `purchasedTickets/${uid}`;
  const purchasedTicketMapByEventId = await fetchDatabaseValue(ticketRef);
  if (!purchasedTicketMapByEventId) return [];

  const sharedProducts: {
    productId: string;
    refPath: string;
    count: number;
  }[] = [];

  // 冗長だが、全イベントの購入情報を確認する
  for (const eid of Object.keys(purchasedTicketMapByEventId).filter(
    (el) => el !== "_id"
  )) {
    const purchasedTicketByTicketId: PurchasedTicket =
      // @ts-expect-error TS7053
      purchasedTicketMapByEventId[eid]?.tickets;

    if (!purchasedTicketByTicketId) continue;

    for (const tid of Object.keys(purchasedTicketByTicketId)) {
      const purchasedTicket = purchasedTicketByTicketId[tid];
      // check if ticket is issued or shared
      const hasIssuedTicket =
        // @ts-expect-error TS18048
        purchasedTicket.ticketNum && purchasedTicket.ticketNum.length !== 0;
      if (
        // @ts-expect-error TS18048
        (!purchasedTicket.orders ||
          // @ts-expect-error TS18048
          Object.keys(purchasedTicket.orders).length === 0) &&
        !hasIssuedTicket
      ) {
        // コンビニキャンセルの場合はordersプロパティは存在しないのでチェック / 共有チケットならスルー
        continue;
      }

      const successCount =
        // @ts-expect-error TS18048
        Number(purchasedTicket.checked) + Number(purchasedTicket.unchecked);
      let purchasedCount = 0;
      // @ts-expect-error TS18048
      if (typeof purchasedTicket.orders !== "undefined") {
        // @ts-expect-error TS18048
        for (const orderId of Object.keys(purchasedTicket.orders)) {
          const purchasedTicketOrder: PurchasedTicketOrder =
            // @ts-expect-error TS7015
            purchasedTicket.orders[orderId];
          purchasedCount += purchasedTicketOrder.count;
        }
      }

      // 保有数と購入数に差があれば、共有されたチケットとする
      const sharedTicketCount = successCount - purchasedCount;
      if (sharedTicketCount > 0) {
        // add ticket refPath from user shared products
        // refPath はreferenceから購入できる仕組みを導入したときに追加された。
        // firestoreのパスの`/`を`:`に置き換えたものが入っている。realtime databaseでは`/`は子要素を作成してしまうため。
        const refPath = purchasedTicket?.refPath
          ? purchasedTicket.refPath.replace(/:/g, "/")
          : `tickets/${eid}/tickets/${tid}`;

        sharedProducts.push({
          productId: tid,
          refPath,
          count: sharedTicketCount,
        });
      }
    }
  }

  // fetch ticket data from ref path
  const ticketPromises = [];
  for (const refPath of unique(sharedProducts.map((el) => el.refPath))) {
    ticketPromises.push(fetchFirestoreDocument(refPath));
  }
  const tickets = await Promise.all(ticketPromises);
  // @ts-expect-error TS2322
  const ticketDataMap: { [pid: string]: MyProductData } = convertArrayToDict(
    tickets.filter((el) => el)
  );

  const products: MyProductData[] = [];
  for (const { productId, count } of sharedProducts) {
    const ticketData = ticketDataMap[productId];
    // @ts-expect-error TS2345
    products.push({
      ...ticketData,
      productId,
      count,
      status: "PAYSUCCESS",
    });
  }
  return products;
};

/**
 * 注文データから商品データ毎のデータに分解する
 * @param orders
 * @returns
 */
export const getUserOrderProductsByOrder = (
  orders: ActiveTransaction[]
): MyProductData[] => {
  // @ts-expect-error TS2322
  const products: MyProductData[] = orders
    .map(({ status, orderId, products, updatedAt, payType }) => {
      // Venueは表示対象外とする ※ 未決済が残り続ける
      if (payType === "Venue") {
        return [];
      }
      // @ts-expect-error TS2345
      if (!["PAYSUCCESS", "UNPROCESS", "AUTHPROCESS"].includes(status)) {
        return [];
      }
      // @ts-expect-error TS18048
      return products.map((el) => {
        return {
          ...el,
          productId: el.ref.id,
          description: "",
          specification: "",
          status,
          orderId: String(orderId),
          // "updatedAt" を決済完了時刻にする
          purchasedAt: status === "PAYSUCCESS" ? updatedAt?.toDate() : null,
        };
      });
    })
    .flat();
  return products;
};

/**
 * @deprecated 本来、大元のsnapshotを更新すべきだが、延期等で視聴期限が意図せず更新される場合がある
 * 対応が間に合わないケースがあるため、マイチケットデータ内の指定したチケットの視聴期限に差し替える
 */
export const applyExpiredAtToMyTicketData = (
  myTicketData: MyEventTicketData[],
  products: ProductData[],
  now: Date
): MyEventTicketData[] => {
  const eventIds = products.map((e) => e.eid);
  for (const eid of unique(eventIds)) {
    const targetEventIndex = myTicketData.findIndex(
      (el) => el.event._id === eid
    );
    if (targetEventIndex !== -1) {
      // @ts-expect-error TS2339
      const { event, tickets } = myTicketData[targetEventIndex];
      const eventProducts = products.filter((el) => el.eid === event._id);
      // @ts-expect-error TS2532
      myTicketData[targetEventIndex].tickets = tickets.map((ticket) => {
        const [p] = eventProducts.filter((el) => el._id === ticket.productId);
        if (!p) return ticket;
        const { expiredDateTime, vodActiveHours } = p;
        const product = {
          ...ticket,
          expiredDateTime,
          vodActiveHours,
        };
        return setProductVideoExipredAt(product, event, now);
      });
    }
  }
  return myTicketData;
};

function* confirmMyOrder(fbToken: string) {
  // コンビニ決済待ちなどの更新用に叩く
  // @ts-expect-error TS2769
  const response = yield fetch(appConfig.CloudFunctions.confirmTran, {
    // let response = yield fetch("http://localhost:5000/bls-boost/us-central1/confirmTran", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
  });
  if (response.status !== 200) {
    console.error(response);
    throw new Error(response);
  }
}

function* fetchMyTickets(action: { payload: ReqFetchMyTickets }) {
  const { callConfirmTran } = action.payload;

  // 購入したチケットを取得
  const {
    auth: { user },
    ticket: { fetchingMyTickets },
  }: Store = yield select();
  const allSpan = span("fetchMyTickets:all").start(true);
  if ((!!user && (!user.uid || !user.emailVerified)) || fetchingMyTickets) {
    return;
  }
  try {
    const inFetching: any = { fetchingMyTickets: true };
    yield put(ticketActions.fetchMyTickets.done(inFetching));
    const fdTokenSpan = span("fetchMyTickets:fdToken").start();
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }
    fdTokenSpan.end();

    // 決済済と決済待ちの注文データを取得する
    const fetchMyOrdersSpan = span("fetchMyTickets:fetchMyOrders").start();
    const { paidOrders, unPaidOrders } = yield fetchMyOrders(user.uid);
    fetchMyOrdersSpan.end();

    // 決済待ちがあればconfirmTranを実行する
    if (callConfirmTran && unPaidOrders.length) {
      yield confirmMyOrder(fbToken);
    }

    // マイチケットに関連するデータを取得する
    const userOrderProducts = getUserOrderProductsByOrder(
      paidOrders.concat(unPaidOrders)
    );

    const myProductDataSpan = span("fetchMyTickets:MyProductData").start();
    const [
      userVoucherTicketProducts,
      userSharedTicketProducts,
    ]: MyProductData[][] = yield Promise.all([
      fetchUserVoucherTicketProducts(user.uid),
      fetchUserSharedTicketProducts(user.uid),
    ]);
    myProductDataSpan.end();

    const myProducts = [
      ...userOrderProducts,
      // @ts-expect-error TS2461
      ...userVoucherTicketProducts,
      // @ts-expect-error TS2461
      ...userSharedTicketProducts,
    ];

    // 商品に紐づくイベント情報を取得
    const eventIds = myProducts.map((e) => e.eid);
    const eventPromises = [];
    for (const eid of unique(eventIds)) {
      eventPromises.push(fetchFirestoreDocument(`events/${eid}`));
    }
    const myEventsSpan = span("fetchMyTickets:myEventsSpan").start();
    const myEvents: Event[] = yield Promise.all(eventPromises);
    const myEventMap = convertArrayToDict(myEvents.filter((el) => el));
    yield put(eventActions.getUserRelatedEventMap({ eventMap: myEventMap }));
    myEventsSpan.end();

    // マイチケットに対応する構造に変換
    const now = new Date();
    const myTicketData: MyEventTicketData[] = Object.values(myEventMap)
      .map((event) => createMyTicketProductData(event, myProducts, now))
      .filter((el) => el);

    /** @deprecated LIVEチケットのみ、getStreamingKeyと同じくマスターデータの視聴期限に差し替える（DEV側への作業軽減のため） */
    const ticketRefPaths = myProducts
      .filter((el) => el.streamingType === "Live")
      .map((e) => `tickets/${e.eid}/tickets/${e.productId}`) // fesチケット非対応
      .filter((el) => el);

    const ticketPromises = [];
    for (const refPath of unique(ticketRefPaths)) {
      ticketPromises.push(fetchFirestoreDocument(refPath));
    }

    const ticketMasterDataListSpan = span(
      "fetchMyTickets:ticketMasterDataList"
    ).start();
    const ticketMasterDataList: ProductData[] = yield Promise.all(
      ticketPromises
    );
    const _myTicketData = applyExpiredAtToMyTicketData(
      myTicketData,
      ticketMasterDataList.filter((el) => el),
      now
    );
    ticketMasterDataListSpan.end();

    const fetchTicketData: {
      myTicketData: ticketState["myTickets"];
      fetchingMyTickets: ticketState["fetchingMyTickets"];
    } = {
      myTicketData: _myTicketData,
      fetchingMyTickets: false,
    };
    const fetchMyTicketsDoneSpan = span(
      "fetchMyTickets:fetchMyTicketsDone"
    ).start();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(ticketActions.fetchMyTickets.done(fetchTicketData as any));
    yield put(ticketActions.fetchUnProcessData.done(unPaidOrders));
    yield put(streamingActions.createMyVideos.started(_myTicketData));
    fetchMyTicketsDoneSpan.end();
  } catch (e) {
    console.error(e);
    // ログインしていない場合は権限エラーなのでスルー
    const {
      auth: { user },
    } = yield select();
    if (user.uid && user.emailVerified) {
      if (e instanceof Error) errorReport(e);
      const msg = i18nextT("ticket.lottery.occurredError");
      yield put(modalActions.toggleError({ msg }));
    }
  } finally {
    const finishFetching: any = { fetchingMyTickets: false };
    yield put(ticketActions.fetchMyTickets.done(finishFetching));
    allSpan.end();
  }
}

export const setProductVideoExipredAt = (
  product: MyProductData,
  event: Event,
  now: Date
): MyProductData => {
  if (
    !(
      product.status === "PAYSUCCESS" &&
      // @ts-expect-error TS2345
      ["Live", "VOD"].includes(product.streamingType)
    )
  ) {
    return product;
  }
  // check if ticket is live streaming or vod ticket
  const vodExpiredAt = calcVODExpiredAt(
    product,
    // @ts-expect-error TS2345
    convertDateToTimestamp(product.purchasedAt)
  );
  // @ts-expect-error TS2322
  const labelStatus: TicketLabelStatus =
    // @ts-expect-error TS18047
    isOpenEventStreamingPage(event) && event.hasVOD && now < vodExpiredAt
      ? "vod"
      : null;
  return {
    ...product,
    // @ts-expect-error TS2322
    vodExpiredAt,
    labelStatus,
  };
};

export const createMyTicketProductData = (
  event: Event,
  products: MyProductData[],
  now: Date
): MyEventTicketData => {
  const eventProducts = products.filter((el) => el.eid === event?._id);
  // @ts-expect-error TS2322
  if (!eventProducts.length) return null;

  const eventProductsMap: Record<string, MyProductData[]> = {};
  for (const product of eventProducts) {
    if (!eventProductsMap[product.productId])
      eventProductsMap[product.productId] = [];
    // @ts-expect-error TS2532
    eventProductsMap[product.productId].push(product);
  }

  const myProducts: MyProductData[] = [];
  const unprocessOrderIds: string[] = [];
  for (const pid of Object.keys(eventProductsMap)) {
    const eventProducts = eventProductsMap[pid];
    // @ts-expect-error TS18048
    const purchasedProducts = eventProducts.filter(
      (el) => el.status === "PAYSUCCESS"
    );

    // 購入済みの場合は同じ商品をまとめる
    if (purchasedProducts.length) {
      // 価格毎に分け
      for (const price of unique(purchasedProducts.map((el) => el.price_jpy))) {
        const products = purchasedProducts.filter(
          (el) => el.price_jpy === price
        );
        // 商品の購入数を合算
        const count = products.reduce((a, b) => a + b.count, 0);
        // 最後に購入した商品情報
        const [_p] = products.sort((a, b) => {
          const aTime = a?.purchasedAt?.getTime() ?? 0;
          const bTime = b?.purchasedAt?.getTime() ?? 0;
          return bTime - aTime;
        });
        // @ts-expect-error TS2322
        const p: MyProductData = {
          ..._p,
          count,
        };
        // 配信に関するものは視聴期限を付与する
        // @ts-expect-error TS2345
        if (["Live", "VOD"].includes(p.streamingType)) {
          // snapshot だと 視聴期限延長の際に壊れるので注意
          // 必要に応じて applyExpiredAtToMyTicketData で上書きする
          myProducts.push(setProductVideoExipredAt(p, event, now));
        } else {
          myProducts.push(p);
        }
      }
    }

    // 未決済の場合は同じ商品でも分ける
    // @ts-expect-error TS18048
    const unPurchasedProducts = eventProducts.filter(
      (el) => el.status !== "PAYSUCCESS"
    );
    for (const p of unPurchasedProducts) {
      myProducts.push(p);
      if (p?.orderId) unprocessOrderIds.push(p.orderId);
    }
  }

  const myEventTickets: MyEventTicketData = {
    event,
    tickets: myProducts.filter((el) => el.productType === "ticket"),
    goods: myProducts.filter((el) => el.productType === "goods"),
    unprocessOrderIds: unique(unprocessOrderIds),
  };
  return myEventTickets;
};

/**
 * チケットもぎり チェックイン
 */
function* collectTicket(action: { payload: ReqCollectTicket }) {
  console.dir(action);
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("ticket.collect.loading") })
  );
  const msg = {
    eid: action.payload.eid,
    ticketNumber: action.payload.ticketNumber,
  };
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.collectTicket, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    if (response.status !== 200) {
      if (response.status === 403) {
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({
            msg: i18nextT("ticket.collect.loginError"),
          })
        );
        return;
      } else if (response.status === 500) {
        // server is busy
        // @ts-expect-error TS7057
        const json = yield response.json();
        yield put(loadingActions.toggleLoading({}));
        yield put(modalActions.toggleError({ msg: json.msg }));
      } else {
        console.error(response);
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({
            msg: i18nextT("ticket.collect.connectError"),
          })
        );
      }
    } else {
      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleNotice({
          msg: i18nextT("ticket.collect.successCollect"),
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.collect.occurredError"),
      })
    );
  }
}

/**
 * 特典引き換え
 */
function* redeemTicket(action: { payload: ReqCollectTicket }) {
  const redeemTarget = !action.payload.isGoods
    ? i18nextT("ticket.redeem.benefit")
    : i18nextT("ticket.redeem.goods");
  yield put(
    loadingActions.toggleLoading({
      msg: `${redeemTarget}${i18nextT("ticket.redeem.redeemMsg")}`,
    })
  );
  const msg = {
    eid: action.payload.eid,
    ticketNumber: action.payload.ticketNumber,
  };
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.redeemTicket, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    if (response.status !== 200) {
      if (response.status === 403) {
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({
            msg: i18nextT("ticket.redeem.loginError"),
          })
        );
        return;
      } else if (response.status === 500) {
        // server is busy
        // @ts-expect-error TS7057
        const json = yield response.json();
        yield put(loadingActions.toggleLoading({}));
        yield put(modalActions.toggleError({ msg: json.msg }));
      } else {
        console.error(response);
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({
            msg: i18nextT("ticket.redeem.connectError"),
          })
        );
      }
    } else {
      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleNotice({
          msg: `${redeemTarget}${i18nextT("ticket.redeem.successRedeem")}`,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.redeem.occurredError"),
      })
    );
  }
}

/**
 * 共有リンク生成
 */
// @ts-expect-error TS7006
function* shareTicket(action) {
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("ticket.share.generate") })
  );
  const msg = {
    eid: action.payload.eid,
    targetTicketNum: action.payload.ticketNumber,
  };
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS7057
    const response = yield fetch(
      // @ts-expect-error TS2769
      appConfig.CloudFunctions.generateTicketSharingKey,
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify(msg),
      }
    );
    if (response.status !== 200) {
      if (response.status === 403) {
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({ msg: i18nextT("ticket.share.loginError") })
        );
        return;
      } else if (response.status === 500) {
        // server is busy
        // @ts-expect-error TS7057
        const json = yield response.json();
        yield put(loadingActions.toggleLoading({}));
        yield put(modalActions.toggleError({ msg: json.msg }));
      } else {
        console.error(response);
        yield put(loadingActions.toggleLoading({}));
        yield put(
          modalActions.toggleError({
            msg: i18nextT("ticket.share.connectError"),
          })
        );
      }
    } else {
      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleNotice({
          msg: i18nextT("ticket.share.successShare"),
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.share.occurredError"),
      })
    );
  }
}

/**
 * 共有リンク生成
 */
// @ts-expect-error TS7006
function* receiveTicket(action) {
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("ticket.receive.loading") })
  );
  const msg = {
    eid: action.payload.eid,
    ticketNum: action.payload.ticketNum,
    tid: action.payload.tid,
    code: action.payload.code,
  };
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.receiveSharedTicket, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    // @ts-expect-error TS7057
    const json = yield response.json();
    if (response.status !== 200) {
      yield put(modalActions.toggleError({ msg: json.msg }));
    } else {
      // success
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "confirmTransition",
          trunk: { link: "/account/ticket" },
          caption: i18nextT("ticket.receive.success"),
          msg: i18nextT("ticket.receive.check"),
          btnMsg: i18nextT("ticket.receive.myTicket"),
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      modalActions.toggleError({
        msg: i18nextT("ticket.receive.occurredError"),
      })
    );
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

const hasMessage = (e: unknown): e is { message: string } =>
  // @ts-expect-error TS18047
  typeof e === "object" && "message" in e;

// @ts-expect-error TS7006
function* fetchEventTicketData(action) {
  try {
    const { eid } = action.payload;
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    if (!fbToken) {
      return;
    }
    const msg = { eid };
    let json = null;
    let json2 = null;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.getSellingTickets, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });

    let json2Error = null;
    let response2Status = null;

    try {
      const url = `${appConfig.backendApiUrl}/portal/python-functions/getSellingTickets`;
      // @ts-expect-error TS2769
      const response2 = yield fetchRetry(
        url,
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify(msg),
          credentials: "include",
        },
        3
      );

      response2Status = response2.status;

      // @ts-expect-error TS2769
      json2 = yield response2.json();

      console.debug("getSellingTicket");
      console.debug(json2);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        json2Error = e.message;
      }
    }

    if (response.status !== 200) {
      if (response.status === 403) {
        return;
      } else {
        // @ts-expect-error TS7057
        json = yield response.json();

        try {
          const url = `${appConfig.backendApiUrl}/portal/python-functions/diffLogger`;
          yield fetch(url, {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fbToken}`,
            },
            body: JSON.stringify({
              json,
              json2,
              // browserのcacheなどがあって、古いコードが走っている可能性があるので、versionを指定して、いつのコードがは知っているかを明示する
              meta: {
                name: "getSellingTicket",
                msg,
                response2Status,
                json2Error,
                version: "2",
              },
            }),
            credentials: "include",
          });
        } catch (e) {
          console.error(e);
        }

        if (json.hasOwnProperty("msg")) {
          yield put(
            modalActions.toggleError({ msg: i18nextT("ticket.fetch.error") })
          );
        } else {
          console.error(response);
          throw new Error(response);
        }
      }
    } else {
      // @ts-expect-error TS7057
      json = yield response.json();

      try {
        const url = `${appConfig.backendApiUrl}/portal/python-functions/diffLogger`;
        yield fetch(url, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify({
            json,
            json2,
            // browserのcacheなどがあって、古いコードが走っている可能性があるので、versionを指定して、いつのコードがは知っているかを明示する
            meta: {
              name: "getSellingTicket",
              msg,
              response2Status,
              json2Error,
              version: "2",
            },
          }),
          credentials: "include",
        });
      } catch (e) {
        console.error(e);
      }
    }
    if (json.isError) {
      throw new Error(json.msg);
    }
    json.ticketData = addKeyToObjectMap(json.ticketData);
    yield put(ticketActions.fetchEventTicketData.done(json));
  } catch (e) {
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        // @ts-expect-error TS2322
        msg: hasMessage(e) && e.message,
      },
    });
  }
}

function* issueTicketNumIfPossible(action: {
  payload: ReqIssueTicketNumIfPossible;
}) {
  try {
    const { eid, itemId, isGoods, orderId } = action.payload;
    if (isGoods) {
      const goodsConfig: GoodsConfig = yield fetchFirestoreDocument(
        `goods/${eid}`
      );
      if (!goodsConfig.allowSelfTnumGeneration) {
        return;
      }
    }
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    if (!fbToken) {
      return;
    }
    const msg = {
      eid,
      itemId,
      isGoods,
      orderId,
    };
    // console.log("Called", msg);
    // return
    let json = null;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.issueATicket, {
      // const response = yield fetch("http://127.0.0.1:8000/issue_a_ticket",{
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    //TODO わんちゃん文字列の"200"かもだからとりあえず
    // eslint-disable-next-line eqeqeq
    if (response.status == 200) {
      // json = yield response.json();
      return;
    } else {
      // @ts-expect-error TS7057
      json = yield response.json();
      throw new Error(json.msg);
    }
  } catch (e) {
    console.error(hasMessage(e) && e.message);
  }
}

/**
 * fetch survey result in event ticket page
 */
function* fetchEventTicketSurveyResult(action: {
  payload: ReqFetchEventTicketSurveyResult;
}) {
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken(true);
    if (!fbToken) {
      return;
    }
    // @ts-expect-error TS7057
    const response = yield fetch(
      // @ts-expect-error TS2769
      appConfig.CloudFunctions.fetchEventTicketSurveyResult,
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify({
          eventId: action.payload.eventId,
        }),
      }
    );
    if (response.status !== 200) {
      // pass when event has no survey.
      // @ts-expect-error TS7057
      const error = yield response.json();
      throw new Error(error.msg);
    }
    // @ts-expect-error TS7057
    const eventSurveyResult = yield response.json();
    // surveyResults is sorted values
    yield put(
      ticketActions.fetchEventTicketSurveyResult.done(
        eventSurveyResult["surveyResults"]
      )
    );
  } catch (error) {
    console.error(error);
  }
}
