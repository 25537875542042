/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { EventCard } from "../../EventCard";
import { FC } from "react";
import { useRecommendedEvents } from "./useRecommendedEvents";
import { useI18n } from "../../../hooks/i18n/i18n";

export const RecommendedEvents: FC<{
  onClickEventCard: () => void;
  type: "addedToCart" | "settlementCompleted" | "forceExit";
}> = ({ onClickEventCard, type }) => {
  const events = useRecommendedEvents();
  const { t } = useI18n();

  if (!events.data) {
    return null;
  }

  if (events.data.length <= 0) {
    return null;
  }

  return (
    <div
      css={css`
        margin-top: 36px;
        @media screen and (min-width: 768px) {
          margin-top: 40px;
        }
      `}
    >
      <div>
        <p
          css={css`
            width: fit-content;
            font-size: 14px;
            line-height: 1;
            font-weight: 700;
            @media screen and (min-width: 768px) {
              font-size: 20px;
              line-height: 1;
              font-weight: 600;
            }
          `}
        >
          {t("event.recommended.headline")}
        </p>
      </div>
      <div
        // 横幅を統一するために仕方なく .event-card のスタイルを上書きしている
        css={css`
          margin-top: 12px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 12px;
          & .event-card {
            width: 100%;
          }
          @media screen and (min-width: 480px) {
            & .event-card {
              width: calc(50% - 12px);
            }
          }
          @media screen and (min-width: 768px) {
            margin-top: 16px;
            justify-content: flex-start;
            gap: 20px;
            & .event-card {
              width: 100%;
              max-width: 320px;
            }
          }
        `}
      >
        {events.data.map((e) => (
          <EventCard
            key={e._id}
            event={e}
            action={onClickEventCard}
            recommendedFrom={type}
          />
        ))}
      </div>
    </div>
  );
};
