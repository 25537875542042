import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import { setFirestoreDocument } from "../utility/firebase";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import type {
  PickUpNewsData,
  UserNotification,
} from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("notification");

export const notificationActions = {
  clearStateByKey: actionCreator<keyof notificationState>("clearStateByKey"),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watchUserNotifications: actionCreator.async<void, any>(
    "watchUserNotifications"
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchPickUpNews: actionCreator.async<void, any>("fetchPickUpNews"),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markAsRead: actionCreator.async<ReqMarkAsRead, any>("markAsRead"),
};

export interface notificationState {
  notificationMap: { [templateId: string]: UserNotification };
  pickUpNews: PickUpNewsData;
}

const initialState: notificationState = {
  // @ts-expect-error TS2322
  notificationMap: null,
  pickUpNews: {
    msg: "",
    isActive: false,
    // @ts-expect-error TS2322
    open: null,
    // @ts-expect-error TS2322
    close: null,
  },
};

export type ReqMarkAsRead = {
  notificationId: string;
};

const notificationReducer = reducerWithInitialState(initialState)
  .case(
    notificationActions.clearStateByKey,
    (state, key: keyof notificationState) => {
      return { ...state, [key]: initialState[key] };
    }
  )
  .case(
    notificationActions.watchUserNotifications.done,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state, payload: any) => {
      return { ...state, notificationMap: payload };
    }
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(notificationActions.fetchPickUpNews.done, (state, payload: any) => {
    return { ...state, pickUpNews: payload };
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(notificationActions.markAsRead.done, (state, payload: any) => {
    return { ...state, notificationMap: payload };
  });

export default notificationReducer;

export function* notificationSaga() {
  yield takeEvery(
    notificationActions.watchUserNotifications.started,
    watchUserNotifications
  );
  yield takeEvery(notificationActions.fetchPickUpNews.started, fetchPickUpNews);
  yield takeEvery(notificationActions.markAsRead.started, markAsRead);
}

function* fetchPickUpNews() {
  try {
    const ref: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> =
      yield firebase.firestore().doc("news/pickUpNews/").get();
    const data = ref.data();

    if (
      data == null ||
      !data.isActive ||
      data.msg === undefined ||
      data.msg === null ||
      data.msg === "" ||
      data.open === null ||
      data.close === null ||
      data.open.seconds * 1000 >= Date.now() ||
      data.close.seconds * 1000 <= Date.now()
    ) {
      return;
    }

    yield put(notificationActions.fetchPickUpNews.done(data));
  } catch (e) {
    console.error(e);
  }
}

function notificationsChannel(userId: string) {
  const now = new Date();
  return eventChannel((emitter) => {
    // TODO@later イベント増えてきたら全件取るやり方はやめたい
    const unsubscribe = firebase
      .firestore()
      .collection(`/users/${userId}/notifications`)
      .where("display", "==", true)
      .where("endAt", ">", now)
      .onSnapshot(
        (snapshot) => {
          let map = {};
          snapshot.forEach((doc) => {
            map = { ...map, [doc.id]: { ...doc.data(), _id: doc.id } };
          });
          emitter(map);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

/**
 * fetch notifications with number limited
 */
function* watchUserNotifications() {
  const {
    auth: { user },
    firestore: { channels },
  } = yield select();
  if (!user || (user && !user.uid)) {
    return;
  }
  if (isRegisteredChannel(channels, "notifications")) return;
  try {
    // @ts-expect-error TS7057
    const channel = yield call(notificationsChannel, user.uid);
    while (true) {
      const data: { [id: string]: UserNotification } = yield take(channel);
      yield put(notificationActions.watchUserNotifications.done(data));
      yield put(
        firestoreActions.addChannel({ ...channel, name: "notifications" })
      );
    }
  } catch (e) {
    console.error(e);
  }
  // TODO@later if notificationMap is null, fetch 10
  // if notificationMap has any notifications, add 10
}

/**
 * mark as read notification
 */
function* markAsRead(action: { payload: ReqMarkAsRead }) {
  const {
    auth: { user },
  } = yield select();
  if (!user || (user && !user.uid)) {
    return;
  }
  const { notificationId } = action.payload;
  const docRef = "/users/" + user.uid + "/notifications/" + notificationId;
  const settingData = {
    hasRead: true,
    updatedAt: new Date(),
  };
  setFirestoreDocument(docRef, settingData);
}
