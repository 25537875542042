import { usePawFeatureFlag } from "constants/featureFlags";
import firebase from "firebase/app";
import { authActions } from "modules/auth";
import { purchaseActions } from "modules/purchase";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import useSWR from "swr";
import { createConnectClient } from "utility/connectWeb";

type ChargeData = {
  [key: string]: ChargeItem; // または [key: number]: ChargeItem; より厳密には、キーは数値文字列です。
};

type ChargeItem = {
  chargeType: "MEMBER" | "TOKEN";
  createAt: number;
  freeExpire: number;
  freeValue: number;
  paidExpire: number;
  paidValue: number;
  remainingFreeValue: number;
  remainingPaidValue: number;
  status: "CHARGE";
  updateAt: number;
};

export const FetchPawChargeHistoryKey = "fetchPawChargeHistory";

export const useChargeHistory = () => {
  /**
   * @todo envファイルに値を設定して、それを参照するようにする
   */
  const RELEASE_FLAG = usePawFeatureFlag();
  let end = Number.MAX_SAFE_INTEGER;
  const dispatch = useDispatch();
  const auth = firebase.auth();
  const connectClient = createConnectClient(auth);
  const pawHistoryContainer = useSelector(
    (state: Store) => state.purchase.pawHistoryContainer
  ) as ChargeData;

  useEffect(() => {
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );

    dispatch(purchaseActions.getPAWChargeHistory.started({ end: null }));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWChargeHistory.started,
        args: { end: null },
      })
    );
  }, [dispatch]);

  const reloadHistory = useCallback(() => {
    dispatch(purchaseActions.getPAWChargeHistory.started({ end }));
  }, [dispatch, end]);

  /**
   * @todo ここに新規APIの処理を追加する
   */
  const { data } = useSWR([FetchPawChargeHistoryKey], async () => {
    const userId = auth.currentUser?.uid;

    const response = await connectClient.fetchPawChargeHistory({
      userId: userId ?? "",
    });

    if (
      response.status === "SPWN_API_RESPONSE_UNSPECIFIED" ||
      response.status === "SPWN_API_RESPONSE_ERROR"
    ) {
      return {
        status: response.status,
      };
    }

    return {
      status: response.status,
      data: response.data,
    };
  });

  const newPawHistoryContainer = useMemo(
    () =>
      data?.data
        ?.map((history) => {
          const { chargedAt, value, validValue, expiresAt } = history;
          const status = calcStatus(Number(expiresAt), Number(validValue));
          return {
            chargedAt: Number(chargedAt),
            value: Number(value),
            expiresAt: Number(expiresAt),
            status,
          };
        })
        .sort((a, b) => {
          return -(a.chargedAt - b.chargedAt);
        }) ?? [],
    [data?.data]
  );

  if (!pawHistoryContainer || Object.keys(pawHistoryContainer).length === 0) {
    return {
      pawHistoryContainer: RELEASE_FLAG ? newPawHistoryContainer : [],
      reloadHistory,
    };
  }

  const oldPawHistoryContainer = Object.keys(pawHistoryContainer)
    .map((key) => {
      const {
        createAt,
        paidExpire,
        paidValue,
        remainingPaidValue: remaining,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } = pawHistoryContainer[key]!;

      const status = calcStatus(paidExpire, remaining);

      end = end < createAt ? end : createAt;

      return {
        chargedAt: createAt,
        value: paidValue,
        expiresAt: paidExpire,
        status,
      };
    })
    .sort((a, b) => {
      return -(a.chargedAt - b.chargedAt);
    });

  return {
    pawHistoryContainer: RELEASE_FLAG
      ? newPawHistoryContainer.concat(oldPawHistoryContainer)
      : oldPawHistoryContainer,
    reloadHistory,
  };
};

const calcStatus = (expiresAt: number, validValue: number) => {
  if (expiresAt < new Date().getTime()) {
    return "EXPIRED";
  } else if (validValue === 0) {
    return "USED";
  } else {
    return "CHARGE";
  }
};
