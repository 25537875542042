import React from "react";
import { useDispatch } from "react-redux";

import "firebase/firestore";
import "firebase/database";
import { modalActions } from "modules/modal";
import { useI18n } from "hooks/i18n/i18n";
import { Paw } from "../../styles";
import { useEmoBalance } from "hooks/paw/useEmoBalance";
import { useChargeHistory } from "hooks/paw/useChargeHistory";
import Box from "@material-ui/core/Box";
import { getCommaStyleNumber, getSlashSeparatedDateStyle } from "utility";
import { PawBanner } from "./PawBanner";
import { SubContentsLayout } from "ui/SubContentsLayout";
import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "react-responsive";

export const AccountPawChargeHistory: React.FC = () => {
  const { t } = useI18n();
  const isDesktopDisplay = useMediaQuery({ query: "(min-width: 768px)" });
  const dispatch = useDispatch();
  const { paidValue: pawBalance } = useEmoBalance();
  const { pawHistoryContainer, reloadHistory } = useChargeHistory();

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  return (
    <SubContentsLayout>
      <Box>
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: "ホーム", link: "/" },
            { label: t("common.routes.account"), link: "/account" },
            { label: t("common.routes.pawChargeHistory"), link: null },
          ]}
        />

        <PawBanner />

        {/* 現在の所持PAWを表示する */}
        <Box component={"section"} borderBottom={"1px solid #e6e5e6"}>
          <Box
            display={"grid"}
            gridTemplateColumns={"auto max-content"}
            alignItems={"center"}
            gridGap={"24px"}
            width={"90%"}
            maxWidth={"980px"}
            margin={"0 auto"}
            padding={"8px 0"}
          >
            <Box>
              <Box component={"h2"} fontSize={"14px"} marginBottom={"4px"}>
                {t("pawChargeHistory.ownedPAW")}
              </Box>
              <Paw>{getCommaStyleNumber(pawBalance)}</Paw>
            </Box>

            <Box>
              <Button
                type="button"
                aria-label={t("account.topUpPAW")}
                style={{
                  width: "100%",
                  maxWidth: "270px",
                  color: "#fff",
                  fontSize: isDesktopDisplay ? "14px" : "12px",
                  textAlign: "center",
                  padding: isDesktopDisplay ? "8px 64px" : "8px 32px",
                  backgroundColor: "#00c2ae",
                  borderRadius: "0px",
                }}
                onClick={togglePawChargeModal}
              >
                {t("account.topUpPAW")}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* PAWの購入履歴を表示する */}
        <Box width={"90%"} maxWidth={"980px"} margin={"0 auto"}>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr"}
            gridGap={"16px"}
            alignItems={"center"}
            padding={"40px 0"}
          >
            <Box component={"h2"} fontSize={"20px"}>
              {t("common.routes.pawChargeHistory")}
            </Box>
            <Box component={"p"} fontSize={"14px"}>
              {t("pawChargeHistory.note1")}
            </Box>
          </Box>

          <Box
            display={"grid"}
            gridTemplateColumns={["1fr 1fr 2fr", "1fr 1fr 1fr 1fr"]}
            gridGap={"16px"}
            alignItems={"center"}
            padding={["0px 4px 8px", "0px 16px 8px"]}
            fontSize={"14px"}
            fontWeight={"600"}
            borderBottom={"1px solid #000"}
          >
            <Box>{t("pawChargeHistory.dateOfPurchase")}</Box>
            <Box>{t("pawChargeHistory.expirationDate")}</Box>
            <Box display={["none", "block"]}></Box>
            <Box textAlign={"right"}>{t("pawChargeHistory.topedUpPAW")}</Box>
          </Box>

          {pawHistoryContainer.map((history) => {
            return (
              <Box
                key={history.chargedAt}
                display={"grid"}
                gridTemplateColumns={["1fr 1fr 2fr", "1fr 1fr 1fr 1fr"]}
                gridGap={"16px"}
                alignItems={"center"}
                padding={["8px", "8px 16px"]}
                color={history.status !== "CHARGE" ? "#a3a4a5" : "#000"}
                bgcolor={history.status !== "CHARGE" ? "#f8f8f8" : "#fff"}
                borderBottom={"1px solid #e6e5e6"}
              >
                <Box>{getSlashSeparatedDateStyle(history.chargedAt)}</Box>

                <Box>{getSlashSeparatedDateStyle(history.expiresAt)}</Box>

                <Box display={["none", "block"]} textAlign={"right"}>
                  {history.status !== "CHARGE" && (
                    <Box
                      component={"p"}
                      display={"inline-block"}
                      padding={"8px 16px"}
                      color={"#fff"}
                      fontSize={"12px"}
                      textAlign={"center"}
                      bgcolor={"#c5c5c5"}
                      borderRadius={"14px"}
                    >
                      {history.status === "USED" ? "使用済み" : "期限切れ"}
                    </Box>
                  )}
                </Box>

                <Box fontSize={"18px"} fontWeight={"600"} textAlign={"right"}>
                  {getCommaStyleNumber(history.value)}
                  <Box component={"span"} fontSize={"12px"} paddingLeft={"4px"}>
                    PAW
                  </Box>
                </Box>
              </Box>
            );
          })}

          {/* 本来ならページネーションが理想だが、一旦既存実装をそのまま使用する。 */}
          <Box
            onClick={reloadHistory}
            marginTop={"24px"}
            color={"#00c2ae"}
            fontSize={"14px"}
            textAlign={"center"}
          >
            <Box
              component={"span"}
              style={{
                cursor: "pointer",
              }}
            >
              {t("pawChargeHistory.readMore")}
            </Box>
          </Box>
        </Box>
      </Box>
    </SubContentsLayout>
  );
};
