import { md5 } from "js-md5";

// ABテストのグループ名のシード. ABテストを追加する際には、ABテストごとにtypeを追加する
type ABTestSeed = "AB_TEST_SIGNUP_BUTTON";

type ABTestGroup = "A_GROUP" | "B_GROUP" | undefined;

const getGACookie = () => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    cookie = cookie.trim();
    // _gaから始まるcookieを探す
    if (cookie.indexOf("_ga=") === 0) {
      return cookie.substring("_ga=".length, cookie.length);
    }
  }
  return undefined;
};

const getHexRemainder = (hexString: string): ABTestGroup => {
  // 最後の文字（最下位ビット）を取得
  const lastChar = hexString.charAt(hexString.length - 1).toLowerCase();

  // 最後の文字が偶数か奇数かをチェック
  const isOdd = ["1", "3", "5", "7", "9", "b", "d", "f"].includes(lastChar);

  // 奇数なら余りは1、偶数なら余りは0
  return isOdd ? "B_GROUP" : "A_GROUP";
};

const checkQueryParams = (): ABTestGroup => {
  const urlParams = new URLSearchParams(window.location.search);
  const abTestGroup = urlParams.get("ab_test_group");
  if (abTestGroup === "A_GROUP" || abTestGroup === "B_GROUP") {
    return abTestGroup;
  }
  return undefined;
};

export const enableABTestNewFeature = (
  abTestSeed: ABTestSeed
): boolean | undefined => {
  try {
    // URLパラメータにab_test_groupが指定されている場合は、その値で機能を出すかどうかを判定する
    const queryParamsGroup = checkQueryParams();
    if (queryParamsGroup !== undefined) {
      return queryParamsGroup === "B_GROUP";
    }

    const abTestGroup = getABTestGroup(abTestSeed);
    if (abTestGroup === undefined) {
      return undefined;
    }

    return abTestGroup === "B_GROUP";
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const getABTestGroup = (abTestSeed: ABTestSeed): ABTestGroup => {
  const pseudoUUID = getGACookie();
  if (pseudoUUID === undefined) {
    return undefined;
  }

  // ga1.2.1234567890.1234567890のような形式から,1234567890.1234567890の部分を取得
  const pseudoUUIDArray = pseudoUUID.split(".");
  if (pseudoUUIDArray.length !== 4) {
    return undefined;
  }
  const pseudoUUIDWithoutVersion = pseudoUUIDArray.slice(2).join(".");

  const hash = md5.create();
  hash.update(abTestSeed + pseudoUUIDWithoutVersion);
  const hexDigest = hash.hex();

  return getHexRemainder(hexDigest);
};
