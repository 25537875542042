import type { HostingType } from "@spwn/types/firebase/firestore";

/**
 * hostingTypeと今いるドメインのマッピング。これを利用して、ドメインによってスタイルを変更している。
 * 該当イベントが属するドメインにアクセスされなかった場合、１つ目に指定されたドメインにリダイレクトされる
 *
 * ローカル環境でスタイルを確認する際は、localhost を該当のhostingTypeの配列に含めてください。
 * ただしコミットはせずに、localhost は基本 portal-1 のままにしておいてください
 *
 * web-dev1.spwn.jp〜web-dev4.spwn.jpは基本的に変更せず、新規ドメイン追加の際はdev5〜dev10のいずれかを使用してください
 *
 * ドメインを新規追加する場合、GCP承認済みのリダイレクトURIにもドメインの追加を行う必要があるのでNotionを参照してください
 */
export const hostingMap: { [key in HostingType]: string[] } = {
  "portal-1": [
    "spwn.jp",
    "devspwn.net",
    "spwn-balus-stg1.spwn.jp",
    "web-dev1.spwn.jp", // 基本的に変更しない形で運用する
    "0222.spwn.jp",
    "localhost",
  ],
  vtuber1: [
    "virtual.spwn.jp",
    "dive.spwn.jp",
    "virtual.devspwn.net",
    "bls-boost.firebaseapp.com",
    "bls-boost.web.app",
    "spwn-balus.firebaseapp.com",
    "spwn-balus.web.app",
    "spwn-balus-stg1.web.app",
    "www.spwn.jp",
    "local-f0ff9f106b49.spwn.jp",
    "local-maru-3ffd7fd7f88e.spwn.jp",
    "local-nagata-d711cfc1b75a.spwn.jp",
    "web-dev2.spwn.jp", // 基本的に変更しない形で運用する
  ],
  event1: [
    "event.spwn.jp",
    "event.devspwn.net",
    "bls-boost-bqa6k4he83.web.app", // bls-boost
    "dev-brand-a.spwn.jp",
    "stg-brand-a.spwn.jp",
  ],
  webinar1: [
    // 'localhost',
  ],
  "774inc1": [
    "774inc.spwn.jp",
    "774inc.devspwn.net",
    "spwn-balus-stg1.web.app",
    "web-dev4.spwn.jp",
  ],
  php1: ["php-the21.spwn.jp", "php-the21.devspwn.net"],
  fent1: [
    "fent.spwn.jp",
    "fent.devspwn.net",
    "bls-boost-bqa6k4he83.firebaseapp.com",
    "spwn-balus-stg1.firebaseapp.com",
  ],
  "geki-keshigomu1": ["geki-keshigomu.spwn.jp", "geki-keshigomu.devspwn.net"],
  kioichostudio1: ["kioichostudio.spwn.jp", "kioichostudio.devspwn.net"],
  biz1: [
    "biz.spwn.jp",
    "biz.devspwn.net",
    "bls-4afa-9f55-77023d1c9655.firebaseapp.com",
  ],
  platto1: ["platto.spwn.jp", "platto.devspwn.net"],
  uchigeki1: ["uchigeki.spwn.jp", "uchigeki.devspwn.net"],
  gibiate1: ["gibiate.spwn.jp", "gibiate.devspwn.net"],
  "v-clan1": ["v-clan.spwn.jp", "v-clan.devspwn.net"],
  harevutai1: ["harevutai.spwn.jp", "harevutai.devspwn.net"],
  "akilab-1": ["akilab.spwn.jp", "akilab.devspwn.net"],
  "theater-academy-1": [
    "theater-academy.spwn.jp",
    "theater-academy.devspwn.net",
  ],
  "hiroki773-1": ["hiroki773.spwn.jp", "hiroki773.devspwn.net"],
  "mdl-1": ["mdl.spwn.jp", "mdl.devspwn.net"],
  "engekiichiba-1": ["engekiichiba.spwn.jp", "engekiichiba.devspwn.net"],
  "ufg-1": ["ufg.spwn.jp", "ufg.devspwn.net"],
  "tvf-1": ["tvf.spwn.jp", "tvf.devspwn.net"],
  "theatre-workshop-1": [
    "theatre-workshop.spwn.jp",
    "theatre-workshop.devspwn.net",
  ],
  "ultraman-1": ["ultraman.spwn.jp", "ultraman.devspwn.net"],
  "keytalk-1": ["keytalk.spwn.jp", "keytalk.devspwn.net"],
  "tif-1": ["tif.spwn.jp", "tif.devspwn.net"],
  "phantom-beats-opera-1": [
    "phantom-beats-opera.spwn.jp",
    "phantom-beats-opera.devspwn.net",
  ],
  "hadajin-shoten-1": ["hadajin-shoten.spwn.jp", "hadajin-shoten.devspwn.net"],
  "yam2020-1": ["yam2020.spwn.jp", "yam2020.devspwn.net"],
  "balus-1": ["balus.spwn.jp", "balus.devspwn.net"],
  "toukenranbu-1": [
    "stage-toukenranbu.spwn.jp",
    "stage-toukenranbu.devspwn.net",
    "bls-4afa-9f55-77023d1c9655.web.app",
  ],
  "BS12-1": ["bs12.spwn.jp", "bs12.devspwn.net"],
  "confac-1": ["confac.spwn.jp", "confac.devspwn.net"],
  "frankenstein-1": [
    "stage-frankenstein.spwn.jp",
    "stage-frankenstein.devspwn.net",
  ],
  "capsule-1": ["capsule.spwn.jp", "capsule.devspwn.net", "web-dev3.spwn.jp"], // 基本的に変更しない
  "capsule-50-1": ["capsule-50.spwn.jp", "capsule-50.devspwn.net"],
  "brand-1": ["jintomo.spwn.jp", "jintomo.devspwn.net"],
  "pokopea-1": ["pokopea.spwn.jp", "pokopea.devspwn.net", "web-dev5.spwn.jp"],
  "hama-kento-1": ["hama-kento.spwn.jp", "hama-kento.devspwn.net"],
  "straycity-1": ["stage-straycity.spwn.jp", "stage-straycity.devspwn.net"],
  "tsudaken-1": ["tsudaken.spwn.jp", "tsudaken.devspwn.net"],
  "watakon-stage-1": ["watakon-stage.spwn.jp", "watakon-stage.devspwn.net"],
  "themoney-1": ["themoney.spwn.jp", "themoney.devspwn.net"],
  "lemino-1": ["lemino.spwn.jp", "lemino.devspwn.net"],
  "virtuacul-1": [
    "virtuacul.spwn.jp",
    "virtuacul.devspwn.net",
    "web-dev6.spwn.jp",
  ],
  "terashima-junta-1": [
    "terashima-junta.spwn.jp",
    "terashima-junta.devspwn.net",
    "web-dev7.spwn.jp",
    // "localhost",
  ],
  "of-the-dead-1": [
    "of-the-dead.spwn.jp",
    "of-the-dead.devspwn.net",
    "web-dev8.spwn.jp",
  ],
  "kys-1": ["kys.spwn.jp", "kys.devspwn.net", "web-dev9.spwn.jp"],
};
