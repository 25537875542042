import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import appConfig from "../constants/appConfig";
import { GMO_INVALID_CARD_ERROR_CODES } from "../constants/gmo";
import {
  fetchFbToken,
  fetchFirestoreDocument,
  fetchFirestoreCollectionBySnapshotMap,
  fetchFirestoreCollection,
} from "../utility/firebase";
import { eventChannel } from "@redux-saga/core";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import { loadingActions } from "../modules/loading";
import { modalActions } from "../modules/modal";
import { getCardTokenGMO } from "../utility/gmo";
import { IsJsonString } from "../utility";
import { i18nextT } from "../hooks/i18n/i18n";
import { createEcommerceEventItemName, pushDataLayer } from "utility/ga";
import { errorReport } from "utility/logger";
import { v4 as uuidV4 } from "uuid";
import { Store } from "store";
import { generateErrorMessage } from "./error";
import type { FunctionError } from "@spwn/types/functions";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import {
  ActiveTransaction,
  UserCartProduct,
} from "@spwn/types/firebase/firestore";
import type { PawItemType } from "@spwn/types/firebase/database";
import { MyCartData } from "./cart";
import {
  callChargeCard,
  callChargeMember,
  callConfirmCharge,
  callUsePAW,
} from "./pawApi";

const actionCreator = actionCreatorFactory("event");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const purchaseActions = {
  clearStateByKey: actionCreator<keyof purchaseState>("clearStateByKey"),
  getEmoBalance: actionCreator.async<void, any>("getEmoBalance"),
  watchPawBalance: actionCreator.async<void, any>("watchPawBalance"),
  fetchCardInfo: actionCreator.async<any, any>("fetchCardInfo"), // 登録クレカ情報取得
  purchaseProductsWithCard: actionCreator.async<
    ReqPurchaseProductsWithCard,
    any
  >("purchaseProductsWithCard"),
  purchaseProductsWithMemId: actionCreator.async<
    ReqPurchaseProductsWithMember,
    any
  >("purchaseProductsWithMemId"),
  purchaseProductsWithCVS: actionCreator.async<ReqPurchaseProductsWithCVS, any>(
    "purchaseProductsWithCVS"
  ),
  purchaseProductsWithPhone: actionCreator.async<
    ReqPurchaseProductsWithPhone,
    any
  >("purchaseProductsWithPhone"),
  purchaseProductsWithFree: actionCreator.async<
    ReqPurchaseProductsWithFree,
    any
  >("purchaseProductsWithFree"),
  getPAWChargeHistory: actionCreator.async<{ end: number | null }, any>(
    "getPAWChargeHistory"
  ),
  getPAWUsageHistory:
    actionCreator.async<{ end: number | null }, any>("getPAWUsageHistory"),
  getPAWItemList: actionCreator.async<void, any>("getPAWItemList"),
  toggleRegisterCard: actionCreator<void>("toggleRegisterCard"),
  registerCardInfo: actionCreator.async<any, any>("registerCardInfo"),
  removeRegisteredCardInfo: actionCreator.async<any, any>(
    "removeRegisteredCardInfo"
  ),
  chargeWithMemId: actionCreator.async<any, any>("chargeWithMemId"),
  chargeWithCard: actionCreator.async<any, any>("chargeWithCard"),
  usePAW: actionCreator.async<ReqUsePAW, any>("usePAW"),
  togglePawSettlement: actionCreator<any>("togglePawSettlement"),
  fetchUserActiveTransactions: actionCreator.async<void, any>(
    "fetchUserActiveTransactions"
  ),
  setIsPurchasing: actionCreator<boolean>("setIsPurchasing"),
  displayCompletionScreen: actionCreator.async<void, any>(
    "displayCompletionScreen"
  ),
  refreshFingerprint: actionCreator<void>("refreshFingerprint"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface purchaseState {
  emoBalance: PawBalance;
  isBalanceInWatching: boolean;
  cardInfoList: null | CardInfo[];
  pawHistoryContainer: {};
  pawUsageHistoryContainer: {};
  pawItemList: unknown;
  isOpenRegisterCard: boolean;
  pawPurchase: {
    isOpenPawSettlement: boolean;
    price: number;
    pawId: string;
  };
  activeTransactionMap: { [orderId: string]: ActiveTransaction };
  isPurchasing: boolean;
  isSuccessUsePAW: boolean;
  isPurchaseComplete: boolean;
  fingerprint: string;
}
export type CardInfo = {
  cardNumber: string;
  cardExpireMonth: string;
  cardExpireYear: string;
  cardHolderName: string;
  cardSecurityCode?: string;
  cardSeq: string;
};
// api
export type ReqPurchaseProductsWithCard = {
  paymentInfo: {
    eid: string;
    cardNumber: string;
    cardExpireYear: string;
    cardExpireMonth: string;
    cardSecurityCode: string;
    cardHolderName: string;
    displayPrice: number;
  };
  cartProducts?: {
    id: string;
  }[];
  /**
   * GTMに送る情報用
   */
  gtm: {
    myCarts: MyCartData[];
    totalPrice: number;
    shippingFee?: number;
  };
};
export type ReqPurchaseProductsWithMember = {
  paymentInfo: {
    eid: string;
    cardSecurityCode: string;
    cardSeq: string;
    displayPrice: number;
  };
  cartProducts?: {
    id: string;
  }[];
  /**
   * GTMに送る情報用
   */
  gtm: {
    myCarts: MyCartData[];
    totalPrice: number;
    shippingFee?: number;
  };
};
export type ReqPurchaseProductsWithCVS = {
  paymentInfo: {
    eid: string;
    cvesCode: CVSCode;
    customerName: string;
    customerKana: string;
    telNo: string;
    displayPrice: number;
  };
  cartProducts?: {
    id: string;
  }[];
  /**
   * GTMに送る情報用
   */
  gtm: {
    myCarts: MyCartData[];
    totalPrice: number;
    shippingFee?: number;
  };
};
export type ReqPurchaseProductsWithPhone = {
  paymentInfo: {
    eid: string;
    phoneCode: PhoneCode;
    displayPrice: number;
  };
  cartProducts?: {
    id: string;
  }[];
  /**
   * GTMに送る情報用
   */
  gtm: {
    myCarts: MyCartData[];
    totalPrice: number;
    shippingFee?: number;
  };
};
export type ReqPurchaseProductsWithFree = {
  paymentInfo: {
    eid: string;
    displayPrice: number;
  };
  cartProducts?: {
    id: string;
  }[];
  /**
   * GTMに送る情報用
   */
  gtm: {
    myCarts: MyCartData[];
    totalPrice: number;
    shippingFee?: number;
  };
};
export interface ReqUsePAW {
  suppressMsg?: boolean;
  eid: string;
  itemId: string;
  freePrice: number;
  paidPrice: number;
  count?: number;
  type?: PawItemType;
  orderId?: string;
  giftPlaces?: string[];
  displayValue?: number;
}
export const CVSNames: { [key in CVSCode]: string } = {
  "10001": "ローソン",
  "10002": "ファミリーマート",
  "10008": "セイコーマート",
};
export type PaymentMethodForLottery = "MEM" | "CVS" | "FREE";
export type PawBalance = {
  freeValue: number;
  paidValue: number; // こっち使う
};

const initialState: purchaseState = {
  emoBalance: {
    // @ts-expect-error TS2322
    freeValue: undefined,
    // @ts-expect-error TS2322
    paidValue: undefined,
  },
  isBalanceInWatching: false,
  cardInfoList: null,
  pawHistoryContainer: {},
  pawUsageHistoryContainer: {},
  pawItemList: {},
  isOpenRegisterCard: false,
  pawPurchase: {
    isOpenPawSettlement: false,
    // @ts-expect-error TS2322
    price: null,
    pawId: "",
  },
  // @ts-expect-error TS2322
  activeTransactionMap: null,
  isPurchasing: false,
  isSuccessUsePAW: false,
  // 処理を完了したら完了画面を表示するために必要？？
  // モーダルはできればなくしていきたいので、フロント側でfalseからtrueに変わる間、ローダーを表示って感じがいいのでは？？
  isPurchaseComplete: false,
  fingerprint: uuidV4(),
};

const hasMessage = (e: unknown): e is { message: string } =>
  // @ts-expect-error TS18047
  typeof e === "object" && "message" in e;

/* eslint-disable @typescript-eslint/no-explicit-any */
const purchaseReducer = reducerWithInitialState(initialState)
  .case(purchaseActions.clearStateByKey, (state, key: keyof purchaseState) => {
    return { ...state, [key]: initialState[key] };
  })
  .case(purchaseActions.getEmoBalance.done, (state, payload: any) => {
    return { ...state, emoBalance: payload };
  })
  .case(purchaseActions.watchPawBalance.done, (state, payload: any) => {
    const emoBalance = { freeValue: 0, paidValue: payload };
    return { ...state, emoBalance };
  })
  .case(purchaseActions.fetchCardInfo.done, (state, payload: any) => {
    return { ...state, cardInfoList: payload.params };
  })
  .case(purchaseActions.purchaseProductsWithCard.done, (state, _payload) => {
    return { ...state, myCart: [] };
  })
  .case(purchaseActions.purchaseProductsWithMemId.done, (state, _payload) => {
    return { ...state, myCart: [] };
  })
  .case(purchaseActions.purchaseProductsWithCVS.done, (state, _payload) => {
    return { ...state, myCart: [] };
  })
  .case(purchaseActions.purchaseProductsWithPhone.done, (state, _payload) => {
    return { ...state, myCart: [] };
  })
  .case(purchaseActions.getPAWChargeHistory.done, (state, history) => {
    return {
      ...state,
      pawHistoryContainer: {
        ...state.pawHistoryContainer,
        ...history,
      },
    };
  })
  .case(purchaseActions.getPAWUsageHistory.done, (state, history) => {
    return {
      ...state,
      pawUsageHistoryContainer: {
        ...state.pawUsageHistoryContainer,
        ...history,
      },
    };
  })
  .case(purchaseActions.getPAWItemList.done, (state, items) => {
    return {
      ...state,
      pawItemList: items,
    };
  })
  .case(purchaseActions.toggleRegisterCard, (state, _payload) => {
    return { ...state, isOpenRegisterCard: !state.isOpenRegisterCard };
  })
  .case(purchaseActions.togglePawSettlement, (state, payload) => {
    const isOpen = !!payload && Object.keys(payload).length !== 0;
    const pawPurchase = {
      isOpenPawSettlement: isOpen,
      price: isOpen ? payload.price : null,
      pawId: isOpen ? payload.pawId : "",
    };
    return { ...state, pawPurchase };
  })
  .case(
    purchaseActions.fetchUserActiveTransactions.done,
    (state, payload: any) => {
      return { ...state, activeTransactionMap: payload };
    }
  )
  .case(purchaseActions.usePAW.done, (state, payload: any) => {
    return { ...state, isSuccessUsePAW: payload };
  })
  .case(purchaseActions.setIsPurchasing, (state, payload: any) => {
    return { ...state, isPurchasing: payload };
  })
  .case(
    purchaseActions.displayCompletionScreen.done,
    (state, _payload: any) => {
      return { ...state, isPurchaseComplete: true };
    }
  )
  .case(purchaseActions.refreshFingerprint, (state) => {
    return { ...state, fingerprint: uuidV4() };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default purchaseReducer;

export function* purchaseSaga() {
  yield takeEvery(purchaseActions.getEmoBalance.started, getEmoBalance);
  yield takeEvery(purchaseActions.watchPawBalance.started, watchPawBalance);
  yield takeEvery(purchaseActions.fetchCardInfo.started, fetchCardInfo);
  yield takeEvery(
    purchaseActions.purchaseProductsWithCard.started,
    purchaseProductsWithCard
  );
  yield takeEvery(
    purchaseActions.purchaseProductsWithMemId.started,
    purchaseProductsWithMemId
  );
  yield takeEvery(
    purchaseActions.purchaseProductsWithCVS.started,
    purchaseProductsWithCVS
  );
  yield takeEvery(
    purchaseActions.purchaseProductsWithPhone.started,
    purchaseProductsWithPhone
  );
  yield takeEvery(
    purchaseActions.purchaseProductsWithFree.started,
    purchaseProductsWithFree
  );
  yield takeEvery(
    purchaseActions.getPAWChargeHistory.started,
    getPAWChargeHistory
  );
  yield takeEvery(
    purchaseActions.getPAWUsageHistory.started,
    getPAWUsageHistory
  );
  yield takeEvery(purchaseActions.getPAWItemList.started, getPAWItemList);
  yield takeEvery(purchaseActions.registerCardInfo.started, registerCardInfo);
  yield takeEvery(
    purchaseActions.removeRegisteredCardInfo.started,
    removeRegisteredCardInfo
  );
  yield takeEvery(purchaseActions.chargeWithMemId.started, chargeWithMemId);
  yield takeEvery(purchaseActions.chargeWithCard.started, chargeWithCard);
  yield takeEvery(purchaseActions.usePAW.started, usePAW);
  yield takeEvery(
    purchaseActions.fetchUserActiveTransactions.started,
    fetchUserActiveTransactions
  );
  yield takeEvery(
    purchaseActions.displayCompletionScreen.started,
    displayCompletionScreen
  );
}

function* usePAW(action: { payload: ReqUsePAW }) {
  const error = new Error();
  try {
    if (!action.payload.suppressMsg)
      yield put(
        loadingActions.toggleLoading({
          msg: i18nextT("purchase.purchase.purchase"),
        })
      );
    const msg = {
      eid: action.payload.eid,
      itemId: action.payload.itemId,
      freePrice: action.payload.freePrice,
      paidPrice: action.payload.paidPrice,
      count: !action.payload.count ? 1 : action.payload.count,
      orderId: !action.payload.orderId ? null : action.payload.orderId, // only flower stand
      giftPlaces: !action.payload.giftPlaces ? null : action.payload.giftPlaces, // only flower stand
      displayValue: !action.payload.displayValue
        ? null
        : action.payload.displayValue, // only flower stand
    };
    const response: Response = yield callUsePAW(msg);
    if (response.status !== 200) {
      if (response.status === 403) error.message = yield response.text();
      console.error(response);
      // @ts-expect-error TS7057
      const text = yield response.text();
      if (IsJsonString(text)) {
        console.log(`HTTP ${response.status}: ${text}`);
        if (!action.payload.suppressMsg) {
          error.message = `${i18nextT("purchase.purchase.failPurchase")}${
            response.status
          }: ${text}`;
        }
      } else {
        console.log(`HTTP ${response.status}: ${text}`);
        if (!action.payload.suppressMsg) {
          error.message = `${i18nextT("purchase.purchase.failPurchase")} ${
            response.status
          }: ${text}`;
        }
      }
      throw error;
    } else {
      yield put(purchaseActions.getEmoBalance.started());
      yield put(loadingActions.toggleLoading({}));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(purchaseActions.usePAW.done(true as any));
      if (!action.payload.suppressMsg)
        yield put(
          modalActions.toggleNotice({
            msg: i18nextT("purchase.purchase.purchased"),
          })
        );
    }
  } catch (e) {
    // if failed to purchase flower stand, disable purchased data
    // @ts-expect-error TS2339
    const { uid } = firebase.auth().currentUser;
    if (uid && action.payload.type === "flowerStand") {
      const { eid, itemId, orderId, count } = action.payload;
      const ref = `/users/${uid}/events/${eid}/purchasedFlowerStands/${itemId}`;
      // @ts-expect-error TS7057
      const disableTarget = yield fetchFirestoreDocument(ref);
      if (disableTarget) {
        const data = {
          totalCount: firebase.firestore.FieldValue.increment(-Number(count)),
          orders: {
            ...disableTarget.orders,
            // @ts-expect-error TS2464
            [orderId]: {
              // @ts-expect-error TS2538
              ...disableTarget.orders[orderId],
              purchaseFailed: true,
            },
          },
        };
        yield firebase.firestore().doc(ref).set(data, { merge: true });
      }
    }

    yield put(loadingActions.toggleLoading({}));
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

// @ts-expect-error TS7006
function* chargeWithCard(action) {
  try {
    yield put(
      loadingActions.toggleLoading({
        msg: i18nextT("purchase.purchase.chargePAW"),
      })
    );
    const response: Response = yield callChargeCard(action.payload);
    console.log(response);
    if (response.status !== 200) {
      if (response.status === 403) throw new Error(yield response.text());
      console.error(response);
      // @ts-expect-error TS7057
      const text = yield response.text();
      if (IsJsonString(text)) {
        // FIXME
        // eslint-disable-next-line no-constant-condition
        if (false) {
          // TODO: Add PAW charge related errors
          yield put(
            modalActions.toggleError({
              msg: `PAWチャージに失敗しました`,
            })
          );
          return;
        } else {
          console.log(`HTTP ${response.status}: ${text}`);
          yield put(
            modalActions.toggleError({
              msg: `${i18nextT("purchase.purchase.failPurchase")} ${
                response.status
              }: ${text}`,
            })
          );
          return;
        }
      } else {
        console.log(`HTTP ${response.status}: ${text}`);
        yield put(
          modalActions.toggleError({
            msg: `${i18nextT("purchase.purchase.failPurchase")} ${
              response.status
            }: ${text}`,
          })
        );
        return;
      }
    } else {
      yield put(purchaseActions.getEmoBalance.started());
      yield put(
        modalActions.toggleNotice({
          msg: i18nextT("purchase.purchase.succsessPAWcharge"),
        })
      );
      // paw決済モーダルを閉じる
      yield put(purchaseActions.togglePawSettlement({}));
    }
  } catch (e) {
    if (!hasMessage(e)) return;
    console.log(e.message);
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

// @ts-expect-error TS7006
function* chargeWithMemId(action) {
  try {
    // @ts-expect-error TS7057
    const state = yield select();
    yield put(
      loadingActions.toggleLoading({
        msg: i18nextT("purchase.purchase.chargePAW"),
      })
    );
    const msg = {
      price: action.payload.price,
      productId: String(action.payload.productId),
      securityCode: action.payload.securityCode,
      cardSeq:
        state.purchase.cardInfoList[action.payload.selectedCardIdx].cardSeq,
    };
    const response: Response = yield callChargeMember(msg);
    console.log(response);
    if (response.status !== 200) {
      if (response.status === 403) throw new Error(yield response.text());
      console.error(response);
      // @ts-expect-error TS7057
      const text = yield response.text();
      if (IsJsonString(text)) {
        // FIXME
        // eslint-disable-next-line no-constant-condition
        if (false) {
          // TODO: Add PAW charge related errors
          yield put(
            modalActions.toggleError({
              msg: `PAWチャージに失敗しました`,
            })
          );
          return;
        } else {
          console.log(`HTTP ${response.status}: ${text}`);
          yield put(
            modalActions.toggleError({
              msg: `${i18nextT("purchase.purchase.failPurchase")} ${
                response.status
              }: ${text}`,
            })
          );
          return;
        }
      } else {
        console.log(`HTTP ${response.status}: ${text}`);
        yield put(
          modalActions.toggleError({
            msg: `${i18nextT("purchase.purchase.failPurchase")} ${
              response.status
            }: ${text}`,
          })
        );
        return;
      }
    } else {
      yield put(purchaseActions.getEmoBalance.started());
      yield put(
        modalActions.toggleNotice({
          msg: i18nextT("purchase.purchase.succsessPAWcharge"),
        })
      );
      // paw決済モーダルを閉じる
      yield put(purchaseActions.togglePawSettlement({}));
    }
  } catch (e) {
    if (!hasMessage(e)) return;
    console.log(e.message);
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

// @ts-expect-error TS7006
function* removeRegisteredCardInfo(action) {
  try {
    yield put(
      loadingActions.toggleLoading({
        msg: i18nextT("purchase.purchase.remove"),
      })
    );
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    // @ts-expect-error TS7057
    const state = yield select();

    // @ts-expect-error TS7057
    const response = yield fetch(
      `${appConfig.PaymentSystem.paymentRegistration}?cardSeq=${
        state.purchase.cardInfoList[action.payload.targetIdx].cardSeq
      }`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `'Bearer ${fbToken}`,
        },
      }
    );

    if (response.status !== 200) {
      if (response.status === 403) throw new Error(yield response.text());
      console.error(response);
      // @ts-expect-error TS7057
      const text = yield response.text();
      console.log(`HTTP ${response.status}: ${text}`);
      yield put(
        modalActions.toggleError({
          msg: `${i18nextT("purchase.purchase.failRemove")} ${
            response.status
          }: ${text}`,
        })
      );
      return;
    }
    yield put(purchaseActions.fetchCardInfo.started({}));
    yield put(
      modalActions.toggleNotice({ msg: i18nextT("purchase.purchase.removed") })
    );
  } catch (e) {
    if (!hasMessage(e)) return;
    console.log(e.message);
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

// @ts-expect-error TS7006
function* registerCardInfo(action) {
  try {
    yield put(
      loadingActions.toggleLoading({
        msg: i18nextT("purchase.purchase.regist"),
      })
    );
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    const { token } = action.payload;
    const msg = {
      token,
    };
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.paymentRegistration, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: Object.keys(msg)
        // @ts-expect-error TS7053
        .map((key) => key + "=" + encodeURIComponent(msg[key]))
        .join("&"),
    });
    if (response.status !== 200) {
      if (response.status === 403) throw new Error(yield response.text());
      console.error(response);
      // @ts-expect-error TS7057
      const text = yield response.text();
      if (IsJsonString(text)) {
        const json = JSON.parse(text);
        if (
          json.hasOwnProperty("errors") &&
          json.errors.hasOwnProperty("type") &&
          json.errors.type === "API_ERROR" &&
          json.errors.hasOwnProperty("info") &&
          json.errors.info.length > 0 &&
          json.errors.info[0].code === "E01230009"
        ) {
          yield put(
            modalActions.toggleError({
              msg: i18nextT("purchase.purchase.overCredit"),
            })
          );
          return;
        } else {
          throw new Error(
            `${i18nextT("purchase.purchase.failRegist")} ${
              response.status
            }: ${text}`
          );
        }
      } else {
        throw new Error(
          `${i18nextT("purchase.purchase.failRegist")} ${
            response.status
          }: ${text}`
        );
      }
    }
    yield put(purchaseActions.fetchCardInfo.started({}));
    yield put(
      modalActions.toggleNotice({ msg: i18nextT("purchase.purchase.registed") })
    );
  } catch (e) {
    if (!hasMessage(e)) return;
    console.log(e.message);
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: i18nextT("purchase.purchase.failRegist"),
      },
    });
    throw e;
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* getPAWItemList() {
  try {
    // @ts-expect-error TS2339
    const { uid } = firebase.auth().currentUser;
    console.log(uid);
    // @ts-expect-error TS7057
    const snapshot = yield firebase.database().ref(`/items`).once("value");
    const items = snapshot.val();

    yield put(purchaseActions.getPAWItemList.done(items));
  } catch (e) {
    console.error(e);
    modalActions.toggleError({
      msg: i18nextT("purchase.purchase.occuredError"),
    });
  }
}

// @ts-expect-error TS7006
function* getPAWUsageHistory(action) {
  try {
    // @ts-expect-error TS2339
    const { uid } = firebase.auth().currentUser;
    let snapshot;
    if (action.payload.end === null) {
      // @ts-expect-error TS7057
      snapshot = yield firebase
        .database()
        .ref(`/usageLogs/${uid}/dates`)
        // .orderByKey()
        // .limitToLast(2)
        .once("value");
    } else {
      // @ts-expect-error TS7057
      snapshot = yield firebase
        .database()
        .ref(`/usageLogs/${uid}/dates`)
        .orderByKey()
        // .endAt(action.payload.end)
        // .limitToLast(5)
        .once("value");
    }
    const history = snapshot.val();

    yield put(purchaseActions.getPAWUsageHistory.done(history));
  } catch (e) {
    console.error(e);
    modalActions.toggleError({
      msg: i18nextT("purchase.purchase.occuredError"),
    });
  }
}

// @ts-expect-error TS7006
function* getPAWChargeHistory(action) {
  try {
    // @ts-expect-error TS2339
    const { uid } = firebase.auth().currentUser;
    let snapshot;
    if (action.payload.end === null) {
      // @ts-expect-error TS7057
      snapshot = yield firebase
        .database()
        .ref(`/values/${uid}/chargeHistory`)
        .orderByChild("createAt")
        .limitToLast(10)
        .once("value");
    } else {
      // @ts-expect-error TS7057
      snapshot = yield firebase
        .database()
        .ref(`/values/${uid}/chargeHistory`)
        .orderByChild("createAt")
        .endAt(action.payload.end)
        .limitToLast(5)
        .once("value");
    }
    const history = snapshot.val();

    yield put(purchaseActions.getPAWChargeHistory.done(history));
  } catch (e) {
    console.error(e);
    modalActions.toggleError({
      msg: i18nextT("purchase.purchase.occuredError"),
    });
  }
}

function* getEmoBalance() {
  try {
    const response: Response = yield callConfirmCharge();
    // @ts-expect-error TS7057
    const json = yield response.json();
    yield put(purchaseActions.getEmoBalance.done(json));
  } catch (e) {
    console.error(e);
  }
}

function* fetchCardInfo() {
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.paymentRegistration, {
      method: "GET",
      headers: { Authorization: `'Bearer ${fbToken}` },
    });
    // @ts-expect-error TS7057
    const jsonRes = yield response.json();
    const isError = response.status !== 200;
    if (isError) {
      switch (response.status) {
        case 400:
          // HOTFIX: 無理やりなのでエラーハンドリング設計したい
          // カード未登録の場合はエラーポップアップが出ないように。
          if (
            jsonRes.errors.type === "GMO_ID_NOTFOUND" || // gmoに登録されていない
            (jsonRes.errors.type === "API_ERROR" &&
              jsonRes.errors.info[0].code === "E01240002")
          ) {
            // カードが登録されていない
            break;
          }
          yield put(
            modalActions.toggleError({
              msg: i18nextT("purchase.purchase.unexpectedError"),
            })
          );
          break;
        case 403:
          // yield put(modalActions.toggleError({ caption: "メールアドレス認証を行ってください" }))
          // yield put(modalActions.toggleError({ msg: response.text() }))
          // errorMsg = "カード情報の取得に失敗しました<br/>メールアドレス認証を行い、もう一度やり直してください"
          break;
        default:
          yield put(
            modalActions.toggleError({
              msg: i18nextT("purchase.purchase.unexpectedError"),
            })
          );
          break;
      }
    }
    const cardInfoList = [];
    if (!isError) {
      for (const cardInfo of jsonRes) {
        cardInfoList.push({
          cardNumber: cardInfo.cardNo,
          cardExpireMonth: cardInfo.expire.slice(-2),
          cardExpireYear: cardInfo.expire.slice(0, 2),
          cardHolderName: cardInfo.holderName,
          cardSeq: cardInfo.cardSeq,
        });
      }
    }
    // { params: } の形出ないとエラー出るので暫定
    yield put(purchaseActions.fetchCardInfo.done({ params: cardInfoList }));
  } catch (e) {
    console.warn(hasMessage(e) && e.message);
  }
}

function pawBalanceChannel(uid: string) {
  return eventChannel((emitter) => {
    const messageRef = firebase.database().ref(`/values/${uid}`);
    const snapshot = messageRef.on("child_changed", (_snapshot) => {
      // 変更があったら取得
      firebase
        .database()
        .ref(`/values/${uid}/chargeHistory`)
        .orderByChild("remainingPaidValue")
        .startAt(1)
        .once("value")
        .then((snapShot) => {
          let paidValue = 0;
          snapShot.forEach((data) => {
            paidValue += data.val().remainingPaidValue;
          });
          emitter(paidValue);
        });
    });
    const unsubscribe = () => {
      messageRef.off("child_changed", snapshot);
    };
    return unsubscribe;
  });
}

function* watchPawBalance() {
  const {
    auth,
    firestore: { channels },
  } = yield select();
  if (isRegisteredChannel(channels, "pawBalance")) return;

  if (Object.keys(auth.user).length === 0) {
    return [];
  }
  try {
    // @ts-expect-error TS7057
    const channel = yield call(pawBalanceChannel, auth.user.uid);
    yield put(firestoreActions.addChannel({ ...channel, name: "pawBalance" }));
    while (true) {
      // @ts-expect-error TS7057
      const pawBalance = yield take(channel);
      yield put(purchaseActions.watchPawBalance.done(pawBalance));
    }
  } catch (e) {
    console.error(e);
  }
}

function* purchaseProductsWithCard(action: {
  payload: ReqPurchaseProductsWithCard;
}) {
  yield put(
    loadingActions.toggleLoading({
      msg: i18nextT("purchase.purchase.nowSettlement"),
    })
  );
  try {
    const {
      purchase: { fingerprint },
      auth: { user },
    }: Store = yield select();
    const { paymentInfo, cartProducts } = action.payload;
    const {
      eid, // eslint-disable-line unused-imports/no-unused-vars
      cardNumber,
      cardExpireYear,
      cardExpireMonth,
      cardSecurityCode,
      cardHolderName,
      displayPrice,
    } = paymentInfo;
    const arrangedCardInfo = {
      cardNo: cardNumber,
      expire: `${cardExpireYear}${cardExpireMonth}`,
      securityCode: cardSecurityCode,
      holderName: cardHolderName,
      tokenNumber: 1,
    };
    // @ts-expect-error TS7057
    const tokenData = yield getCardTokenGMO(arrangedCardInfo);
    if (tokenData.isError) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          msg: i18nextT("purchase.purchase.errorCredit"),
        })
      );
      return;
    }
    const [token] = tokenData.tokens;
    const payload = {
      token,
      displayPrice,
      pickedCartProducts: cartProducts,
      fingerprint,
    };
    // @ts-expect-error TS7057
    let fbToken = yield fetchFbToken(true);
    //
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          caption: i18nextT("purchase.purchase.congestionPurchaseCaption"),
          msg: i18nextT("purchase.purchase.congestionPurchase"),
        })
      );
      return;
    }
    // buy process
    // @ts-expect-error TS7057
    fbToken = appConfig.debug ? yield fetchFbToken() : fbToken;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.purchaseCard, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.status !== 200) {
      yield put(purchaseActions.setIsPurchasing(false));
      if (response.status === 403) throw new Error(yield response.text());
      // @ts-expect-error TS7057
      const text = yield response.json();
      if (text.errors.type === "API_ERROR") {
        yield call(handlePurchaseCardError, text.errors.info);
      } else if (text.errors.type === "DB_ERROR") {
        yield call(forceConfirmTicket);
      } else {
        switch (text.errors.type) {
          case "PURCHASE_LIMIT_ERROR":
            yield put(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.exceedPerPerson"),
              })
            );
            break;
          default:
            if (
              text.hasOwnProperty("errors") &&
              text.errors.hasOwnProperty("msg")
            ) {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            } else {
              yield put(
                modalActions.toggleError({ msg: `${text.errors.type}` })
              );
            }
            break;
        }
      }
      // if not duplicate transaction error, refresh fingerprint.
      if (text.errors.type !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
    } else {
      // 購入成功

      /**
       * GTMへデータ送信
       */
      const { gtm } = action.payload;
      sendPurchaseEventToGTM({
        ...gtm,
        transactionId: fingerprint,
      });

      const cart: UserCartProduct[] = yield call(
        fetchFirestoreCollection,
        `/users/${user.uid}/cartProducts`
      );
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "settlementComplete",
          trunk: { link: "/account/ticket", cart },
          caption: i18nextT("purchase.purchase.succsessPurchase"),
          msg: i18nextT("purchase.purchase.succsessPurchaseMsg"),
          btnMsg: i18nextT("purchase.purchase.myTicket"),
        })
      );
    }
  } catch (e) {
    yield put(purchaseActions.setIsPurchasing(false));
    if (!hasMessage(e)) return;
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
    yield call(purchaseErrorReport, e.message);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* purchaseProductsWithMemId(action: {
  payload: ReqPurchaseProductsWithMember;
}) {
  yield put(
    loadingActions.toggleLoading({
      msg: i18nextT("purchase.purchase.nowSettlement"),
    })
  );
  try {
    const {
      purchase: { fingerprint },
      auth: { user },
    }: Store = yield select();
    const { paymentInfo, cartProducts } = action.payload;
    const { eid } = paymentInfo; // eslint-disable-line unused-imports/no-unused-vars
    const msg = {
      displayPrice: paymentInfo.displayPrice,
      securityCode: paymentInfo.cardSecurityCode,
      cardSeq: paymentInfo.cardSeq,
      pickedCartProducts: cartProducts,
      fingerprint,
    };
    // @ts-expect-error TS7057
    let fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          caption: i18nextT("purchase.purchase.congestionPurchaseCaption"),
          msg: i18nextT("purchase.purchase.congestionPurchase"),
        })
      );
      return;
    }
    // @ts-expect-error TS7057
    fbToken = appConfig.debug ? yield fetchFbToken() : fbToken;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.purchaseMember, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    console.log(response);
    if (response.status !== 200) {
      yield put(purchaseActions.setIsPurchasing(false));
      if (response.status === 403) throw new Error(yield response.text());
      // @ts-expect-error TS7057
      const text = yield response.json();
      if (text.errors.type === "API_ERROR") {
        yield call(handlePurchaseCardError, text.errors.info);
      } else if (text.errors.type === "DB_ERROR") {
        yield call(forceConfirmTicket);
      } else {
        switch (text.errors.type) {
          case "PURCHASE_LIMIT_ERROR":
            yield put(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.exceedPerPerson"),
              })
            );
            break;
          default:
            if (
              text.hasOwnProperty("errors") &&
              text.errors.hasOwnProperty("msg")
            ) {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            } else {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            }
            break;
        }
      }
      // if not duplicate transaction error, refresh fingerprint.
      if (text.errors.type !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
      return;
    } else {
      // 購入成功

      /**
       * GTMへデータ送信
       */
      const { gtm } = action.payload;
      sendPurchaseEventToGTM({
        ...gtm,
        transactionId: fingerprint,
      });

      const cart: UserCartProduct[] = yield call(
        fetchFirestoreCollection,
        `/users/${user.uid}/cartProducts`
      );
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "settlementComplete",
          trunk: { link: "/account/ticket", cart },
          caption: i18nextT("purchase.purchase.succsessPurchase"),
          msg: i18nextT("purchase.purchase.succsessPurchaseMsg"),
          btnMsg: i18nextT("purchase.purchase.myTicket"),
        })
      );
    }
  } catch (e) {
    yield put(purchaseActions.setIsPurchasing(false));
    if (!hasMessage(e)) return;
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
    yield call(purchaseErrorReport, e.message);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* purchaseProductsWithCVS(action: {
  payload: ReqPurchaseProductsWithCVS;
}) {
  yield put(
    loadingActions.toggleLoading({
      msg: i18nextT("purchase.purchase.nowSettlement"),
    })
  );
  try {
    const {
      purchase: { fingerprint },
      auth: { user },
    }: Store = yield select();
    const { paymentInfo, cartProducts } = action.payload;
    const { eid } = paymentInfo; // eslint-disable-line unused-imports/no-unused-vars
    const msg = {
      ...paymentInfo,
      pickedCartProducts: cartProducts,
      fingerprint,
    };
    // @ts-expect-error TS7057
    let fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          caption: i18nextT("purchase.purchase.congestionPurchaseCaption"),
          msg: i18nextT("purchase.purchase.congestionPurchase"),
        })
      );
      return;
    }
    // @ts-expect-error TS7057
    fbToken = appConfig.debug ? yield fetchFbToken() : fbToken;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.purchaseCVS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    if (response.status !== 200) {
      yield put(purchaseActions.setIsPurchasing(false));
      if (response.status === 403) throw new Error(yield response.text());
      // @ts-expect-error TS7057
      const text = yield response.json();
      if (text.errors.type === "API_ERROR") {
        yield call(handlePurchaseError, text.errors.info);
      } else if (text.errors.type === "DB_ERROR") {
        yield call(forceConfirmTicket);
      } else {
        switch (text.errors.type) {
          case "PURCHASE_LIMIT_ERROR":
            yield put(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.exceedPerPerson"),
              })
            );
            break;
          default:
            if (
              text.hasOwnProperty("errors") &&
              text.errors.hasOwnProperty("msg")
            ) {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            } else {
              yield put(
                modalActions.toggleError({ msg: `${text.errors.type}` })
              );
            }
            break;
        }
      }
      // if not duplicate transaction error, refresh fingerprint.
      if (text.errors.type !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
      return;
    } else {
      // 購入成功

      /**
       * GTMへデータ送信
       */
      const { gtm } = action.payload;
      sendPurchaseEventToGTM({
        ...gtm,
        transactionId: fingerprint,
      });

      const cart: UserCartProduct[] = yield call(
        fetchFirestoreCollection,
        `/users/${user.uid}/cartProducts`
      );
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "settlementComplete",
          trunk: { link: "/account/ticket", cart },
          caption: i18nextT("purchase.purchase.succsessPurchase"),
          msg: i18nextT("purchase.purchase.succsessPurchaseMsg"),
          btnMsg: i18nextT("common.routes.ticket"),
        })
      );
    }
  } catch (e) {
    yield put(purchaseActions.setIsPurchasing(false));
    if (!hasMessage(e)) return;
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
    yield call(purchaseErrorReport, e.message);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* purchaseProductsWithPhone(action: {
  payload: ReqPurchaseProductsWithPhone;
}) {
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("purchase.purchase.start") })
  );
  try {
    const {
      purchase: { fingerprint },
    }: Store = yield select();
    const { paymentInfo, cartProducts } = action.payload;
    // TODO: リニューアル後はこちらを使用する
    // const paymentInfo: RequestPurchasePhone = action.payload.phoneInfo
    const { eid } = paymentInfo; // eslint-disable-line unused-imports/no-unused-vars
    const msg = {
      ...paymentInfo,
      pickedCartProducts: cartProducts,
      fingerprint,
    };
    // @ts-expect-error TS7057
    let fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          caption: i18nextT("purchase.purchase.congestionPurchaseCaption"),
          msg: i18nextT("purchase.purchase.congestionPurchase"),
        })
      );
      return;
    }
    // @ts-expect-error TS7057
    fbToken = appConfig.debug ? yield fetchFbToken() : fbToken;

    // fbToken = currentUser.uid
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.purchasePhone, {
      // let response = yield fetch("http://localhost:5000/spwn-balus/us-central1/purchasePhone", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    if (response.status !== 200) {
      yield put(purchaseActions.setIsPurchasing(false));
      if (response.status === 403) throw new Error(yield response.text());
      // @ts-expect-error TS7057
      const text = yield response.json();
      if (text.errors.type === "API_ERROR") {
        yield call(handlePurchaseError, text.errors.info);
      } else if (text.errors.type === "DB_ERROR") {
        yield call(forceConfirmTicket);
      } else {
        switch (text.errors.type) {
          case "PURCHASE_LIMIT_ERROR":
            yield put(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.exceedPerPerson"),
              })
            );
            break;
          default:
            if (
              text.hasOwnProperty("errors") &&
              text.errors.hasOwnProperty("msg")
            ) {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            } else {
              yield put(
                modalActions.toggleError({ msg: `${text.errors.type}` })
              );
            }
            break;
        }
      }
      // if not duplicate transaction error, refresh fingerprint.
      if (text.errors.type !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
      return;
    } else {
      // 購入成功
      /**
       * GTMへデータ送信
       */
      const { gtm } = action.payload;
      sendPurchaseEventToGTM({
        ...gtm,
        transactionId: fingerprint,
      });
      // キャリア決済ページへリダイレクト
      // @ts-expect-error TS7057
      const url = yield response.text();
      window.location.href = url;
    }
  } catch (e) {
    yield put(purchaseActions.setIsPurchasing(false));
    if (!hasMessage(e)) return;
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
    yield call(purchaseErrorReport, e.message);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

/**
 * execute purchaseFree API (Based purchaseMember)
 * @param action
 */
function* purchaseProductsWithFree(action: {
  payload: ReqPurchaseProductsWithFree;
}) {
  yield put(
    loadingActions.toggleLoading({
      msg: i18nextT("purchase.purchase.nowSettlement"),
    })
  );
  try {
    const {
      purchase: { fingerprint },
      auth: { user },
    }: Store = yield select();
    const { paymentInfo, cartProducts } = action.payload;
    const { eid } = paymentInfo; // eslint-disable-line unused-imports/no-unused-vars
    const msg = {
      displayPrice: paymentInfo.displayPrice,
      pickedCartProducts: cartProducts,
      fingerprint,
    };
    // @ts-expect-error TS7057
    let fbToken = yield fetchFbToken(true);
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      yield put(purchaseActions.setIsPurchasing(false));
      yield put(
        modalActions.toggleError({
          caption: i18nextT("purchase.purchase.congestionPurchaseCaption"),
          msg: i18nextT("purchase.purchase.congestionPurchase"),
        })
      );
      return;
    }
    // @ts-expect-error TS7057
    fbToken = appConfig.debug ? yield fetchFbToken() : fbToken;
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.purchaseFree, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });
    console.log(response);
    if (response.status !== 200) {
      yield put(purchaseActions.setIsPurchasing(false));
      if (response.status === 403) throw new Error(yield response.text());
      // @ts-expect-error TS7057
      const text = yield response.json();
      if (text.errors.type === "API_ERROR") {
        yield call(handlePurchaseError, text.errors.info);
      } else if (text.errors.type === "DB_ERROR") {
        yield call(forceConfirmTicket);
      } else {
        switch (text.errors.type) {
          case "PURCHASE_LIMIT_ERROR":
            yield put(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.exceedPerPerson"),
              })
            );
            break;
          default:
            if (
              text.hasOwnProperty("errors") &&
              text.errors.hasOwnProperty("msg")
            ) {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            } else {
              yield put(modalActions.toggleError({ msg: text.errors.msg }));
            }
            break;
        }
      }
      // if not duplicate transaction error, refresh fingerprint.
      if (text.errors.type !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
      return;
    } else {
      /**
       * GTMへデータ送信
       */
      const { gtm } = action.payload;
      sendPurchaseEventToGTM({
        ...gtm,
        transactionId: fingerprint,
      });
      const cart: UserCartProduct[] = yield call(
        fetchFirestoreCollection,
        `/users/${user.uid}/cartProducts`
      );
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "settlementComplete",
          trunk: { link: "/account/ticket", cart },
          caption: i18nextT("purchase.purchase.succsessPurchase"),
          msg: i18nextT("purchase.purchase.succsessPurchaseMsg"),
          btnMsg: i18nextT("common.routes.ticket"),
        })
      );
    }
  } catch (e) {
    yield put(purchaseActions.setIsPurchasing(false));
    if (!hasMessage(e)) return;
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        msg: e.message,
      },
    });
    yield call(purchaseErrorReport, e.message);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* handlePurchaseError(
  _errors: FunctionError.ErrorResponse["errors"]["info"]
) {
  const caption = i18nextT("purchase.purchase.congestionPurchaseCaption");
  // FIXME: 曖昧なメッセージ
  const msg = i18nextT("purchase.purchase.handlePurchaseError");
  yield put(modalActions.toggleError({ caption, msg }));
}

function* handlePurchaseCardError(
  errors: FunctionError.ErrorResponse["errors"]["info"]
) {
  const caption = i18nextT("purchase.purchase.congestionPurchaseCaption");
  // 取引不可能なカードのエラーコード
  // @ts-expect-error TS18048
  const error = errors.find((err) =>
    GMO_INVALID_CARD_ERROR_CODES.includes(err.code)
  );
  if (error) {
    const msg = i18nextT("purchase.purchase.handlePurchaseInvalidCardError");
    yield put(modalActions.toggleError({ caption, msg }));
  } else {
    // FIXME: 曖昧なメッセージ
    const msg = i18nextT("purchase.purchase.handlePurchaseCardError");
    yield put(modalActions.toggleError({ caption, msg }));
  }
}

export function* isCrowding(fbToken: string) {
  // check server busy state
  // @ts-expect-error TS7057
  const checkServerResponse = yield fetch(
    // @ts-expect-error TS2769
    appConfig.CloudFunctions.checkServerState,
    {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({}),
    }
  );
  if (checkServerResponse.status !== 200) {
    if (checkServerResponse.status === 503) {
      // server is busy
      // const _json = yield checkServerResponse.json()
      // yield put(modalActions.toggleError({ msg: _json.msg }))
    } else {
      // yield put(modalActions.toggleError({ msg: '在庫確認に失敗しました。少し時間をおいて購入ボタンを押してください。' }))
    }
    return true;
  }
  return false;
}

function* fetchUserActiveTransactions() {
  // @ts-expect-error TS2339
  const { uid } = firebase.auth().currentUser;
  const ref = `settlement/${uid}/activeTransactions`;
  const snapshot = firebase
    .firestore()
    .collection(ref)
    .where("isPurchased", "==", true)
    .get();
  const data: ActiveTransaction = yield fetchFirestoreCollectionBySnapshotMap(
    snapshot
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield put(purchaseActions.fetchUserActiveTransactions.done(data as any));
}

function* forceConfirmTicket() {
  // GMOの決済は完了している可能性がある
  yield put(
    modalActions.toggleActionModal({
      actionModalType: "confirmTransition",
      trunk: { link: "/account/ticket" },
      caption: "決済処理中に予期せぬエラーが発生しました",
      msg: "マイチケットから購入情報をご確認ください。<br/>購入情報が確認できない場合は、ご迷惑おかけして誠に申しわけございませんが、下記リンクよりお問い合わせください。<br/>https://spwn.jp/contact",
      btnMsg: i18nextT("purchase.purchase.myTicket"),
    })
  );
  errorReport(
    new Error(
      "決済エラーが発生しました。ユーザーの購入状況を確認してください。"
    )
  );
}

function* purchaseErrorReport(msg: string) {
  // クライアントのネットワークエラーの可能性が高い
  // 決済リクエストがタイムアウトした場合、GMOの決済は完了している可能性がある
  yield errorReport(
    new Error(
      `決済エラーが発生しました。連続して発生する場合はシステムエラーの可能性があります。\n${msg}`
    )
  );
}

function* displayCompletionScreen() {}

/**
 * 購入イベント（purchase）をGoogle Tag Managerに送る
 * - 購入のファネル分析のため
 *
 * NOTE: 購入ドメインにこの処理を置いているのは、購入ページに関わるデータから、GTMのイベントへ変換するので、その責務は購入ドメインにあると考えたため
 */
const sendPurchaseEventToGTM = ({
  myCarts,
  totalPrice,
  transactionId,
  shippingFee,
}: {
  myCarts: MyCartData[];
  totalPrice: number;
  transactionId: string;
  shippingFee?: number;
}) => {
  // カートから、チケット、グッズをまとめてitemにする
  const items = myCarts.flatMap(({ tickets, goods }) => tickets.concat(goods));
  pushDataLayer({
    event: "purchase",
    ecommerce: {
      currency: "JPY",
      // 決済手数料やサービス手数料、送料が含まれ、itemsから計算できないため外から合計を渡してる
      value: totalPrice,
      transaction_id: transactionId,
      ...(shippingFee !== undefined ? { shipping: shippingFee } : {}),
      // item_id が重複していても、別要素としてリクエストする。うまく集計されないようであれば修正する。
      items: items.map((item) => {
        return {
          // アイテムからブランド名を取得する方法が、現状（2023/08/30）のportalフロントエンドでは存在しないため除外する
          // item_brand: "hololive",
          item_category: item.productType,
          item_list_id: item.eid,
          item_id: item.productId,
          item_name: createEcommerceEventItemName({
            itemName: item.name,
            eventId: item.eid,
          }),
          ...(item.subClassName !== undefined
            ? { item_variant: item.subClassName }
            : {}),
          quantity: item.count,
          // 決済時点で実際に支払う額とするため、price_jpyを利用
          price: item.price_jpy,
        };
      }),
    },
  });
};
