import firebase from "firebase/app";
import appConfig from "../constants/appConfig";
import { isAndroid, fixedEncodeURIComponent } from "./index";
import { DocumentData, DocumentSnapshot } from "@google-cloud/firestore";

export const fetchFbToken = async (_noUseUid = false) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return null;
  }
  return currentUser.getIdToken(true);
};

export const fetchFbTokenWithoutTokenRefresh = async () => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return null;
  }
  return currentUser.getIdToken();
};

export const fetchUserId = (_noUseUid = false) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return null;
  }
  return currentUser.uid;
};

// type FirestoreQuery = {
//   fieldPath: string | firebase.firestore.FieldPath;
//   opStr: firebase.firestore.WhereFilterOp;
//   value: any;
// };

// TODO@later ジェネリック使いたいががエラー出るのでなくす
/**
 * Firestoreドキュメントを取得する
 */
export const fetchFirestoreDocument = async <T extends Object>(
  ref: string
): Promise<T> => {
  return firebase
    .firestore()
    .doc(ref)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      if (!data) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...data, _id: snapshot.id } as any;
    })
    .catch(async (e) => {
      console.error(e);
      return Promise.reject(e);
    });
};
export const fetchFirestoreDocumentMap = async (ref: string) => {
  return firebase
    .firestore()
    .doc(ref)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      if (!data) {
        return null;
      }
      return { [snapshot.id]: data };
    })
    .catch(async (e) => {
      console.error(e);
      return Promise.reject(e);
    });
};

/**
 * Firestoreコレクションを取得する
 */
export const fetchFirestoreCollection = async <T extends Object>(
  ref: string
): Promise<T[]> => {
  return firebase
    .firestore()
    .collection(ref)
    .get()
    .then((snapshot) => {
      // @ts-expect-error TS7034
      const list = [];
      snapshot.forEach((doc) => {
        list.push({ ...doc.data(), _id: doc.id, _refPath: doc.ref.path });
      });
      // @ts-expect-error TS7005
      return list;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
export const fetchFirestoreCollectionMap = async <T extends Object>(
  ref: string
): Promise<{ [key: string]: T }> => {
  return firebase
    .firestore()
    .collection(ref)
    .get()
    .then((snapshot) => {
      let map = {};
      snapshot.forEach((doc) => {
        map = { ...map, [doc.id]: { ...doc.data(), _id: doc.id } };
      });
      return map;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
export const fetchFirestoreCollectionGroupMap = async <T extends Object>(
  ref: string,
  query: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.WhereFilterOp,
    unknown
  ]
): Promise<{ [key: string]: T }> => {
  return firebase
    .firestore()
    .collectionGroup(ref)
    .where(query[0], query[1], query[2])
    .get()
    .then((snapshot) => {
      let map = {};
      snapshot.forEach((doc) => {
        // considering document id duplication, use document path
        map = { ...map, [doc.ref.path]: { ...doc.data(), _id: doc.id } };
      });
      return map;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};

// whereなど使う時用
/**
 * Firestoreドキュメントを取得する
 */
export const fetchFirestoreDocumentBySnapshot = async <T extends Object>(
  snapshot: Promise<DocumentSnapshot>
): Promise<T> => {
  return snapshot
    .then((snapshot) => {
      const data = snapshot.data();
      if (!data) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...data, _id: snapshot.id, _refPath: snapshot.ref.path } as any;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
// @ts-expect-error TS7006
export const fetchFirestoreDocumentBySnapshotMap = (snapshot) => {
  return (
    snapshot
      // @ts-expect-error TS7006
      .then((snapshot) => {
        const data = snapshot.data();
        if (!data) {
          return null;
        }
        return { [snapshot.id]: data };
      })
      // @ts-expect-error TS7006
      .catch(async (e) => {
        return Promise.reject(e);
      })
  );
};
/**
 * Firestoreコレクションを取得する
 */
export const fetchFirestoreCollectionBySnapshot = async <T extends Object>(
  snapshot: Promise<DocumentData>
): Promise<T[]> => {
  return snapshot
    .then((snapshot) => {
      // @ts-expect-error TS7034
      const list = [];
      // @ts-expect-error TS7006
      snapshot.forEach((doc) => {
        list.push({ ...doc.data(), _id: doc.id });
      });
      // @ts-expect-error TS7005
      return list;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
// @ts-expect-error TS7006
export const fetchFirestoreCollectionBySnapshotMap = (snapshot) => {
  return (
    snapshot
      // @ts-expect-error TS7006
      .then((snapshot) => {
        let map = {};
        // @ts-expect-error TS7006
        snapshot.forEach((doc) => {
          map = { ...map, [doc.id]: doc.data() };
        });
        return map;
      })
      // @ts-expect-error TS7006
      .catch(async (e) => {
        return Promise.reject(e);
      })
  );
};

/**
 * set data to firestore docment
 * default merge option is true
 *
 * NOTE: if you want to create new document, you need to set a document id
 *   e.g. setFirestoreDocument("users/123", {name: "hoge"})
 * @param ref
 * @param data
 */
export const setFirestoreDocument = async (
  ref: string,
  // @ts-expect-error TS7006
  data,
  mergeOpt = true
) => {
  await firebase.firestore().doc(ref).set(data, { merge: mergeOpt });
};

// Realtime Database

/**
 * Realtime Databaseのvalueを取得する
 * @param ref 対象のvalueのrefにする
 */
export const fetchDatabaseValue = async <T extends Object>(
  ref: string
): Promise<T> => {
  return firebase
    .database()
    .ref(ref)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      if (!data) {
        return null;
      }
      return { ...data, _id: snapshot.key };
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
export const fetchDatabaseValueMap = async (ref: string) => {
  return firebase
    .database()
    .ref(ref)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      if (!data) {
        return null;
      }
      // @ts-expect-error TS2464
      return { [snapshot.key]: data };
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};

/**
 * Realtime Databaseのvalueリストを取得する
 * @param ref リストのルートのrefにする
 */
export const fetchDatabaseValueList = async (ref: string) => {
  return firebase
    .database()
    .ref(ref)
    .once("value")
    .then((snapshot) => {
      // @ts-expect-error TS7034
      const list = [];
      snapshot.forEach((data) => {
        list.push({ ...data.val(), _id: data.key });
      });
      // @ts-expect-error TS7005
      return list;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};
export const fetchDatabaseValuesMap = async <T extends Object>(
  ref: string
): Promise<{ [key: string]: T }> => {
  return firebase
    .database()
    .ref(ref)
    .once("value")
    .then((snapshot) => {
      let map = {};
      snapshot.forEach((doc) => {
        // @ts-expect-error TS2464
        map = { ...map, [doc.key]: doc.val() };
      });
      return map;
    })
    .catch(async (e) => {
      return Promise.reject(e);
    });
};

/**
 * DynamicLink
 */

/**
 * @link https://firebase.google.com/docs/dynamic-links/create-manually?hl=ja
 * @param accessToken
 */
export const createDynamicLinkWithParam = (
  // @ts-expect-error TS7006
  provider,
  credential: firebase.auth.OAuthCredential | { token: string }
) => {
  const { accessToken, idToken, secret } =
    credential as firebase.auth.OAuthCredential;
  let providerId;
  let providerData = "";
  switch (provider) {
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID: {
      providerId = "google";
      providerData = providerData
        .concat(providerId)
        .concat(`?${idToken}?${accessToken}`);
      break;
    }
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID: {
      providerId = "twitter";
      providerData = providerData
        .concat(providerId)
        .concat(`?${accessToken}?${secret}`);
      break;
    }
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID: {
      providerId = "facebook";
      providerData = providerData.concat(providerId).concat(`?${accessToken}`);
      break;
    }
    case firebase.auth.EmailAuthProvider.PROVIDER_ID: {
      providerId = "email";
      break;
    }
    case "docomo": {
      const { token } = credential as { token: string };
      providerData = `docomo?${token}`;
      break;
    }
    default: {
      // 対応していないはずのAuth認証
      providerId = "unknown";
      break;
    }
  }
  const dynamicLink = appConfig.arApp.dynamicLink.domain;
  const { deepLinkDomain } = appConfig.arApp.dynamicLink;
  const { apn } = appConfig.arApp.dynamicLink;
  const { ibi } = appConfig.arApp.dynamicLink;
  const { isi } = appConfig.arApp.dynamicLink;

  if (isAndroid()) {
    return `${dynamicLink}/?link=${deepLinkDomain}/?provider=${providerData}&apn=${apn}&ibi=${ibi}&isi=${isi}&efr=1`;
  } else {
    // HOTFIX: iOSはDynamicLinkのデバッグができないのでカスタムスキームをひとまず使用
    return `spwnAr-facebook://?provider=${providerData}`;
  }
};

/**
 * create dynamic link for viewer app
 * @param provider
 * @param credential
 */
export const createViewerAppDynamicLinkWithParam = (
  provider: string | "docomo",
  credential: firebase.auth.OAuthCredential | { token: string }
) => {
  const { accessToken, idToken, secret } =
    credential as firebase.auth.OAuthCredential;
  let providerData = "";
  switch (provider) {
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID: {
      providerData = `provider=google&token=${idToken}`;
      break;
    }
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID: {
      providerData = `provider=twitter&token=${accessToken}&secret=${secret}`;
      break;
    }
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID: {
      providerData = `provider=facebook?${accessToken}`;
      break;
    }
    case "docomo": {
      const { token } = credential as { token: string };
      providerData = `provider=docomo&token=${token}`;
      break;
    }
    default: {
      break;
    }
  }
  const dynamicLink = appConfig.viewerApp.dynamicLink.domain;
  const { deepLinkDomain } = appConfig.viewerApp.dynamicLink;
  const { apn } = appConfig.viewerApp.dynamicLink;
  const { ibi } = appConfig.viewerApp.dynamicLink;
  const { isi } = appConfig.viewerApp.dynamicLink;
  const deepLink = fixedEncodeURIComponent(`${deepLinkDomain}?${providerData}`);
  return `${dynamicLink}/?link=${deepLink}&apn=${apn}&ibi=${ibi}&isi=${isi}&efr=1`;
};

/**
 * update firestore document
 * @param ref
 * @param data
 */
// @ts-expect-error TS7006
export const updateFirestoreDoc = async (ref: string, data) => {
  if (firebase?.firestore) {
    await firebase.firestore().doc(ref).update(data);
  } else {
    // HOTFIX: storybook 用
    return;
  }
};

/**
 * delete firestore docuemnt
 * @param ref
 */
export const deleteFirestoreDoc = async (ref: string) => {
  if (firebase?.firestore) {
    await firebase.firestore().doc(ref).delete();
  } else {
    // HOTFIX: storybook 用
    return;
  }
};

/**
 * 生メッセージを取得する
 * @param ref メッセージ情報
 */
export const fetchFirestoreCollectionRaw = (ref: string) => {
  if (firebase?.firestore) {
    return firebase.firestore().collection(ref);
  } else {
    // HOTFIX: storybook 用
    return {
      orderBy: () => {
        return {
          limit: () => {
            return {
              onSnapshot: () => {
                return () => {};
              },
            };
          },
        };
      },
    };
  }
};

/**
 * convert Date to Firestore Timestamp
 */
export const convertDateToTimestamp = (date: Date) => {
  return firebase.firestore.Timestamp.fromDate(date);
};

export type FilterQueryDocumentsQueryFn =
  () => firebase.firestore.Query<firebase.firestore.DocumentData>;
export type FilterQueryDocumentsFilterFn<T> = (rows: T[]) => T[];

/**
 * Firestoreからフィルターに合致するドキュメント数がlimitに達するか全件取得するまでページングしながらドキュメントを取得する
 * Query後にフィルタリングしなおかつ一定件数が欲しい場合に使用する
 *
 * @param queryFn Firestoreのクエリを返す関数
 * @param filterFn ドキュメントをフィルタリングする関数
 * @param afterFilterFn 必要なドキュメント後にさらにフィルタリングする関数 (任意) 例: 並び替え
 * @param limit 取得するドキュメント数
 * @param perPage 1回のQueryあたりの取得件数
 */
// このファイルがJSXになっているためGenericsをそのまま使うとエラーが出るのでカンマをいれている
export const filterQueryDocuments = async <T,>(
  queryFn: FilterQueryDocumentsQueryFn,
  filterFn: FilterQueryDocumentsFilterFn<T>,
  afterFilterFn?: FilterQueryDocumentsFilterFn<T>,
  limit?: number,
  perPage?: number
): Promise<T[]> => {
  const rows: T[] = [];

  const perPageNum = perPage ?? 10;
  let hasNext = true;
  let lastDoc:
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | undefined = undefined;

  // limitを満たすまでページングしながらイベントを取得する
  while (hasNext) {
    const q = queryFn();

    const snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> =
      await (lastDoc
        ? q.startAfter(lastDoc).limit(perPageNum).get()
        : q.limit(perPageNum).get());
    if (snapshot.empty) {
      break;
    }

    const docs = snapshot.docs.map((doc) => {
      return { ...doc.data(), _id: doc.id } as T;
    });
    rows.push(...filterFn(docs));

    hasNext =
      snapshot.docs.length === perPage && rows.length < (limit ?? Infinity);
    lastDoc = snapshot.docs[snapshot.docs.length - 1];
  }

  if (afterFilterFn) {
    rows.splice(0, rows.length, ...afterFilterFn(rows));
  }

  return limit ? rows.splice(0, limit) : rows;
};
