import { useRecommendedEvents } from "./useRecommendedEvents";

/**
 * - すぐにおすすめイベントを表示できるようにあらかじめ取得しておく
 * - INクエリで絞り込んでクエリするため、パフォーマンスにはほとんど影響がない
 */
export const PreFetchRecommendedEvents = () => {
  useRecommendedEvents();
  return null;
};
