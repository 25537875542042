import React, { FC } from "react";
import Box from "@material-ui/core/Box";

export const AccountMenuSection: FC<{
  children: JSX.Element | JSX.Element[];
  heading: string;
  isEmergency?: boolean;
}> = ({ children, heading, isEmergency = false }) => {
  return (
    <Box component={"section"}>
      <Box
        component={"h2"}
        padding={"8px 12px"}
        color={"#fff"}
        fontSize={"14px"}
        bgcolor={isEmergency ? "rgb(232, 74, 14)" : "#979797"}
      >
        {heading}
      </Box>

      {children}
    </Box>
  );
};
