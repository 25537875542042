import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { purchaseActions, CardInfo } from "modules/purchase";
import { authActions } from "modules/auth";
import BorderLoader from "components/atoms/loader/BorderLoader";
import { Breadcrumb } from "components/atoms/Breadcrumb";
import { useI18n } from "hooks/i18n/i18n";
import { modalActions } from "modules/modal";
import { SubContentsLayout } from "ui/SubContentsLayout";
import styled from "@emotion/styled";
import {
  fontDinMixin,
  LinkButton,
  myPageEditMixin,
  ContentsLayout,
} from "../../styles";

const _Container = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
  .button {
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
      margin-top: 20px;
    }
  }
  .heading {
    width: 100%;
    margin: 0 auto 40px;
    @media screen and (min-width: 768px) {
      width: 740px;
      font-size: 18px;
    }
  }
  .no_registered_card {
    margin: 80px 0;
    text-align: center;
    font-weight: bold;
  }
  .credit_card_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
  }
  .credit_card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 3%;
    margin-bottom: 20px;
    border: 1px solid #d2d2d2;
    @media screen and (min-width: 768px) {
      width: 360px;
      padding: 20px 15px;
    }
    .info {
      @media screen and (min-width: 768px) {
        width: 210px;
      }
    }
    .expiration {
      @media screen and (min-width: 768px) {
        width: 60px;
      }
    }
    .credit_num,
    .credit_name,
    .expiration {
      p:first-child {
        margin-bottom: 5px;
        font-size: 10px;
      }
      p:last-child {
        ${fontDinMixin};
        font-size: 22px;
        @media screen and (min-width: 768px) {
          font-size: 28px;
        }
      }
    }
    .credit_num {
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        margin-bottom: 15px;
      }
      p:last-child {
        letter-spacing: 1px;
      }
    }
    .delete {
      width: 10%;
      color: #78e7eb;
      font-size: 10px;
      font-weight: bold;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 25px;
      }
    }
  }
  .spinner_layout {
    margin-bottom: 120px;
  }
`;

const Container = styled(_Container)(myPageEditMixin);

export const AccountCredit: React.FC = () => {
  const { t } = useI18n();
  const dispatch = useDispatch();

  const registeredCardList = useSelector(
    (state: Store) => state.purchase.cardInfoList
  );

  useEffect(() => {
    dispatch(purchaseActions.fetchCardInfo.started({}));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.fetchCardInfo.started,
        args: null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerNewCard = () => {
    dispatch(purchaseActions.toggleRegisterCard());
  };

  // @ts-expect-error TS7006
  const removeSelectedCard = (event) => {
    const idx = Number(event.currentTarget.id);
    dispatch(
      modalActions.toggleActionModal({
        trunk: {
          targetIdx: idx,
        },
        caption: "選択したカードを削除しますか？",
        contents: {},
        btnMsg: "削除する",
        callbackTarget: "removeRegisteredCardInfo",
      })
    );
    // this.props.removeRegisteredCardInfo({targetIdx: idx})
  };

  const registeredCards = (cardList: CardInfo[]) => {
    return cardList.map((info: CardInfo, idx) => {
      return (
        <div key={`cardInfo_${idx}`} className="credit_card">
          <div className="info">
            <div className="credit_num">
              <p>{t("accountCredit.creditCardNumber")}</p>
              <p>{info.cardNumber}</p>
            </div>
            <div className="credit_name">
              <p>{t("accountCredit.creditCardHolder")}</p>
              <p>{info.cardHolderName}</p>
            </div>
          </div>
          <div className="expiration">
            <p>{t("accountCredit.expirationDate")}</p>
            <p>
              {info.cardExpireMonth}/{info.cardExpireYear}
            </p>
          </div>
          <p id={String(idx)} onClick={removeSelectedCard} className="delete">
            {t("accountCredit.delete")}
          </p>
        </div>
      );
    });
  };

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.accountCredit")],
        ]}
      />

      <Container id="contents">
        <ContentsLayout>
          <p className="heading">{t("accountCredit.registeredCreditCard")}</p>
          {registeredCardList === undefined || registeredCardList === null ? (
            <BorderLoader />
          ) : registeredCardList.length === 0 ? (
            <p className="no_registered_card">
              {t("accountCredit.emptyCreditCard")}
            </p>
          ) : (
            <div className="credit_card_list">
              {registeredCards(registeredCardList)}
            </div>
          )}
          <LinkButton onClick={registerNewCard} className="button">
            {t("accountCredit.addCreditCard")}
          </LinkButton>
        </ContentsLayout>
      </Container>
    </SubContentsLayout>
  );
};
