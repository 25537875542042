import firebase from "firebase/app";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";

import { usePawFeatureFlag } from "../../constants/featureFlags";
import { authActions } from "../../modules/auth";
import { firestoreActions } from "../../modules/firestore";
import { purchaseActions } from "../../modules/purchase";
import { Store } from "../../store";
import { createConnectClient } from "../../utility/connectWeb";

export const FetchPawBalanceKey = "fetchPawBalance";

export const useEmoBalance = () => {
  const auth = firebase.auth();
  const connectClient = createConnectClient(auth);
  const emoBalance = useSelector((state: Store) => state.purchase.emoBalance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );
    dispatch(purchaseActions.watchPawBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.watchPawBalance.started,
        args: null,
      })
    );

    return () => {
      dispatch(firestoreActions.closeChannel({ channel: "pawBalance" }));
    };
  }, [dispatch]);

  const { data } = useSWR([FetchPawBalanceKey], async () => {
    const userId = auth.currentUser?.uid;

    const response = await connectClient.fetchPawBalance({
      userId: userId ?? "",
    });

    if (
      response.status === "SPWN_API_RESPONSE_UNSPECIFIED" ||
      response.status === "SPWN_API_RESPONSE_ERROR"
    ) {
      return {
        status: response.status,
      };
    }

    return {
      status: response.status,
      data: {
        balance: response.data.balance ? Number(response.data.balance) : 0,
      },
    };
  });

  if (usePawFeatureFlag()) {
    return {
      freeValue: 0,
      paidValue: data?.data?.balance ?? 0,
    };
  } else {
    return emoBalance;
  }
};
