import { authActions } from "modules/auth";
import { purchaseActions } from "modules/purchase";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import useSWR from "swr";
import firebase from "firebase/app";
import { createConnectClient } from "utility/connectWeb";
import { replaceBr } from "utility";
import { usePawFeatureFlag } from "constants/featureFlags";

type OrderItemRecord = {
  eid: string;
  itemId: string;
  usageFreeValue: number;
  usagePaidValue: number;
  count?: number; // count は Optional
};

type OrderRecord = {
  [key: string]: OrderItemRecord;
};

type PawItemValueRecord = {
  display: boolean;
  name: string;
  order: string | number; // orderはstringとnumberが混在している
  values: {
    free: number;
    paid: number;
  };
};

// Action, Donation型共通の型
interface CommonRecord extends PawItemValueRecord {
  type: "action" | "donation";
}

// action 型
interface ActionRecord extends CommonRecord {
  type: "action";
}

// donation型
interface DonationRecord extends CommonRecord {
  type: "donation";
}

// DLC型 (動的に型を生成)
interface DlcRecord extends PawItemValueRecord {
  type: `DLC${string}` | `Vo${string}` | `Pr${string}`;
}

// F型（フラワースタンド）
interface FlowerStandRecord extends PawItemValueRecord {
  type: `F${string}`;
  addCustomImg?: boolean;
  addCustomName?: boolean;
  addProfileImg?: boolean;
  description?: string;
}

// Lottery型
interface LotteryRecord extends PawItemValueRecord {
  type: "lottery";
}

// その他 (typeが固定でないもの)
interface OtherRecord extends PawItemValueRecord {
  type: string;
}

// レコード全体の型 (Union Type)
type PawItemRecord = {
  [key: string]:
    | ActionRecord
    | DonationRecord
    | DlcRecord
    | FlowerStandRecord
    | LotteryRecord
    | OtherRecord;
};

type FetchPawUsageHistoryResponse = {
  status:
    | "SPWN_API_RESPONSE_UNSPECIFIED"
    | "SPWN_API_RESPONSE_ERROR"
    | "SPWN_API_RESPONSE_SUCCESS";
  data?: FetchPawUsageHistoryItem[];
};

type FetchPawUsageHistoryItem = {
  pawUsageHistoryId: string;
  itemId: string;
  itemName: string;
  price: BigInt;
  usedAt: BigInt;
};

export const useUsageHistory = () => {
  /**
   * @todo envファイルに値を設定して、それを参照するようにする
   */
  const RELEASE_FLAG = usePawFeatureFlag();
  const dispatch = useDispatch();
  const auth = firebase.auth();
  const connectClient = createConnectClient(auth);
  const pawItemList = useSelector(
    (state: Store) => state.purchase.pawItemList
  ) as PawItemRecord;
  const pawHistoryContainer = useSelector(
    (state: Store) => state.purchase.pawUsageHistoryContainer
  ) as OrderRecord;

  useEffect(() => {
    dispatch(purchaseActions.getPAWItemList.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWItemList.started,
        args: null,
      })
    );

    dispatch(purchaseActions.getPAWUsageHistory.started({ end: null }));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWUsageHistory.started,
        args: { end: null },
      })
    );
  }, [dispatch]);

  /**
   * @todo ここに新規APIの処理を追加する
   */
  const { data } = useSWR(["fetchUsageHistory"], async () => {
    const userId = auth.currentUser?.uid;

    const response: FetchPawUsageHistoryResponse =
      await connectClient.fetchPawUsageHistory({
        userId: userId ?? "",
      });

    if (
      response.status === "SPWN_API_RESPONSE_UNSPECIFIED" ||
      response.status === "SPWN_API_RESPONSE_ERROR"
    ) {
      return {
        status: response.status,
      };
    }

    return {
      status: response.status,
      data: response.data,
    };
  });

  const newPawHistoryContainer = useMemo(
    () =>
      data?.data
        ?.map((history: FetchPawUsageHistoryItem) => {
          return {
            id: history.pawUsageHistoryId,
            itemName: pawItemList?.[history.itemName]?.name,
            price: Number(history.price),
            usedAt: Number(history.usedAt),
          };
        })
        .sort((a, b) => {
          return b.usedAt - a.usedAt;
        }) ?? [],
    [data?.data, pawItemList]
  );

  if (!pawItemList || Object.keys(pawItemList).length === 0) {
    dispatch(purchaseActions.getPAWItemList.started());
    return {
      pawHistoryContainer: RELEASE_FLAG ? newPawHistoryContainer : [],
    };
  }

  if (!pawHistoryContainer || Object.keys(pawHistoryContainer).length === 0) {
    dispatch(purchaseActions.getPAWUsageHistory.started({ end: null }));
    return {
      pawHistoryContainer: RELEASE_FLAG ? newPawHistoryContainer : [],
    };
  }

  const oldPawHistoryContainer = Object.keys(pawHistoryContainer)
    .map((key) => {
      const usedAt = Number(key);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { itemId, usagePaidValue } = pawHistoryContainer[key]!;
      const itemName = pawItemList[itemId]?.name;
      return {
        id: key,
        usedAt,
        itemName: replaceBr(itemName),
        price: usagePaidValue,
      };
    })
    .sort((a, b) => {
      return b.usedAt - a.usedAt;
    });

  return {
    pawHistoryContainer: RELEASE_FLAG
      ? newPawHistoryContainer.concat(oldPawHistoryContainer)
      : oldPawHistoryContainer,
  };
};
