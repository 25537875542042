import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "modules/modal";
import { useI18n } from "hooks/i18n/i18n";
import { SubContentsLayout } from "ui/SubContentsLayout";
import { Paw } from "../../styles";
import { useEmoBalance } from "hooks/paw/useEmoBalance";
import { useUsageHistory } from "hooks/paw/useUsageHistory";
import Box from "@material-ui/core/Box";
import { PawBanner } from "./PawBanner";

import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "react-responsive";
import { getCommaStyleNumber, getSlashSeparatedDateStyle } from "utility";

export const AccountPawUseHistory: React.FC = () => {
  const { t } = useI18n();
  const isDesktopDisplay = useMediaQuery({ query: "(min-width: 768px)" });
  const dispatch = useDispatch();
  const { paidValue: emoBalance } = useEmoBalance();
  const { pawHistoryContainer } = useUsageHistory();

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  return (
    <SubContentsLayout>
      <Box>
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: "ホーム", link: "/" },
            { label: t("common.routes.account"), link: "/account" },
            { label: t("common.routes.pawUseHistory"), link: null },
          ]}
        />

        <PawBanner />

        {/* 現在の所持PAWを表示する */}
        <Box component={"section"} borderBottom={"1px solid #e6e5e6"}>
          <Box
            display={"grid"}
            gridTemplateColumns={"auto max-content"}
            alignItems={"center"}
            gridGap={"24px"}
            width={"90%"}
            maxWidth={"980px"}
            margin={"0 auto"}
            padding={"8px 0"}
          >
            <Box>
              <Box component={"h2"} fontSize={"14px"} marginBottom={"4px"}>
                {t("pawChargeHistory.ownedPAW")}
              </Box>
              <Paw>{getCommaStyleNumber(emoBalance)}</Paw>
            </Box>

            <Box>
              <Button
                type="button"
                aria-label={t("account.topUpPAW")}
                style={{
                  width: "100%",
                  maxWidth: "270px",
                  color: "#fff",
                  fontSize: isDesktopDisplay ? "14px" : "12px",
                  textAlign: "center",
                  padding: isDesktopDisplay ? "8px 64px" : "8px 32px",
                  backgroundColor: "#00c2ae",
                  borderRadius: "0px",
                }}
                onClick={togglePawChargeModal}
              >
                {t("account.topUpPAW")}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* PAWの購入履歴を表示する */}
        <Box width={"90%"} maxWidth={"980px"} margin={"0 auto"}>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr"}
            gridGap={"16px"}
            alignItems={"center"}
            padding={"40px 0"}
            borderBottom={["1px solid #e6e5e6", "none"]}
          >
            <Box component={"h2"} fontSize={"20px"}>
              {t("common.routes.pawUseHistory")}
            </Box>
            <Box component={"p"} fontSize={"14px"}>
              {t("pawChargeHistory.note1")}
            </Box>
          </Box>

          <Box
            display={["none", "grid"]}
            gridTemplateColumns={"1fr 2fr 1fr"}
            gridGap={"16px"}
            alignItems={"center"}
            padding={["0px 4px 8px", "0px 16px 8px"]}
            fontSize={"14px"}
            fontWeight={"600"}
            borderBottom={"1px solid #000"}
          >
            <Box>{t("pawUseHistory.dateOfUse")}</Box>
            <Box>{t("pawUseHistory.use")}</Box>
            <Box textAlign={"right"}>{t("pawUseHistory.usedPAW")}</Box>
          </Box>

          {pawHistoryContainer.map((history) => {
            return (
              <Box
                key={history.id}
                display={"grid"}
                gridTemplateColumns={["1fr", "1fr 2fr 1fr"]}
                gridGap={["4px", "16px"]}
                alignItems={"center"}
                padding={["8px", "8px 16px"]}
                borderBottom={"1px solid #e6e5e6"}
              >
                <Box fontSize={["14px", "16px"]}>
                  {getSlashSeparatedDateStyle(history.usedAt)}
                </Box>

                <Box
                  fontSize={["14px", "16px"]}
                  fontWeight={["bold", "normal"]}
                >
                  {history.itemName}
                </Box>

                <Box fontSize={"16px"} fontWeight={"600"} textAlign={"right"}>
                  {getCommaStyleNumber(history.price)}
                  <Box component={"span"} fontSize={"12px"} paddingLeft={"4px"}>
                    PAW
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </SubContentsLayout>
  );
};
