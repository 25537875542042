import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import { BreadcrumbArea } from "components/common/Link";
import BorderLoader from "components/atoms/loader/BorderLoader";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useGetEventList } from "hooks/event/useGetEventList";
import { useEmoBalance } from "hooks/paw/useEmoBalance";
import { useQueryString } from "hooks/QueryString";
import { liveActions } from "modules/live";
import { modalActions } from "modules/modal";
import { getEventDisplayInfo } from "utility";
import { getLiveContextType } from "utility/live";
import { Store } from "store";
import { EventThumbImg, Paw, QuizBlock, LiveStreamHeader } from "styles";
import QuizEvent from "./QuizEvent";
import TapEvent from "./TapEvent";

export const LiveEventPawInfo = styled.div`
  padding: 15px 5%;
  background-color: #061823;
  border: none;
  @media screen and (min-width: 768px) {
    margin-bottom: 50px;
  }
  .layout {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 960px;
    }
    .paw {
      display: flex;
      align-items: flex-start;
      color: #fff;
      .heading {
        padding-top: 3px;
        margin-right: 10px;
        /**
         * font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",
         *   "MS PGothic",sans-serif;
         */
        font-family: "游ゴシック体", "YuGothic", "Yu Gothic M",
          "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3",
          "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック",
          sans-serif;
        font-size: 14px;
      }
    }
    .button_plus {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      color: #fff;
      background-color: #00c2ae;
      border-radius: 50%;
      cursor: pointer;
      span {
        display: block;
        font-weight: bold;
      }
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.7);
`;

type LiveEventQuery = {
  eid: string;
};

/**
 * 現地ライブで、スマホを用いてインタラクティブにクイズなどを行うためのコンポーネント
 * - ※ 2023/03 時点で、この機能は使われていないため、再度使う際は動作検証を行うこと
 * - /live-event へのアクセスがないことはGoogle Analyticsで確認済み
 */
export const LiveEvent: React.FC = () => {
  const dispatch = useDispatch();
  const { eid } = useQueryString<LiveEventQuery>();

  const contextId = useSelector((state: Store) => state.live.contextId);
  const contextType = getLiveContextType(contextId);

  const user = useSelector((state: Store) => state.auth.user);
  const emoBalance = useEmoBalance();

  // イベント取得
  const { data: events, isLoading } = useGetEventList({
    type: "byIds",
    ids: eid ? [eid] : [],
  });
  const event = useMemo(() => {
    return !!events && events.length > 0 ? events[0] : null;
  }, [events]);
  const eventInfo = useMemo(() => {
    return event ? getEventDisplayInfo(event) : null;
  }, [event]);

  useEffect(() => {
    dispatch(
      liveActions.watchEventContext.started({
        eventId: eid,
      })
    );
  }, [eid, dispatch]);

  const handlePawChargeClick = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  return events === undefined || isLoading ? (
    <LoaderLayout />
  ) : (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "ライブイベント"],
        ]}
      />
      <LiveEventPawInfo>
        <div className="layout">
          <div className="paw">
            <p className="heading">所有PAW</p>
            {!!emoBalance && emoBalance.paidValue !== undefined ? (
              <Paw>{emoBalance.paidValue}</Paw>
            ) : !!user && !user.emailVerified ? (
              <>email未認証</>
            ) : (
              <BorderLoader />
            )}
          </div>
          <p className="button_plus" onClick={handlePawChargeClick}>
            <span>＋</span>
          </p>
        </div>
      </LiveEventPawInfo>

      {contextType === "qid" && !!event ? (
        <QuizEvent event={event} contextId={contextId} />
      ) : contextType === "cid" && !!event ? (
        <TapEvent event={event} contextId={contextId} />
      ) : (
        <>
          <EventThumbImg src={eventInfo?.thumbnail} alt={eventInfo?.title} />
          <QuizBlock>
            <LiveStreamHeader>COMING SOON</LiveStreamHeader>
            <p className="quiz_text">イベント準備中...</p>
          </QuizBlock>
        </>
      )}
    </Container>
  );
};
