import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LogoutIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
      style={{ width: "24px", height: "24px" }}
    >
      <title>03_ico/account/logout</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1"
          points="-0.0005 0.000700199082 26 0.000700199082 26 14 -0.0005 14"
        ></polygon>
      </defs>
      <g
        id="03_ico/account/logout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-6" transform="translate(17.000000, 11.000000)">
          <path
            d="M30,2 C30,0.896 29.104,0 28,0 L2,0 C0.896,0 0,0.896 0,2 L0,40 C0,41.104 0.896,42 2,42 L28,42 C29.104,42 30,41.104 30,40 C30,39.914 29.985,39.833 29.975,39.75 C29.985,39.667 30,39.586 30,39.5 L30,32.5 C30,31.396 29.104,30.5 28,30.5 C26.896,30.5 26,31.396 26,32.5 L26,38 L4,38 L4,4 L26,4 L26,9.5 C26,10.604 26.896,11.5 28,11.5 C29.104,11.5 30,10.604 30,9.5 L30,2.5 C30,2.414 29.985,2.333 29.975,2.25 C29.985,2.167 30,2.086 30,2"
            id="Fill-1"
            fill="#3D3E44"
          ></path>
          <g id="Group-5" transform="translate(16.000000, 14.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-4"></g>
            <path
              d="M25.5615,5.751 L21.5615,0.751 C20.8725,-0.111 19.6125,-0.251 18.7505,0.439 C17.8875,1.128 17.7485,2.387 18.4385,3.249 L19.8385,5 L1.9995,5 C0.8955,5 -0.0005,5.896 -0.0005,7 C-0.0005,8.105 0.8955,9 1.9995,9 L19.8385,9 L18.4385,10.751 C17.7485,11.613 17.8875,12.872 18.7505,13.562 C19.1195,13.856 19.5605,14 19.9995,14 C20.5845,14 21.1665,13.743 21.5615,13.249 L25.5615,8.249 C26.1465,7.519 26.1465,6.481 25.5615,5.751"
              id="Fill-3"
              fill="#3D3E44"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
