import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ReqCollectTicket } from "modules/ticket";
import type {
  IssuedTicket,
  UserCartProduct,
} from "@spwn/types/firebase/firestore";
import { EventInfo } from "utility";

const actionCreator = actionCreatorFactory("modal");

export const modalActions = {
  toggleLoginMethod: actionCreator<void>("toggleLoginMethod"),
  toggleLogin: actionCreator<ReqToggleLogin>("toggleLogin"),
  toggleError: actionCreator<ReqToggleError>("toggleError"),
  toggleNotice: actionCreator<NoticeData>("toggleNotice"),
  toggleActionModal: actionCreator<ActionData>("toggleActionModal"),
  togglePawChargeModal: actionCreator<ReqPawPurchasePopup>(
    "togglePawChargeModal"
  ),
};

export interface modalState {
  isOpenLoginMethod: boolean;
  isOpenLogin: boolean;
  isOpenError: boolean;
  isOpenNotice: boolean;
  isOpenModalAction: boolean;
  isSignup: boolean;
  isPawCharge: boolean;
  actionModalData: ActionData;
  noticeModalData: NoticeData;
  errorModalData: ErrorModalData;
}

//TODO@later 複雑すぎ
export interface ActionData {
  actionModalType?: ActionModalType;
  trunk?: {
    link?: string; // jumpToの飛び先
    newEmail?: unknown;
    newDisplayName?: unknown;
    issuedTicket?: IssuedTicket;
    targetIdx?: number;
    cart?: UserCartProduct[];
    event?: {
      belongedHostings: EventInfo["belongedHostings"];
    };
  };
  caption?: string;
  msg?: string; // msgとcontentsは共存しないので注意。
  contents?: {
    // 特殊な場合にのみ使用 eg. reAuthのinput
    [key: string]: {
      title?: string;
      tagType?: "input" | "p";
      value?: string | number;
      placeHolderAttr?: string;
      typeAttr?: string;
      classAttr?: string;
      disable?: boolean;
    };
  };
  btnMsg?: string;
  closeBtnMsg?: string;
  callbackTarget?: CallbackTarget;
  args?: ReqCollectTicket; // actionの引数
  action?: (args: ActionData["args"]) => ActionData | void;
}
export interface ActionCommonData {
  caption: string;
}
export type ActionModalType =
  | "shareTicket"
  | "eventCheckIn"
  | "rewardCheckIn"
  | "confirmTransition"
  | "goodsCheckIn"
  | "goodsOrderSheetCheckIn"
  | "confirmAction"
  | "settlementComplete"
  | "addedToCart";
export type CallbackTarget =
  | "reAuthWithEmail"
  | "removeRegisteredCardInfo"
  | "jumpTo"
  | "resetPassword"
  | "sendVerificationEmail";

export interface NoticeData {
  modalType?: NoticeModalType;
  msg?: string;
  caption?: string;
}
export type NoticeModalType =
  | "flowerStandImgRegulation"
  | "flowerStandNameRegulation"
  | "flowerStandProfileImgRegulation"
  // 視聴部屋から強制退出
  | "forceExit";

export interface ErrorModalData {
  msg?: string;
  caption?: string;
}

interface ReqToggleLogin {
  isSignup?: boolean;
  forceClose?: boolean;
}
export interface ReqToggleError {
  msg?: string;
  caption?: string;
}

interface ReqPawPurchasePopup {
  isOpen?: boolean;
}

const initialState: modalState = {
  isOpenLoginMethod: false,
  isOpenLogin: false,
  isOpenError: false,
  isOpenNotice: false,
  isOpenModalAction: false,
  isSignup: false,
  isPawCharge: false,
  actionModalData: {
    trunk: {},
    caption: "",
    contents: {},
    btnMsg: "Close",
    // @ts-expect-error TS2322
    callbackTarget: null,
  },
  noticeModalData: {
    // @ts-expect-error TS2322
    modalType: null,
    msg: "",
  },
  // @ts-expect-error TS2322
  errorModalData: null,
};

const modalReducer = reducerWithInitialState(initialState)
  .case(modalActions.toggleLoginMethod, (state) => {
    return { ...initialState, isOpenLoginMethod: !state.isOpenLoginMethod };
  })
  // @ts-expect-error TS2345
  .case(modalActions.toggleLogin, (state, payload) => {
    const isOpenLogin = payload.forceClose ? false : !state.isOpenLogin;
    return { ...state, isOpenLogin, isSignup: payload.isSignup };
  })
  .case(modalActions.toggleError, (state, payload: ReqToggleError) => {
    return {
      ...state,
      isOpenError: !state.isOpenError,
      errorModalData: { ...payload },
    };
  })
  .case(modalActions.toggleNotice, (state, payload: NoticeData) => {
    const noticeModalData = { ...payload };
    return {
      ...initialState,
      isOpenNotice: !state.isOpenNotice,
      noticeModalData,
    };
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(modalActions.toggleActionModal, (state, payload: any) => {
    const isOpen =
      (payload !== null &&
        payload !== undefined &&
        Object.keys(payload).length !== 0 &&
        payload.contents !== undefined &&
        payload.callbackTarget !== null) ||
      (!!payload && !!payload.actionModalType);
    // TODO@later payloadに何もなかったら開かない、で良さそう
    // const isOpen = !!payload && (Object.keys(payload).length !== 0)
    const actionModalData = isOpen ? { ...payload } : {};
    return {
      ...state,
      isOpenModalAction: isOpen,
      actionModalData,
    };
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(modalActions.togglePawChargeModal, (state, payload: any) => {
    const isOpen = payload.isOpen ? true : !state.isPawCharge;
    return { ...state, isPawCharge: isOpen };
  });

export default modalReducer;
