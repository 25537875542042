import { useGetEventList } from "../../../hooks/event/useGetEventList";

/**
 * design doc: https://www.notion.so/balusco/19398eee078b80fdba36c70408ee24e1
 */
const RECOMMENDED_EVENT_IDS = [
  "evt_25022701-jpcalliope2ndlive",
  "evt_25030801-jphololive6thfes",
];

export const useRecommendedEvents = () => {
  return useGetEventList({
    type: "byIds",
    ids: RECOMMENDED_EVENT_IDS,
  });
};
