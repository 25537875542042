import styled from "@emotion/styled";

// NOTE: PopupWindow とは別に存在し、3箇所で利用されている
export const Popup = styled.div`
  position: relative;
  width: 100%;
  margin: 60px auto 60px;
  padding: 0 40px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    width: 700px;
    padding: 50px 2.5% 50px;
  }
  h2 {
    margin-bottom: 30px;
    font-size: 20px;
    text-align: center;
    line-height: 1.3em;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
      font-size: 25px;
    }
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    line-height: 1.5em;
    &.popup_message {
      margin-bottom: 50px;
    }
    &.popup_link {
      a {
        color: #000;
        text-decoration: underline;
      }
    }
  }
  .popup_message {
    margin-bottom: 20px;
    .list_in_list {
      li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

Popup.displayName = "Popup";
