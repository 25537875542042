import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ProfileIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
      style={{ width: "24px", height: "24px" }}
    >
      <title>03_ico/account/profile</title>
      <desc>Created with Sketch.</desc>
      <g
        id="03_ico/account/profile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-11" transform="translate(9.000000, 8.000000)">
          <path
            d="M23.125,3.8047 C12.472,3.8047 3.805,12.4727 3.805,23.1247 C3.805,33.7777 12.472,42.4457 23.125,42.4457 C33.777,42.4457 42.445,33.7777 42.445,23.1247 C42.445,12.4727 33.777,3.8047 23.125,3.8047 M23.125,46.1247 C10.443,46.1247 0.125,35.8077 0.125,23.1247 C0.125,10.4427 10.443,0.1247 23.125,0.1247 C35.808,0.1247 46.125,10.4427 46.125,23.1247 C46.125,35.8077 35.808,46.1247 23.125,46.1247"
            id="Fill-1"
            fill="#3D3E44"
          ></path>
          <path
            d="M23.125,3.8047 C12.472,3.8047 3.805,12.4727 3.805,23.1247 C3.805,33.7777 12.472,42.4457 23.125,42.4457 C33.777,42.4457 42.445,33.7777 42.445,23.1247 C42.445,12.4727 33.777,3.8047 23.125,3.8047 Z M23.125,46.1247 C10.443,46.1247 0.125,35.8077 0.125,23.1247 C0.125,10.4427 10.443,0.1247 23.125,0.1247 C35.808,0.1247 46.125,10.4427 46.125,23.1247 C46.125,35.8077 35.808,46.1247 23.125,46.1247 Z"
            id="Stroke-3"
            stroke="#3D3E44"
            strokeWidth="0.25"
          ></path>
          <path
            d="M10.2451,40.5254 C9.2291,40.5254 8.4051,39.7014 8.4051,38.6844 C8.4051,32.7344 12.7371,28.1594 19.4421,27.0274 C20.4421,26.8554 21.3931,27.5324 21.5631,28.5344 C21.7321,29.5364 21.0571,30.4864 20.0551,30.6544 C14.1791,31.6474 12.0851,35.4534 12.0851,38.6844 C12.0851,39.7014 11.2611,40.5254 10.2451,40.5254"
            id="Fill-5"
            fill="#3D3E44"
          ></path>
          <path
            d="M36.0049,40.5254 C34.9889,40.5254 34.1649,39.7014 34.1649,38.6844 C34.1649,35.4534 32.0709,31.6474 26.1949,30.6544 C25.1929,30.4864 24.5179,29.5364 24.6879,28.5344 C24.8559,27.5324 25.8059,26.8524 26.8079,27.0274 C33.5129,28.1594 37.8449,32.7344 37.8449,38.6844 C37.8449,39.7014 37.0219,40.5254 36.0049,40.5254"
            id="Fill-7"
            fill="#3D3E44"
          ></path>
          <path
            d="M23.125,12.7646 C19.067,12.7646 15.765,16.0666 15.765,20.1246 C15.765,24.1836 19.067,27.4856 23.125,27.4856 C27.184,27.4856 30.485,24.1836 30.485,20.1246 C30.485,16.0666 27.184,12.7646 23.125,12.7646 M23.125,31.1646 C17.038,31.1646 12.085,26.2126 12.085,20.1246 C12.085,14.0366 17.038,9.0846 23.125,9.0846 C29.213,9.0846 34.165,14.0366 34.165,20.1246 C34.165,26.2126 29.213,31.1646 23.125,31.1646"
            id="Fill-9"
            fill="#3D3E44"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};
