import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const PaymentIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
      style={{ width: "24px", height: "24px" }}
    >
      <title>03_ico/account/paymenthistory</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1"
          points="0 0.0003 47.9995 0.0003 47.9995 46 0 46"
        ></polygon>
      </defs>
      <g
        id="03_ico/account/paymenthistory"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-5" transform="translate(8.000000, 9.000000)">
          <polygon
            id="Fill-1"
            fill="#3D3E44"
            points="30.2783 18.042 27.5833 24.245 24.8873 18.042 20.6303 18.042 24.5923 26.108 22.4873 26.108 22.4873 28.06 25.5663 28.06 25.5663 28.938 22.4873 28.938 22.4873 30.888 25.5663 30.888 25.5663 33.693 29.5993 33.693 29.5993 30.888 32.6773 30.888 32.6773 28.938 29.5993 28.938 29.5993 28.06 32.6773 28.06 32.6773 26.108 30.5723 26.108 34.5583 18.042"
          ></polygon>
          <g id="Group-4">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-3"></g>
            <path
              d="M27.3535,41.6953 C18.3495,41.6953 11.0245,34.3933 11.0245,25.4183 C11.0245,16.4423 18.3495,9.1403 27.3535,9.1403 C36.3575,9.1403 43.6815,16.4423 43.6815,25.4183 C43.6815,34.3933 36.3575,41.6953 27.3535,41.6953 M4.3185,20.5813 C4.3185,11.6063 11.6425,4.3043 20.6465,4.3043 C22.2175,4.3043 23.7335,4.5393 25.1715,4.9543 C14.8095,6.0443 6.7075,14.8033 6.7075,25.4183 C6.7075,27.4323 7.0115,29.3773 7.5555,31.2183 C7.4155,30.7433 7.2885,30.2603 7.1815,29.7713 C5.3775,27.1533 4.3185,23.9903 4.3185,20.5813 M35.8115,6.6623 L35.8115,6.6633 C32.0365,2.5783 26.6445,0.0003 20.6465,0.0003 C9.2615,0.0003 -0.0005,9.2323 -0.0005,20.5813 C-0.0005,28.9243 5.0145,36.1093 12.1885,39.3383 C15.9645,43.4223 21.3565,46.0003 27.3535,46.0003 C38.7385,46.0003 47.9995,36.7673 47.9995,25.4183 C47.9995,17.0753 42.9865,9.8903 35.8115,6.6623"
              id="Fill-2"
              fill="#3D3E44"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
