import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const PawUseIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 64 64"
      width="64px"
      height="64px"
      style={{ width: "24px", height: "24px" }}
    >
      <title>03_ico/account/paypaymenthistory</title>
      <desc>Created with Sketch.</desc>
      <g
        id="03_ico/account/paypaymenthistory"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12.3184,29.5811 C12.3184,20.6061 19.6424,13.3051 28.6464,13.3051 C30.2174,13.3051 31.7334,13.5391 33.1724,13.9541 C22.8094,15.0441 14.7074,23.8041 14.7074,34.4181 C14.7074,35.9121 14.8774,37.3661 15.1814,38.7711 C13.3784,36.1531 12.3184,32.9901 12.3184,29.5811 M30.8284,50.0461 C27.6184,49.1211 24.8074,47.2491 22.7384,44.7391 C20.4204,41.9291 19.0244,38.3331 19.0244,34.4181 C19.0244,25.4421 26.3494,18.1411 35.3534,18.1411 C37.4394,18.1411 39.4274,18.5471 41.2624,19.2611 C44.3414,20.4581 46.9734,22.5511 48.8194,25.2291 C50.6224,27.8471 51.6814,31.0101 51.6814,34.4181 C51.6814,43.3941 44.3574,50.6951 35.3534,50.6951 C33.7824,50.6951 32.2674,50.4611 30.8284,50.0461 M43.8114,15.6631 C40.0364,11.5781 34.6444,9.0001 28.6464,9.0001 C17.2614,9.0001 8.0004,18.2321 8.0004,29.5811 C8.0004,37.9251 13.0144,45.1091 20.1884,48.3381 C23.9644,52.4221 29.3564,55.0001 35.3534,55.0001 C46.7384,55.0001 56.0004,45.7681 56.0004,34.4181 C56.0004,26.0751 50.9864,18.8921 43.8114,15.6631"
          id="Fill-1"
          fill="#3D3E44"
        ></path>
        <path
          d="M37.7363,32.6211 C37.7163,32.9031 37.6583,33.1401 37.5563,33.3291 C37.4673,33.5061 37.3323,33.6411 37.1393,33.7421 C36.9373,33.8491 36.6153,33.9041 36.2093,33.9041 L35.4543,33.9041 L35.4543,29.0941 L36.3003,29.0941 C36.7913,29.0941 37.0493,29.1801 37.1753,29.2511 C37.3553,29.3511 37.4863,29.4931 37.5753,29.6841 C37.6763,29.8971 37.7343,30.1541 37.7473,30.4481 C37.7623,30.7871 37.7703,31.1451 37.7703,31.5221 C37.7703,31.9251 37.7583,32.2921 37.7363,32.6211 M40.4843,29.0731 C40.3153,28.4411 40.0073,27.8831 39.5693,27.4131 C39.1293,26.9401 38.6293,26.6061 38.0813,26.4191 C37.5533,26.2371 36.9403,26.1461 36.2553,26.1461 L32.3583,26.1461 L32.3583,43.2331 L35.4543,43.2331 L35.4543,36.8521 L36.2783,36.8521 C37.2123,36.8521 37.9853,36.6851 38.5783,36.3561 C39.1723,36.0271 39.6513,35.5621 40.0043,34.9771 C40.3043,34.4931 40.5043,33.9701 40.5953,33.4201 C40.6823,32.8991 40.7263,32.2541 40.7263,31.4991 C40.7263,30.4921 40.6453,29.6771 40.4843,29.0731"
          id="Fill-4"
          fill="#3D3E44"
        ></path>
      </g>
    </SvgIcon>
  );
};
