/**
 * 参考: https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g
 */
export const fetchRetry = async (
  url: string,
  options: RequestInit,
  n: number
): Promise<Response> => {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (n === 1) throw err;
    return await fetchRetry(url, options, n - 1);
  }
};
