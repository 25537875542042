import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";
import "firebase/firestore";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import { loadingActions } from "./loading";
import { modalActions } from "./modal";
import appConfig from "../constants/appConfig";
import {
  fetchFbToken,
  fetchFirestoreDocument,
  fetchFirestoreCollectionBySnapshotMap,
} from "../utility/firebase";
import { convertArrayToDict, convertMapToValuesWithId } from "utility";
import { eventActions } from "./event";
import { isCrowding, purchaseActions } from "./purchase";
import { streamingActions } from "./streaming";

import { i18nextT } from "../../src/hooks/i18n/i18n";
import { createEcommerceEventItemName, pushDataLayer } from "../utility/ga";
import { Store } from "store";
import type { PayType, ProductType } from "@spwn/types";
import type { OrderSource } from "@spwn/types/spwn";
import type { ValueOf } from "@spwn/types/common";
import type { UserCartProduct, Event } from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("cart");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const cartActions = {
  setStateByKey:
    actionCreator<{ [P in keyof cartState]?: cartState[P] }>("setStateByKey"),
  toggleMyCart: actionCreator<ReqToggleMyCart>("toggleMyCart"),
  getMyCart: actionCreator.async<any, any>("getMyCart"),
  fetchMyCart: actionCreator.async<void, any>("fetchMyCart"),
  addToCart: actionCreator.async<ReqAddToCartData, any>("addToCart"),
  deleteFromCart: actionCreator.async<ReqDeleteFromCart, any>("deleteFromCart"),
  clearMyCart: actionCreator<void>("clearMyCart"),
  addToCartAndPurchase: actionCreator.async<
    ReqAddToCartAndPurchase<PayType>,
    any
  >("addToCartAndPurchase"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface cartState {
  isOpenMyCart: boolean;
  myCart: MyCartData[];
  userCartProducts: UserCartProduct[];
  shippingFee: number;
}
export interface MyCartData {
  eventId: string;
  productCount: number;
  subTotal: number;
  totalDisplayPrice: number;
  tickets: UserCartProduct[];
  goods: UserCartProduct[];
}
// addToCart req data
export interface ReqAddToCartData {
  body: {
    eventId: string;
    products: ReqAddToCartProduct[];
    fanClubId?: string;
    invitationCode?: string;
  };
  actionOnModal: () => void;
  selectedPlace?: string;
  optionalInputData?: {
    [key: string]: Omit<
      ValueOf<Event["ticketOptionalInputData"]>,
      "inputInfo"
    > & { inputText: string };
  };
}
export type ReqDeleteFromCart = {
  payload: {
    cartId: string;
  };
};
export interface ReqAddToCartProduct {
  id: string;
  productType: ProductType;
  count: number;
  price: number;
  name: string;
  variantName?: string;
}
export interface ReqToggleMyCart {
  isOpen?: boolean;
}

export type ReqAddToCartAndPurchase<T> = T extends "Card"
  ? ReqAddToCartAndCreditPurchase
  : T extends "CVS"
  ? ReqAddToCartAndCvsPurchase
  : ReqAddToCartAndCareerPurchase;

type ReqAddToCartAndPurchaseCommon = {
  eventId: string;
  currentVideoId: string;
  products: {
    id: string;
    productType: string;
    count: number;
  }[];
  displayPrice: number;
  purchaseMethod: PayType;
  source: OrderSource;
};

type ReqAddToCartAndCreditPurchase = ReqAddToCartAndPurchaseCommon & {
  cardSeq: number;
  securityCode: string;
};

type ReqAddToCartAndCvsPurchase = ReqAddToCartAndPurchaseCommon & {
  cvesCode: string;
  customerName: string;
  customerKana: string;
  telNo: string;
};

type ReqAddToCartAndCareerPurchase = ReqAddToCartAndPurchaseCommon & {
  phoneCode: string;
};

const initialState: cartState = {
  isOpenMyCart: false,
  myCart: [],
  // @ts-expect-error TS2322
  userCartProducts: null,
  shippingFee: 0,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const cartReducer = reducerWithInitialState(initialState)
  .case(cartActions.setStateByKey, (state, payload) => {
    return { ...state, ...payload };
  })
  .case(cartActions.toggleMyCart, (state, payload: ReqToggleMyCart) => {
    const isOpen = payload.isOpen ? true : !state.isOpenMyCart;
    return { ...state, isOpenMyCart: isOpen };
  })
  .case(cartActions.getMyCart.done, (state, payload: any) => {
    return { ...state, myCart: payload };
  })
  .case(cartActions.addToCart.done, (state, payload: any) => {
    return { ...state, myCart: payload };
  })
  .case(cartActions.deleteFromCart.done, (state, payload: any) => {
    return { ...state, myCart: payload };
  })
  .case(cartActions.clearMyCart, (state) => {
    return { ...state, myCart: [] };
  })
  .case(cartActions.fetchMyCart.done, (state, payload: any) => {
    return { ...state, myCart: payload };
  })
  .case(cartActions.addToCartAndPurchase.done, (state, _payload: any) => {
    return { ...state };
  });
/* eslint-disable @typescript-eslint/no-explicit-any */

export default cartReducer;

export function* cartSaga() {
  yield takeEvery(cartActions.getMyCart.started, getMyCart);
  yield takeEvery(cartActions.addToCart.started, addToCart);
  yield takeEvery(cartActions.deleteFromCart.started, deleteFromCart);
  yield takeEvery(cartActions.fetchMyCart.started, fetchMyCart);
  yield takeEvery(
    cartActions.addToCartAndPurchase.started,
    addToCartAndPurchase
  );
}

function getMyCartChannel(userId: string) {
  const now = new Date();
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection("/users/" + userId + "/cartProducts")
      .where("expiredAt", ">", now)
      .onSnapshot(
        (snapshot) => {
          // @ts-expect-error TS7034
          const myCart = [];
          snapshot.forEach((doc) => {
            myCart.push({ ...doc.data(), _id: doc.id, _refPath: doc.ref.path });
          });
          // @ts-expect-error TS7005
          emitter(myCart);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* getMyCart() {
  const {
    firestore: { channels },
  } = yield select();
  if (isRegisteredChannel(channels, "myCart")) return;

  const { auth } = yield select();
  if (Object.keys(auth.user).length === 0) {
    return [];
  }
  const userId = auth.user.uid;
  try {
    // @ts-expect-error TS7057
    const channel = yield call(getMyCartChannel, userId);
    yield put(firestoreActions.addChannel({ ...channel, name: "myCart" }));
    while (true) {
      const userCartProducts: UserCartProduct[] = yield take(channel);
      yield put(cartActions.setStateByKey({ userCartProducts }));
      const sortProducts: MyCartData[] = arrangeProducts(userCartProducts);
      // update cart data
      yield put(cartActions.getMyCart.done(sortProducts as any));

      const {
        event: { userRelatedEventMap },
      } = yield select();
      // add event data as user related event
      const eventIds = sortProducts.map((el) => el.eventId);
      const eventPromises = [];
      for (const eid of eventIds) {
        if (userRelatedEventMap?.[eid]) {
          continue;
        }
        eventPromises.push(fetchFirestoreDocument(`events/${eid}`));
      }
      const myEvents: Event[] = yield Promise.all(eventPromises);
      if (myEvents.length !== 0) {
        const myEventMap = convertArrayToDict(myEvents);
        yield put(
          eventActions.getUserRelatedEventMap({ eventMap: myEventMap })
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* addToCart(action: { payload: ReqAddToCartData }) {
  const { body, actionOnModal, selectedPlace, optionalInputData } =
    action.payload;
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("cart.msg.nowCheckCart") })
  );
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    // add optional input data log
    if (optionalInputData) {
      const optionalInputDataMap = {
        eventId: body.eventId,
        optionalInputData,
      };
      // @ts-expect-error TS7057
      const surveyResponse = yield fetch(
        // @ts-expect-error TS2769
        appConfig.CloudFunctions.storeSurveyData,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `'Bearer ${fbToken}`,
          },
          body: JSON.stringify(optionalInputDataMap),
        }
      );
      if (surveyResponse.status !== 200) {
        yield put(
          modalActions.toggleError({
            msg: i18nextT("cart.msg.purchaseAPIError"),
          })
        );
        return;
      }
    }
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.addToCart, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(body),
    });
    // @ts-expect-error TS7057
    const resBody = yield response.json();
    if (response.status !== 200) {
      yield put(modalActions.toggleError({ msg: resBody.errors.msg }));
    } else {
      // add event data as user related event
      // @ts-expect-error TS7057
      const event = yield fetchFirestoreDocument(`events/${body.eventId}`);
      const myEventMap = convertArrayToDict([event]);
      yield put(eventActions.getUserRelatedEventMap({ eventMap: myEventMap }));

      // HOTFIX: if place select mode, save selected place
      const {
        auth: { user },
      } = yield select();
      if (selectedPlace && user && body.eventId === "191214-marinasu") {
        const ref = `/actionLog/${body.eventId}/users/${user.uid}`;
        firebase.firestore().doc(ref).set(
          {
            selectedPlace,
            updatedAt: new Date(),
          },
          { merge: true }
        );
      }

      // update cart (pop up)
      yield put(
        modalActions.toggleActionModal({
          actionModalType: "addedToCart",
          caption: i18nextT("cart.modal.addCartModal"),
          msg: "",
          btnMsg: i18nextT("cart.msg.checkCart"),
          action: actionOnModal,
          // @ts-expect-error TS2322
          args: null,
          // action: () => put(cartActions.toggleMyCart({isOpen: true})),
          // action: call(cartActions.toggleMyCart, {isOpen: true}) //ここで呼ぶ方法がわからない
        })
      );

      /**
       * GAのadd_to_cartイベントをトリガーする
       */
      const { products, eventId } = body;
      sendAddToCartEventToGTM({ products, eventId });
    }
  } catch (e) {
    yield put(
      modalActions.toggleError({ msg: i18nextT("cart.msg.unexpectedError") })
    );
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

// @ts-expect-error TS7006
function* deleteFromCart(action) {
  const { payload } = action.payload;
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("cart.msg.deleteCart") })
  );
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.PaymentSystem.deleteFromCart, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(payload),
    });
    // @ts-expect-error TS7057
    const resBody = yield response.json();
    if (response.status !== 200) {
      yield put(modalActions.toggleError({ msg: resBody.errors.msg }));
    } else yield put(modalActions.toggleNotice({ msg: resBody.msg }));
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

function* fetchMyCart() {
  const {
    auth: { user },
  } = yield select();
  if (!user.uid) {
    return;
  }
  const snapshot = firebase
    .firestore()
    .collection("/users/" + user.uid + "/cartProducts")
    .where("expiredAt", ">", new Date())
    .get();
  // @ts-expect-error TS7057
  const cartProductMap = yield fetchFirestoreCollectionBySnapshotMap(snapshot);
  const cartProducts =
    convertMapToValuesWithId<UserCartProduct>(cartProductMap);
  const sortProducts: MyCartData[] = arrangeProducts(cartProducts);
  // update cart data
  yield put(cartActions.fetchMyCart.done(sortProducts as any));
}

const arrangeProducts = (cartProducts: UserCartProduct[]) => {
  const sortProducts: MyCartData[] = [];

  // 期限切れが近い商品のeventを先に入れる
  let events = cartProducts
    .sort((prev, cur) => {
      return prev.expiredAt.seconds - cur.expiredAt.seconds;
    })
    .map((el) => el.eid);
  events = Array.from(new Set(events));
  // 表示用の配列を作成してreduxに持たせる
  for (const eventId of events) {
    const products = cartProducts.filter((el) => el.eid === eventId);
    const productCount = products
      .map((el) => el.count)
      .reduce((prev, cur) => prev + cur);
    const subTotal = products
      .map((el) => el.price_jpy * el.count)
      .reduce((prev, cur) => prev + cur);
    const totalDisplayPrice = products
      .map((el) => (el.displayPrice || 0) * el.count) // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      .reduce((prev, cur) => prev + cur);
    const tickets = products
      // new ticket model has no productType
      .filter(
        (el) => el.productType === "ticket" || el.productType === undefined
      )
      .map((el) => {
        return {
          ...el,
        };
      });
    const goods = products
      .filter((el) => el.productType === "goods")
      .map((el) => {
        return {
          ...el,
        };
      });
    sortProducts.push({
      eventId,
      productCount,
      subTotal,
      totalDisplayPrice,
      tickets,
      goods,
    });
  }
  return sortProducts;
};

class PurchaseMemberError extends Error {}
class ModuleError extends Error {}
function* addToCartAndPurchase(action: {
  payload: ReqAddToCartAndPurchase<PayType>;
}) {
  // TODO@later 配信ページ用のモーダル
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("cart.msg.purchase") })
  );

  // const { eventId, products, displayPrice, cardSeq, securityCode } = action.payload
  const { eventId, currentVideoId, products, displayPrice, source } =
    action.payload;

  const { auth, streaming } = yield select();
  const purchaseItemList = action.payload.products;

  // @ts-expect-error TS7057
  const fbToken = yield fetchFbToken();
  try {
    const {
      purchase: { fingerprint },
    }: Store = yield select();
    // delete from cart if my real cart is not empty
    // @ts-expect-error TS7057
    const resDeleteAllFromCart = yield call(deleteAllFromCart, fbToken);
    if (resDeleteAllFromCart.isError === true) {
      throw new ModuleError(i18nextT("cart.msg.unexpectedError"));
    }

    // add to cart
    // @ts-expect-error TS2769
    const addToCartResponse = yield fetch(appConfig.PaymentSystem.addToCart, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({
        eventId,
        products,
      }),
    });

    if (addToCartResponse.status !== 200) {
      // @ts-expect-error TS7057
      const resBody = yield addToCartResponse.json();
      throw new ModuleError(resBody.errors.msg);
    }

    // check server congestion
    // @ts-expect-error TS7057
    const isBusy = yield call(isCrowding, fbToken);
    if (isBusy) {
      throw new ModuleError(i18nextT("cart.msg.congestionError"));
    }

    // start purchaseMember
    let purchaseMemberResponse;
    switch (action.payload.purchaseMethod) {
      case "Card": {
        const { cardSeq, securityCode } =
          action.payload as ReqAddToCartAndPurchase<"Card">;
        // @ts-expect-error TS7057
        purchaseMemberResponse = yield fetch(
          // @ts-expect-error TS2769
          appConfig.PaymentSystem.purchaseMember,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `'Bearer ${fbToken}`,
            },
            body: JSON.stringify({
              displayPrice,
              securityCode,
              cardSeq,
              fingerprint,
              source,
            }),
          }
        );
        break;
      }

      case "CVS": {
        const { cvesCode, customerName, customerKana, telNo } =
          action.payload as ReqAddToCartAndPurchase<"CVS">;
        // @ts-expect-error TS7057
        purchaseMemberResponse = yield fetch(
          // @ts-expect-error TS2769
          appConfig.PaymentSystem.purchaseCVS,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `'Bearer ${fbToken}`,
            },
            body: JSON.stringify({
              displayPrice,
              cvesCode,
              customerName,
              customerKana,
              telNo,
              fingerprint,
              source,
            }),
          }
        );
        break;
      }

      case "Phone": {
        const { phoneCode } =
          action.payload as ReqAddToCartAndPurchase<"Phone">;
        // @ts-expect-error TS7057
        purchaseMemberResponse = yield fetch(
          // @ts-expect-error TS2769
          appConfig.PaymentSystem.purchasePhone,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `'Bearer ${fbToken}`,
            },
            body: JSON.stringify({
              displayPrice,
              phoneCode,
              fingerprint,
              source,
            }),
          }
        );
        break;
      }
    }

    if (purchaseMemberResponse.status !== 200) {
      const resBody: { errors: { type: string; msg: string } } =
        yield purchaseMemberResponse.json();
      const errorType = resBody?.errors?.type;
      // if not duplicate transaction error, refresh fingerprint.
      if (errorType !== "DUPLICATE_TRANSACTION_ID_ERROR") {
        yield put(purchaseActions.refreshFingerprint());
      }
      if (purchaseMemberResponse.status === 403)
        throw new PurchaseMemberError(yield purchaseMemberResponse.text());
      switch (errorType) {
        case "API_ERROR":
          throw new PurchaseMemberError(i18nextT("cart.msg.purchaseFailError"));
        case "PURCHASE_LIMIT_ERROR":
          throw new PurchaseMemberError(
            i18nextT("cart.msg.purchaseLimitError")
          );
        default:
          throw new PurchaseMemberError(
            resBody?.errors?.msg || i18nextT("cart.msg.unexpectedError")
          );
      }
    } else {
      // 購入成功
      if (action.payload.purchaseMethod === "Phone") {
        // キャリア決済ページを別タブで表示する
        // スクリプトによる別タブ表示はポップアップブロックにブロックされるので、返ってきた支払いページへのURLをStoreに格納する
        // @ts-expect-error TS7057
        const url = yield purchaseMemberResponse.text();
        yield put(streamingActions.successPurchaseLiveCommerce(true));
        yield put(streamingActions.setPhonePurchaseUrl(url));
      } else {
        yield put(streamingActions.successPurchaseLiveCommerce(true));
      }

      // 購入した商品をコメント欄に表示する
      for (const item of purchaseItemList) {
        if (item.productType === "ticket") return;

        const data = {
          msg: "",
          icon: streaming.iconUrls["32"],
          uid: auth.user.uid,
          name: auth.user.displayName || " ", // if undefined, permission error
          color: "",
          isBan: false,
          isSuperChat: false,
          isPurchased: true,
          amount: 0,
          giftItemId: "",
          purchasedProductId: item.id,
        };

        yield put(
          streamingActions.postComment.started({
            eventId,
            videoId: currentVideoId,
            postData: data,
            isGift: false,
            itemId: "",
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
    if (error instanceof PurchaseMemberError) {
      // !!! clear cart if purchase failed !!!
      // @ts-expect-error TS7057
      const resDeleteAllFromCart = yield call(deleteAllFromCart, fbToken);
      if (resDeleteAllFromCart.isError === true) {
        console.error("");
      }
      yield put(
        streamingActions.toggleLiveCommerceError({
          isError: true,
          msg: error.message,
        })
      );
      return;
    } else if (error instanceof ModuleError) {
      yield put(
        streamingActions.toggleLiveCommerceError({
          isError: true,
          msg: error.message,
        })
      );
      return;
    }
    yield put(
      streamingActions.toggleLiveCommerceError({
        isError: true,
        msg: i18nextT("cart.msg.unexpectedError"),
      })
    );
  } finally {
    // TODO@later 配信ページ用のモーダル
    yield put(loadingActions.toggleLoading({}));
  }
}

function* deleteAllFromCart(fbToken: string) {
  try {
    const { cart } = yield select();
    const { myCart } = cart;
    if (myCart.length > 0) {
      for (const cartData of myCart) {
        for (const product of cartData.tickets.concat(cartData.goods)) {
          // @ts-expect-error TS2769
          const response = yield fetch(appConfig.PaymentSystem.deleteFromCart, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `'Bearer ${fbToken}`,
            },
            body: JSON.stringify({
              cartId: product._id,
            }),
          });
          yield response.json();
          if (response.status !== 200) {
            // yield put(modalActions.toggleError({ msg: resBody.errors.msg }))
            throw new Error("failed to delete");
          } else {
            // yield put(modalActions.toggleNotice({ msg: resBody.msg }))
          }
        }
      }
    }
    return { isError: false };
  } catch (error) {
    return { isError: true };
  }
}

/**
 * カート追加イベント(add_to_cart）をGoogle Tag Managerに送る
 * - 購入のファネル分析のため
 *
 * NOTE: カート追加ドメインにこの処理を置いているのは、カート追加に関わるデータから、GTMのイベントへ変換するので、その責務はカート追加ドドメインにあると考えたため
 */
const sendAddToCartEventToGTM = ({
  products,
  eventId,
}: {
  products: ReqAddToCartProduct[];
  eventId: string;
}) => {
  const itemTotalAmount = products.reduce((cur, acc) => {
    return cur + acc.price * acc.count;
  }, 0);
  pushDataLayer({
    event: "add_to_cart",
    eid: eventId,
    ecommerce: {
      currency: "JPY",
      value: itemTotalAmount,
      items: products.map((p) => ({
        // アイテムからブランド名を取得する方法が、現状（2023/08/30）のportalフロントエンドでは存在しないため除外する
        // item_brand: "",
        item_id: p.id,
        quantity: p.count,
        item_category: p.productType,
        item_list_id: eventId,
        price: p.price,
        item_name: createEcommerceEventItemName({ itemName: p.name, eventId }),
        ...(p.variantName !== undefined ? { item_variant: p.variantName } : {}),
      })),
    },
  });
};
