/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { changeStyleWithHosting } from "utility/hosting";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContentsLayout } from "ui/SubContentsLayout";

interface Props {}

const Warning: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classesEventNotesStyles = eventNotesStyles();

  if (changeStyleWithHosting().commonSettings.menu.eventNotes.path) {
    // before open link, move prev page. HOTFIX: move home
    dispatch(push(changeStyleWithHosting().commonSettings.route.home));
    window.open(
      // @ts-expect-error TS2345
      changeStyleWithHosting().commonSettings.menu.eventNotes.path,
      null,
      "noopener"
    );
  }

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "イベントの注意事項"],
        ]}
      />

      <div css={classesEventNotesStyles.root}>
        <h2>イベントの注意事項</h2>
        <div css={classesEventNotesStyles.section}>
          <h3>SPWNで販売したチケットについて</h3>
          <p>
            ・お客様の都合によるチケット払戻しはお受けできません。購入後のキャンセルは不可となっております。
          </p>
          <p>
            ・チケットの枚数が定員に達した場合でも、状況によっては当日券など追加でチケットの販売を行う場合がございます。追加チケットの販売については、主催者より発表させていただきます。
          </p>
          <p>
            ・チケットの転売行為は、金額・理由問わず、一切禁止とさせていただきます。
          </p>
        </div>

        <div css={classesEventNotesStyles.section}>
          <h3>配信イベントに関して</h3>
          <p>
            ・<Link to="/streaming-test">視聴環境テストのページ</Link>
            から、ご利用の環境で視聴できるかご確認のうえ配信チケットを購入していただけますようお願い致します。
          </p>
          <p>
            ・動作環境ではないOS、ブラウザ、デバイス、タブレットをご利用の場合、正常に動作しない可能性がございます。
          </p>
          <p>
            ・ライブ配信とアーカイブ配信は、イベントによって内容が異なる場合もございます。また、ライブ配信終了後、アーカイブ配信の開始までに、お時間をいただきます。
          </p>
          <p>
            ・推奨環境内であっても、端末個別の仕様によって正常に動作しない場合もございます。
          </p>
          <p>
            ・テスト環境で映像を視聴いただけた場合においても、配信コンテンツによっては日本国外からの視聴を制限することがございます。
          </p>
          <p>・ライブ配信のチケット料金の他に、別途通信料がかかります。</p>
          <p>
            ・ライブ配信視聴に必要なデバイスの電力や通信料などの費用はお客様負担となります。
          </p>
          <p>
            ・イベントによっては、ライブ配信のチケット料金の他に「システム利用料」「配送手数料」「コンビニ決済手数料」などの別途料金がかかる場合がございます。詳しくは
            <Link to="/events">各イベントページ</Link>
            で、ご購入時にご確認ください。
          </p>
          <p>
            ・ライブ配信を視聴する際、他の皆様の迷惑になるようなコメントはお控えください。
          </p>
          <p>
            ・ライブ配信チケットは、お一人様につき1枚ご購入ください。（複数人での視聴はご遠慮ください。）
          </p>
          <p>
            ・当社が不正とみなす行為が発覚した場合、注意事項に違反する場合、その他弊社及びイベント主催者からのご案内に従っていただけない場合は、ライブ配信のチケットを無効もしくは取り消しとさせていただく場合がございます。
          </p>
        </div>

        <div css={classesEventNotesStyles.section}>
          <h3>
            SPWNでチケット販売を実施した、会場で実施されるイベントに関して
          </h3>
          <p>
            ・他のお客様へのご迷惑になりますので、割込みや開場時無理な席取りはご遠慮ください。
          </p>
          <p>
            ・入場開始時に指定場所に整列されていない場合、チケット番号に関わらず列の最後尾からのご案内となります。（別途、主催者より案内がある場合は除きます。）
          </p>
          <p>
            ・開場時間の前から入口付近での待機等の行為は他店舗、他のお客様への迷惑となりますので禁止されております。集合時間（整列開始時間）を目安にご来場いただき、スタッフの指示に従い整列のご協力をよろしくお願いいたします。（別途、主催者より案内がある場合は除きます。）
          </p>
          <p>
            ・大きな荷物はフロア内で他のお客様のご迷惑になってしまいますので、近隣のコインロッカーをご利用ください。（別途、主催者より案内がある場合は除きます。）
          </p>
          <p>
            ・会場内に録音・録画に使用する機材およびアルコールを含む飲食物、危険物などを持ち込むことは出来ません。（別途、主催者より案内がある場合は除きます。）
          </p>
          <p>
            ・ご来場の際の駐車場については、イベント及び会場により異なりますので、各イベントページをご参照の上、各会場へ直接お問い合わせください。
          </p>
        </div>

        <div css={classesEventNotesStyles.section}>
          <h3>その他の注意事項</h3>
          <p>
            ・会場・ライブ配信時の模様（映像・写真・コメントなど）が、WEB動画配信・番組・誌面等の各種メディアで使用される可能性がありますので予めご了承下さい。
          </p>
          <p>
            ・各イベントの内容につきましては、予告なく変更する場合がございます。
          </p>
          <p>
            ・注意事項を逸脱した行動によって事故・怪我等にあった場合、その責任は一切負いません。
          </p>
          <p>
            ・主催者や他のお客様にご迷惑をおかけする行為が見受けられた場合は、スタッフの誘導によりご退場いただくことがございます。
          </p>
          <p>
            ・天災地変、戦乱、暴動、官公署の命令、その他やむを得ない事由により、安全かつ円滑な実施が不可能となった場合には、イベントを中止・延期・内容を変更する場合がございます。
          </p>
          <p>
            ・出演者の急病、主催者の都合により公演が中止・延期となった場合の払い戻しに関しては、別途ご連絡させていただきます。
          </p>
          <p>・払い戻しの対象は、「チケット料金」のみとなります。</p>

          <p>
            ・返金については、ご利用いただいた決済方法毎のスケジュールにより行われますので、返金のお手続きを頂いてから実際に返金されるまでお時間をいただく場合がございます。
          </p>
          <p>
            ・個人情報の取り扱いに関しては､
            <Link to="/privacy-policy">プライバシーポリシー</Link>
            をご確認ください。
          </p>
          <p>
            ・プライバシーポリシーの内容は、サービス内容や技術動向の変化、法令の改正等に従って予告なく変更及び修正されることがあります。
          </p>
        </div>
      </div>
    </SubContentsLayout>
  );
};
export default Warning;

const eventNotesStyles = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 80px 0;
      margin: 0 auto;
      h2 {
        margin-bottom: 80px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
      }
    `,
    section: css`
      margin-bottom: 40px;
      h3 {
        margin-bottom: 16px;
        line-height: 1.3em;
      }
      p {
        padding-left: 1em;
        margin-bottom: 8px;
        font-size: 14px;
        text-indent: -1em;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
    `,
  };
};
