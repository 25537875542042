/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContentsLayout } from "ui/SubContentsLayout";
import { TermSidebar } from "features/terms/TermSidebar";

export const BasicTermsOfService: React.FC = () => {
  const classes = styles();

  return (
    <SubContentsLayout>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "基本利用規約"],
        ]}
      />

      <div css={classes.root}>
        {/* サイドバーを表示する */}
        <TermSidebar />

        {/* コンテンツを表示する */}
        <div css={classes.main}>
          <iframe
            title="基本利用規約"
            src="https://docs.google.com/document/d/e/2PACX-1vR-FiLJqBIBA3vCLHLLfta5nKTcC7l6AKWkF80yjkUmzeiDWrdUUKA5LCfZ14YagA/pub?embedded=true"
          ></iframe>
        </div>
      </div>
    </SubContentsLayout>
  );
};

const styles = () => {
  return {
    root: css`
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 16px;
      width: 90%;
      max-width: 1140px;
      padding: 80px 0;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        grid-template-columns: 320px auto;
      }
    `,
    main: css`
      position: relative;
      width: 100%;
      iframe {
        width: 100%;
        height: 5000px;
      }
    `,
  };
};
